import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import { Card, CardContent, Paper, Tooltip } from '@mui/material';

export function TabPanel(props) {
  const { children, value, index, p, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Stack width={1} p={p}>
          {children}
        </Stack>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

/**
 *
 * @param {{
 * headers: Array<{name:string, icon: any?, hover: string?}>,
 * panels: Array<{element: any, padding: number}>,
 * subHeader: function (number),
 * afterTab: function? (number) => React.ReactNode,
 * beforPanel: function (number)=> React.ReactNode,
 * paddingT: number,
 * currentTab: number?,
 * panelCard: boolean?,
 * isCard: boolean?,
 * onChange: function (number),
 * }} props
 * @returns
 */
export default function BasicTabs({
  headers,
  panels,
  panelCard = false,
  subHeader,
  beforPanel,
  paddingT,
  currentTab = null,
  onChange,
  afterTab,
  isCard = true,
  defaultPanel = 0
}) {
  const [value, setValue] = React.useState(defaultPanel);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChange && onChange(newValue);
  };

  React.useEffect(() => {
    if (currentTab) {
      setValue(currentTab);
      return;
    }
    setValue(0);
  }, [currentTab]);

  return (
    <Stack width={1}>
      <Stack
        px={1}
        alignItems="center"
        width={1}
        {...(isCard ? { component: Paper } : { bgcolor: (t) => t.palette.background.paper })}
        elevation={5}
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Stack
          direction={{ xs: 'column-reverse', md: 'row' }}
          width={1}
          height={{ xs: 90, md: 40 }}
          sx={{ mb: 1 }}
          justifyContent="space-between"
        >
          <Stack direction="row">
            <Tabs
              value={value}
              scrollButtons
              allowScrollButtonsMobile
              variant="scrollable"
              onChange={handleChange}
              aria-label="scrollable auto tabs example"
            >
              {headers.map((head, idx) =>
                head.hover ? (
                  <Tooltip title={head.hover} key={idx + 'tab'} arrow>
                    <Tab icon={head?.icon || null} key={idx + 'tab'} label={head.name} {...a11yProps(idx)} />
                  </Tooltip>
                ) : (
                  <Tab
                    icon={head?.icon || null}
                    key={idx + 'tab'}
                    label={head.name}
                    {...a11yProps(idx)}
                    sx={{ fontSize: 13, pt: 2 }}
                  />
                )
              )}
            </Tabs>

            <Stack>{afterTab && afterTab(value)}</Stack>
          </Stack>

          <Stack width={{ xs: 1, md: 'max-content' }} alignItems="end">
            {subHeader && subHeader(value)}
          </Stack>
        </Stack>
      </Stack>

      <Stack className="scroll-smooth-small" width={1} sx={{ maxWidth: { md: 1, xs: 1 }, overflowX: 'scroll' }}>
        {beforPanel && beforPanel(value)}
      </Stack>

      {panelCard ? (
        <Card>
          <CardContent>
            <Stack width={1} maxWidth={{ xs: 1 }} sx={{ ...(paddingT && { pt: paddingT }) }}>
              {panels.map((panel, idx) => (
                <TabPanel key={idx + 'panel'} p={panel?.padding || 0} value={value} index={idx}>
                  {panel.element}
                </TabPanel>
              ))}
            </Stack>
          </CardContent>
        </Card>
      ) : (
        <Stack px={1} width={1} maxWidth={{ xs: 1 }} sx={{ ...(paddingT && { pt: paddingT }) }}>
          {panels.map((panel, idx) => (
            <TabPanel key={idx + 'panel'} p={panel?.padding || 0} value={value} index={idx}>
              {panel.element}
            </TabPanel>
          ))}
        </Stack>
      )}
    </Stack>
  );
}
