import { nanoid } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { CONFIRM_MESSAGE } from 'src/constants/community';
import useAuth from 'src/hooks/useAuth';
import { useToggleV2 } from 'src/hooks/useToggle';
import { ChannelType, CommMessageType } from 'src/models/communication_types';
import {
  createCommMessage,
  deleteChannel,
  deleteCommMessage,
  onRemoveSelectedChannel,
  onSelectedChannel,
  onSelectedMessage,
  updateChannel,
  updateCommMessageReaction
} from 'src/redux/slices/communication/channel';
import { dispatch, useSelector } from 'src/redux/store';
import ChannelActionModal from 'src/section/communications/channels/ChannelActionModal';
import ChannelInfos from 'src/section/communications/channels/ChannelInfos';
import { serverTime } from 'src/utils/serverTime';

const CommunityProvider = createContext({
  onOpenInfoCanal: (channel) => { },
  /**@type {ChannelType} */
  currentChannel: null,
  /**@type {Array<ChannelType>} */
  channels: [],
  /** @type {CommMessageType} */
  currentMessage: null,
  toSelectChanneldId: null,
  toSelectMessageId: null,
  selectedChannel: (id) => { },
  openChannelById: (id) => { },
  handleAddReactionItem: (reaction, message, isAnswer) => { },
  openChannelAndMessageById: (channelId, messageId) => { },
  selectedMessageById: (messageId) => { },
  handleOpenConfim: (actionType, channel = null, message = null, answers = null) => { }
});

export const useCommunityContext = () => useContext(CommunityProvider);

export default function CommunityContext({ children }) {
  const { user } = useAuth();
  const [confirmation, setConfirmation] = React.useState(null);
  const [openInfo, onOpenInfo, onCloseInfo] = useToggleV2();
  const [infoCanal, setInfoCanal] = useState(null);
  const [infoMessage, setInfoMessage] = useState(null);
  const [infoAnswer, setInfoAnswer] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { users } = useSelector((state) => state.user);

  const [toSelectChanneldId, setToSelectChannelId] = useState(null);
  const [toSelectMessageId, setToSelectMessageId] = useState(null);

  const { channels, selected, selectedMessage, messages } = useSelector((state) => state.communication);

  const currentChannel = useMemo(() => {
    if (channels?.length && selected) {
      return channels?.find((_chn) => _chn.id === selected.id);
    }
    return null;
  }, [channels, selected]);

  const currentMessage = useMemo(() => {
    if (selectedMessage) {
      const allMsg = messages[selected?.id] || [];
      const res = allMsg.find((_msg) => _msg.id === selectedMessage.id);
      return res || null;
    }
    return null;
  }, [messages, selected, selectedMessage]);

  const selectedChannel = (id) => {
    dispatch(onSelectedChannel({ id, callback: () => setToSelectChannelId(null) }));
  };

  const selectedMessageById = (messageId) => {
    dispatch(onSelectedMessage({ messageId, callback: () => setToSelectMessageId(null) }));
  };

  const openChannelById = (id) => {
    setToSelectChannelId(id);
  };

  const openChannelAndMessageById = (id, messageId) => {
    setToSelectChannelId(id);
    setToSelectMessageId(messageId);
  };

  const onOpenInfoCanal = (channel) => {
    setInfoCanal(channel);
    onOpenInfo();
  };

  const handleOpenConfim = (type, channel, _message, answers) => () => {
    setConfirmation(type);
    if (channel) setInfoCanal(channel);
    if (_message) setInfoMessage(_message);
    if (answers) setInfoAnswer(answers);
  };

  const handleCloseConfirm = () => {
    setConfirmation(null);
  };

  const leaveChannel = () => {
    const members = infoCanal?.members?.filter((member) => member?.id !== user.uid) || [];
    dispatch(
      createCommMessage({
        channel: currentChannel,
        messageObject: {
          id: nanoid(),
          content: `${user.displayName} a quitté le canal`,
          links: [],
          mentions: [],
          isSystem: true,
          created_at: serverTime(true),
          user: {
            name: user.displayName,
            id: user.uid
          }
        },
        users,
        callback: () => {
          dispatch(
            updateChannel({
              channel: { ...infoCanal, members },
              oldChannel: infoCanal,
              callback: () => {
                handleCloseConfirm();
                onCloseInfo();
                dispatch(onRemoveSelectedChannel());
                enqueueSnackbar('Vous avez quitté le canal avec succès', { variant: 'info' });
              }
            })
          );

        }
      })
    );

  };

  const closeChannel = () => {
    dispatch(
      updateChannel({
        channel: { ...infoCanal, isClosed: true },
        oldChannel: infoCanal,
        callback: () => {
          handleCloseConfirm();
          onCloseInfo();
          dispatch(onRemoveSelectedChannel());
          enqueueSnackbar('Vous avez cloturé le canal avec succès', { variant: 'info' });
        }
      })
    );
  };

  const reOpenChannel = () => {
    dispatch(
      updateChannel({
        channel: { ...infoCanal, isClosed: false },
        oldChannel: infoCanal,
        callback: () => {
          handleCloseConfirm();
          onCloseInfo();

          enqueueSnackbar('Vous avez rouvrir le canal avec succès', { variant: 'info' });
        }
      })
    );
  };

  const deleteCanal = () => {
    dispatch(
      deleteChannel({
        channelId: infoCanal?.id,
        callback: () => {
          handleCloseConfirm();
          onCloseInfo();
          dispatch(onRemoveSelectedChannel());
          enqueueSnackbar('Vous avez supprimé le canal avec succès', { variant: 'warning' });
        }
      })
    );
  };

  const deleteMessage = () => {
    dispatch(
      deleteCommMessage({
        channelId: infoCanal?.id,
        message: infoMessage,
        messageId: infoMessage?.id,
        answersId: infoAnswer?.id || null,
        callback: () => {
          handleCloseConfirm();
          onCloseInfo();
          enqueueSnackbar('Vous avez supprimer ce message avec succès', { variant: 'warning' });
        }
      })
    );
  };

  const handleAddReactionItem = (reaction, message, isAnswer = false) => {
    let basereactions = message?.reactions || [];
    let reactions = [...basereactions];

    let checkIndex = reactions.findIndex((_one) => _one?.type === reaction);

    let thisReaction = [];

    if (checkIndex !== -1) {
      thisReaction = reactions[checkIndex]?.users || [];

      const exist = thisReaction.find((_user) => _user.id === user.uid);

      if (exist) {
        thisReaction = thisReaction.filter((_user) => _user.id !== user.uid);
      } else {
        //console.log({ thisReaction });
        thisReaction = [...thisReaction, { name: user.displayName, id: user.uid }];
      }

      if (thisReaction.length >= 1) {
        reactions.splice(checkIndex, 1, { type: reaction, users: thisReaction });
      } else {
        reactions.splice(checkIndex, 1);
      }
    } else {
      reactions.push({ type: reaction, users: [{ id: user.uid, name: user.displayName }] });
    }

    if (isAnswer && currentMessage) {
      let cloneSelectedMessage = { ...currentMessage };
      let selectedMessageAnswers = cloneSelectedMessage?.answers || [];
      let thisAnswerIndex = selectedMessageAnswers?.findIndex((_one) => _one.id === message.id);

      if (thisAnswerIndex !== -1) {
        const theAnswers = [...selectedMessageAnswers];

        theAnswers.splice(thisAnswerIndex, 1, { ...message, reactions });

        dispatch(
          updateCommMessageReaction({
            channelId: currentChannel.id,
            message: { ...cloneSelectedMessage, answers: theAnswers },
            callback: (res) => {
              // console.log({ res });
            }
          })
        );
      }

      return;
    }

    dispatch(
      updateCommMessageReaction({
        channelId: currentChannel.id,
        message: { ...message, reactions },
        callback: (res) => { }
      })
    );
  };

  const store = {
    channels,
    currentChannel,
    currentMessage,
    selectedChannel,
    openChannelById,
    onOpenInfoCanal,
    handleOpenConfim,
    toSelectMessageId,
    toSelectChanneldId,
    selectedMessageById,
    handleAddReactionItem,
    openChannelAndMessageById
  };

  const handleAction = () => {
    if (confirmation.action === CONFIRM_MESSAGE.LEAVE.action) return leaveChannel();
    if (confirmation.action === CONFIRM_MESSAGE.OPEN.action) return reOpenChannel();
    if (confirmation.action === CONFIRM_MESSAGE.CLOSE.action) return closeChannel();
    if (confirmation.action === CONFIRM_MESSAGE.DELETE_MESSAGE.action) return deleteMessage();
    if (confirmation.action === CONFIRM_MESSAGE.DELETE_CANAL.action) return deleteCanal();
  };

  return (
    <CommunityProvider.Provider value={store}>
      {children}
      {confirmation && (
        <ChannelActionModal
          open={Boolean(confirmation)}
          onClose={handleCloseConfirm}
          message={confirmation.title}
          actionTitle={confirmation.action}
          action={handleAction}
        />
      )}
      {openInfo && infoCanal && <ChannelInfos open={openInfo} onClose={onCloseInfo} channel={infoCanal} user={user} />}
    </CommunityProvider.Provider>
  );
}
