import { createContext, useContext, useState, useMemo } from 'react';
import { useDispatch } from 'src/redux/store';
import { getConversationById, onNewMessage } from 'src/redux/slices/chat';
import useAuth from 'src/hooks/useAuth';
import { useWorldTime } from 'src/contexts/WorldTimeContext';
import { useToggleV2 } from 'src/hooks/useToggle';
// import {useSnackbar} from 'notistack'

import PropTypes from 'prop-types';
import { nanoid } from '@reduxjs/toolkit';
import { values, flattenDeep, uniq } from 'lodash';
import { baseColors } from 'src/constants/color';

export const useConverseProvider = () => useContext(ConverseContext);

export default function ConverseProvider({ children }) {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { now } = useWorldTime();
  // const {enqueueSnackbar} = useSnackbar()

  const [view, setView] = useState(CONVERSE_VIEW.CONVERSATION_LIST);
  const [conversation, setConversation] = useState(null);
  const [uploadProgress, setUploadProgress] = useState({});

  const [isChatVisible, showChat, hideChat, switchChatVisibility] = useToggleV2();

  const uploadingFiles = useMemo(() => {
    return flattenDeep(values(uploadProgress));
  }, [uploadProgress]);

  const goToHome = () => setView(CONVERSE_VIEW.CONVERSATION_LIST);
  const goToForm = () => setView(CONVERSE_VIEW.CREATE_FORM);
  const goToRoom = (conversation) => {
    setConversation(conversation);
    setView(CONVERSE_VIEW.CHATROOM);
    // console.log("goToRoom",conversation)
  };

  const goToDetails = (conversation) => {
    setConversation(conversation);
    setView(CONVERSE_VIEW.DETAILS);
  };

  const sendMessage = (message, { onResolve } = {}) => {
    const data = {
      ...message,
      attachments: (message?.attachments || [])?.map((file) => Object.assign(file, { id: nanoid() }))
    };

    setUploadProgress((prevState) => ({ ...prevState, [message?.id]: [] }));

    dispatch(
      onNewMessage(transformMessageMention(data), 0, user?.id, onResolve, (funct) => {
        setUploadProgress((prevState) => {
          const old = prevState[message?.id] || [];
          const current = funct(old);
          return { ...prevState, [message?.id]: current };
        });
      })
    );
  };

  const openConversationById = (conversationId) => {
    if (conversationId)
      dispatch(
        getConversationById({
          conversationId,
          onResolve: (conversation) => {
            const canAccess = conversation?.participantsId?.find((id) => id === user?.id);
            if (canAccess) {
              goToRoom(conversation);
              showChat();
            } else {
              // enqueueSnackbar("Vous n'avez pas access a cette conversation", {variant: 'error'})
            }
          },
          onReject: (error) => {
            console.log('level error', error);
            // enqueueSnackbar("Cette conversation n'existe plus", {variant: 'info'})
          }
        })
      );
  };

  const store = {
    view,
    setView,
    currentConversation: conversation,
    goToHome,
    goToForm,
    goToRoom,
    goToDetails,
    sendMessage,
    uploadProgress,
    uploadingFiles,
    close: () => { },
    isChatVisible,
    showChat,
    hideChat,
    switchChatVisibility,
    openConversationById
  };

  return <ConverseContext.Provider value={store}>{children}</ConverseContext.Provider>;
}

ConverseProvider.propTypes = {
  children: PropTypes.element
};

export const CONVERSE_VIEW = {
  CONVERSATION_LIST: 'CONVERSATION_LIST',
  CHATROOM: 'CHATROOM',
  CREATE_FORM: 'CREATE_FORM',
  DETAILS: 'DETAILS'
};

const transformMessageMention = (data) => {
  const { message = '', mentions = [] } = data;

  let text = message;

  mentions?.forEach((el) => {
    text = text.replaceAll(el?.display, `@${el?.display}`);
  });

  return {
    ...data,
    message: text
  };
};

export const transformMessageLinks = (text = '') => {
  var copy = text;

  const localhostExp =
    /https?:\/\/(((www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,63})|(localhost))\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  const httpRegexG =
    /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/g;
  const urls = uniq([...(text.match(httpRegexG) || []), ...(text.match(localhostExp) || [])]);

  urls.forEach((el) => {
    copy = copy.replaceAll(el, `<a href="${el}" target="_blank" style="color: ${baseColors.BLUEDARK}">${el}</a>`);
  });

  return copy;
};

export const transformMessageBody = (data) => {
  const { message, body = '', mentions = [] } = data;

  let text = transformMessageLinks(message || body);

  mentions?.forEach((el) => {
    text = text.replaceAll(`@${el?.display}`, `<span style="color: ${baseColors.BLUEDARK}">@${el?.display}</span>`);
  });

  return {
    ...data,
    message: text
  };
};

const ConverseContext = createContext({
  view: CONVERSE_VIEW.CONVERSATION_LIST,
  currentConversation: null,
  isChatVisible: false,
  showChat: () => { },
  hideChat: () => { },
  switchChatVisibility: () => { },
  openConversationById: (id) => { },
  setView: (view) => { },
  goToHome: () => { },
  goToForm: () => { },
  goToRoom: (conversation) => { },
  goToDetails: (conversation) => { },
  close: () => { },
  sendMessage: (message, { onResolve }) => { },
  uploadProgress: {},
  uploadingFiles: []
});
