import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import { firestoreReducer } from 'redux-firestore';
import sitesReducer from './slices/site';
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import blogReducer from './slices/blog';
import userReducer from './slices/user';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import tasksReducer from './slices/task';
import communicationReducer from 'src/redux/slices/communication/channel';
import audience from './slices/audience';
import stage from './slices/stage';
import contact from './slices/contact';
import workflow from './slices/workflow';
import commentReducer from './slices/comment';
import notificationsReducer from './slices/notifications';
import oldTaskReducer from './slices/oldtask';
import archivesReducer from './slices/archives';
import videoReducer from './slices/video';
import referencesReducer from './slices/references.slice';
import goutiLikeProjectReducer from './slices/project/project.slice';
import document from './slices/document';
import conversationReducer from './slices/conversation';
import affectationReducer from './slices/affectations';
import affectation_v4Reducer from './slices/affectation_v4';
import worldTimeReducer from 'src/redux/slices/worldTime';
import microsoftMailReducer from "src/redux/slices/microsoftMail";
import customMailReducer from "src/redux/slices/customMail";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const goutiPersistConfig = {
  key: 'gouti',
  storage,
  keyPrefix: 'redux-'
};

const rootReducer = combineReducers({
  firestore: firestoreReducer,
  archives: archivesReducer,
  sites: sitesReducer,
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  user: userReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  tasks: tasksReducer,
  communication: communicationReducer,
  audience: audience,
  affectation: affectationReducer,
  affectation_v4: affectation_v4Reducer,
  stage: stage,
  contact: contact,
  workflow: workflow,
  document: document,
  product: persistReducer(productPersistConfig, productReducer),
  comments: commentReducer,
  notifications: notificationsReducer,
  oldTask: oldTaskReducer,
  video: videoReducer,
  references: referencesReducer,
  conversation: conversationReducer,
  gouti: persistReducer(goutiPersistConfig, goutiLikeProjectReducer),
  worldTime: worldTimeReducer,
  microsoftMail:microsoftMailReducer,
  customMail:customMailReducer
});

export { rootPersistConfig, rootReducer };
