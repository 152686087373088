import { Dialog, DialogContent, DialogTitle, Divider, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import LabelStyle from 'src/components/LabelStyle';
import useAuth from 'src/hooks/useAuth';
import { TaskType } from 'src/models/task_m';
import { updateTaskWithoutProject } from 'src/redux/slices/task';
import AddProjectToTask from '../components/AddProjectToTask';
import TaskAssigne from '../components/TaskAssigne';
import TaskDescriptionInput from '../components/TaskDescriptionInput';
import TaskDueDate from '../components/TaskDueDate';
import TaskFileView from '../components/TaskFileView';
import TaskSubTask from '../components/TaskSubTask';
import TaskTitleInput from '../components/TaskTitleInput';
import TaskItemDetailToolbar from './TaskItemDetailToolbar';
import { dispatch, useSelector } from 'src/redux/store';
import { getTaskById } from 'src/redux/slices/kanban';
import { useProjectContext } from 'src/contexts/ProjectContext';
import { uniq } from 'lodash';

/**
 *
 * @param {{ open: boolean, onClose: function, task: TaskType }} props
 * @returns
 */
export default function TaskItemDetail({ open, onClose, task: _task, canEdit, canDelete, canCreate }) {
  //TODO : add disable statement to all component
  const { user } = useAuth();
  const [currentProject, setCurrentProject] = useState(null);
  const { currentProject: _currentProject } = useProjectContext();

  const { project } = useSelector((state) => state.kanban);

  const [task, setTask] = useState(null);
  const [update, setUpdate] = useState(false);
  const [attachement, setAttachement] = useState([]);
  const [loadingAttachement, setLoadingAttachement] = useState(false);

  const [description, setDescription] = useState({
    description: _task?.description || '',
    desc_mention: _task?.desc_mentions || []
  });

  //console.log('attachement', attachement);
  React.useEffect(() => {
    if (project?.length) {
      const exist = project?.find((_one) => _one?.id === _task?.projectKey);
      setCurrentProject(exist);
    }
  }, [project, _task?.projectKey]);
  const handleUpdate = () => {
    //console.log('handleUpdate');
    setUpdate(!update);
    setTimeout(() => {
      setUpdate(false);
    }, 1000);
  };

  useEffect(() => {
    setAttachement(_task?.attachments);
  }, [_task?.attachments]);

  //console.log('id', _task.id);
  useEffect(() => {
    dispatch(
      getTaskById({
        taskId: _task.id,
        resolve: (task) => {
          setTask(task);
          setAttachement(task?.attachments);
          //console.log({task})
        },
        reject: () => {
          //console.log("reject")
        }
      })
    );
  }, [_task, update]);

  const handleChangeFile = (files) => {
    //console.log('handleChangeFile', files);
    setAttachement(uniq([...attachement, ...files]));
  };

  //console.log({canCreate,canEdit,canDelete});

  const taskRights = {
    create: canCreate,
    edit: canEdit,
    delete: canDelete
  };

  const dataInterval = currentProject ? currentProject?.dataInterval : undefined;

  // console.log(
  //   currentProject
  // )

  const handleClose = () => {
    dispatch(
      updateTaskWithoutProject(
        task,
        { description: description.description || '', desc_mentions: description.desc_mention || [] },
        () => {
          //console.log("updateTaskWithoutProject")
        }
      )
    );
    onClose();
  };

  if (!task || !attachement) return null;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="md"
      fullWidth
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <Stack>
        <DialogTitle id="scroll-dialog-title">
          <TaskItemDetailToolbar
            user={user}
            task={task}
            onUpdate={updateTaskWithoutProject}
            onClose={handleClose}
            canDelete={canDelete}
            canEdit={canEdit}
            forceUpdate={handleUpdate}
            attachement={attachement}
            setAttachement={handleChangeFile}
            loadingAttachement={loadingAttachement}
            setLoadingAttachement={setLoadingAttachement}
          />
        </DialogTitle>
        <Divider sx={{ pt: 2 }} />
        <DialogContent>
          <Stack spacing={3} width={1} px={4}>
            <TaskTitleInput
              task={task}
              value={{ name: task?.name, editor_mentions: task?.editor_mentions }}
              onUpdate={updateTaskWithoutProject}
              disabled={!canEdit}
            />
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <LabelStyle>Projet</LabelStyle>
              <Stack width={1} direction="row">
                <AddProjectToTask task={task} user={user} onUpdate={updateTaskWithoutProject} disabled={!canEdit} />
              </Stack>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <LabelStyle>Échéance</LabelStyle>
              <Stack width={1} direction="row">
                <TaskDueDate
                  task={task}
                  onUpdate={updateTaskWithoutProject}
                  disabled={!canEdit}
                  enabledDataInterval={dataInterval}
                />
              </Stack>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <LabelStyle>Intervenants</LabelStyle>
              <TaskAssigne task={task} onUpdate={updateTaskWithoutProject} disabled={!canEdit} />
            </Stack>
            <Stack width={1} spacing={2}>
              <LabelStyle>Description</LabelStyle>
              <TaskDescriptionInput task={task} onUpdate={setDescription} disabled={!canEdit} />
            </Stack>

            <Stack width={1}>
              <TaskSubTask task={task} rights={taskRights} onUpdate={updateTaskWithoutProject} disabled={!canEdit} />
            </Stack>

            <Stack width={1}>
              <TaskFileView
                task={task}
                user={user}
                onUpdate={updateTaskWithoutProject}
                disabled={!canEdit}
                setAttachement={handleChangeFile}
                attachement={attachement}
                loadingAttachement={loadingAttachement}
                setLoadingAttachement={setLoadingAttachement}
                taskRights={taskRights}
              />
            </Stack>
          </Stack>
        </DialogContent>
      </Stack>
    </Dialog>
  );
}
