import { Button, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import Iconify from 'src/components/Iconify';

import V4ProjectContentListSection from './V4ProjectContentListSection';
import V4ProjectContentTaskComponent from './V4ProjectContentTaskComponent';
import { Droppable } from 'react-beautiful-dnd';
import useToggle from 'src/hooks/useToggle';
import V4ProjectContextListSectionNew from './V4ProjectContextListSectionNew';
import { useV4ProjectContext } from 'src/section/project/v4/content/project_content';

export default function V4ProjectContentLIst() {
  const { projectId } = useParams();
  const { open, handleOpen, handleClose } = useToggle();
  const { taskRights:rigths } = useV4ProjectContext();
  const canCreate = rigths?.create;
  const canDelete = rigths?.delete;
  const canEdit = rigths?.edit;

  //console.log({ rigths });

  return (
    <Stack>
      <Stack width={1} direction="row" justifyContent="space-between" mb={1.5} alignItems="center">
        <Stack direction="row" spacing={2}>
          <Button
            size="small"
            variant="outlined"
            color="inherit"
            startIcon={<Iconify icon="eva:plus-fill" sx={{ height: 15, width: 15 }} />}
            onClick={canCreate && handleOpen}
            disabled={!canCreate}
          >
            Ajouter une opération
          </Button>
        </Stack>
      </Stack>

      <V4ProjectContentTaskComponent
        header
        name="Nom de la tâche"
        intervenant="Intervenants"
        priority="Priorité"
        due="Échéance"
      />
      {open && <V4ProjectContextListSectionNew projectKey={projectId} onClose={handleClose} />}
      <Droppable droppableId="all-columns" direction="vertical" type="column" isDragDisabled={!canEdit}>
        {(provided) => (
          <Stack width={1} my={2} {...provided.droppableProps} ref={provided.innerRef}>
            {projectId && <V4ProjectContentListSection projectId={projectId}  />}
            {provided.placeholder}
          </Stack>
        )}
      </Droppable>
    </Stack>
  );
}
