export const V4_TASK_STATE = {
  ALL: 'ALL',
  NOT_END: 'NOT_END',
  END: 'END'
};

export const V4_ONLY_MY_TASK = {
  ALL: 'ALL',
  CREATED: 'CREATED',
  ASSIGNEE: 'ASSIGNEE',
};

export const V4_TASK_STATE_CONVERT = {
  [V4_TASK_STATE.ALL]: 'Toutes les tâches',
  [V4_TASK_STATE.NOT_END]: 'Tâches en cours',
  [V4_TASK_STATE.END]: 'Tâches terminées'
};

export const V4_DATE_STATE = {
  LATE: 'Ancienne',
  TODAY: "Aujourd'hui",
  THIS_WEEK: 'Cette semaine',
  NEXT_WEEK: 'La semaine prochaine'
};

export const V4_ONLY_MY_TASK_SORT = {
  PRIORITY: 'PRIORITY',
  CREATED_DATE: 'CREATED_DATE',
  DEADLINE: 'DEADLINE',
};

export const V4_ONLY_MY_TASK_SORT_CONVERT = {
  [V4_ONLY_MY_TASK_SORT.PRIORITY]: 'Priorité',
  [V4_ONLY_MY_TASK_SORT.CREATED_DATE]: 'Date de création',
    [V4_ONLY_MY_TASK_SORT.DEADLINE]: 'Date limite',

};

export const V4_ONLY_MY_TASK_ORDER = {
  ASC: 'ASC',
  DESC: 'DESC'
};

export const V4_ONLY_MY_TASK_ORDER_CONVERT = {
  [V4_ONLY_MY_TASK_ORDER.ASC]: 'Croissant',
  [V4_ONLY_MY_TASK_ORDER.DESC]: 'Décroissant'
};


export const COL_WIDTH = 110;
