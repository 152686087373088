export const mailHoverColor = 'rgba(249, 162, 31, 0.15)';
export const mailUnSeenColor = '#E6F0FF';
export const mailSelectedColor = 'rgba(249, 162, 31, 0.10)';

export const CUSTOM_MAIL_BOX = {
  INBOX: 'INBOX',
  DRAFTS: 'DRAFTS',
  SENT: 'Sent',
  Junk: 'Junk',
  Trash: 'Trash'
};
export const CUSTOM_MAIL_FLAG = {
  UNSEEN: '\\UNSEEN',
  SEEN: '\\SEEN',
  DRAFTS: '\\DRAFT',
  SENT: '\\SENT',
  TRASH: '\\TRASH',
  JUNK: '\\JUNK'
};

export const MAIL_PLATFORM = {
  MICROSOFT: 'microsoft',
  CUSTOM: 'custom'
};

export const MAIL_SIGNATURE_CLASS = 'mail-signature-custom-component';
