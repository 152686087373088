import { createSlice } from '@reduxjs/toolkit';
import { auth, firestore } from 'src/contexts/FirebaseContext';
import { IOkyDriverFile, IOkyDriverFolder } from 'src/models/IOkyDriver';
import { dispatch } from 'src/redux/store';
import { asyncForEach } from 'src/utils/asyncForeach';
import { compareArraysOfObjects } from 'src/utils/changeOnObject';
import { serverTime } from 'src/utils/serverTime';
import { documentSharedNotification, documentSharedUserLeaveNotification } from '../notifications';

const initialState = {
  driver: [],
  error: false,
  isLoading: false
};

const slice = createSlice({
  name: 'driver',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      console.error(action.payload);
    }
  }
});

export default slice.reducer;

export const driverReference = firestore.collection('okydook_driver');
export const driverTagReference = firestore.collection('okydook_driver_tags');

//#region HELPERS

const removeFileExtension = (fileName) => {
  const lastDotIndex = fileName.lastIndexOf('.');
  if (lastDotIndex !== -1) {
    return fileName.slice(0, lastDotIndex);
  }
  return fileName;
};

const getFileExtension = (fileName) => {
  const lastDotIndex = fileName.lastIndexOf('.');
  if (lastDotIndex !== -1) {
    return `.${fileName.slice(lastDotIndex + 1)}`;
  }
  return '';
};

const checkNameExistance = ({ list = [], element, num }) => {
  const nameWithoutExtension = removeFileExtension(element?.name);

  const nameExist = list.find((item) => {
    if (item?.type === element?.type) {
      const name = Boolean(num) ? `${nameWithoutExtension} (${num})` : nameWithoutExtension;
      return name === removeFileExtension(item?.name);
    }
    return false;
  });

  if (Boolean(nameExist)) {
    return checkNameExistance({ list, element, num: num + 1 });
  }

  return Boolean(num)
    ? { ...element, name: `${nameWithoutExtension} (${num})${getFileExtension(element?.name)}` }
    : element;
};

/**
 *
 * @param {{
 * existItems: Array<IOkyDriverFolder | IOkyDriverFile>,
 * element: IOkyDriverFolder | IOkyDriverFile | Array<IOkyDriverFile>
 * }} params
 * @returns {IOkyDriverFolder | IOkyDriverFile | Array<IOkyDriverFile>}
 */
export const checkNameExistanceAndRename = ({ existItems = [], element }) => {
  if (!element.length) {
    return checkNameExistance({ list: existItems, element, num: 0 });
  }

  return element.map((one) => {
    return checkNameExistance({ list: existItems, one, num: 0 });
  });
};
//#endregion

//#region FOLDER
export const addFolderAction = ({ parentId, name, items, onSuccess, onError }) => {
  return async () => {
    try {
      const { uid } = auth.currentUser;
      const folderRef = driverReference.doc();
      const folderId = folderRef.id;

      const element = checkNameExistanceAndRename({ existItems: items, element: { name, type: 'folder' } });

      let toSave = {
        name: element?.name,
        url: '',
        size: 0,
        tags: {},
        id: folderId,
        type: 'folder',
        sharedWith: [],
        updatedBy: uid,
        uploadedBy: uid,
        isDeleted: false,
        sharedWithIds: [],
        createdAt: serverTime(),
        updatedAt: serverTime(),
        parentFolderId: parentId
      };

      await folderRef.set(toSave, { merge: true });
      if (onSuccess) onSuccess(folderId);
    } catch (error) {
      if (onError) onError(error);
      console.error(error);
    }
  };
};

export const getFolderById = ({ folderId, onSuccess, onError }) => {
  return async (dispatch) => {
    try {
    } catch (error) {
      if (onError) onError(error);
      console.error(error);
    }
  };
};

/**
 * @param {Array} treeList
 * @param {string} parentId
 * @param {Array} newChildren
 * @returns
 */
export function addChildToTreeList(treeList, parentId, newChildren) {
  let parentFound = false;

  const addChildToNode = (node) => {
    if (node.id === parentId) {
      if (!node.children) {
        node.children = [];
      }
      newChildren.forEach((child) => {
        if (!node.children.find((one) => one?.id === child?.id)) {
          node.children.push(child);
        }
      });
      parentFound = true;
    } else if (node.children) {
      node.children.forEach(addChildToNode);
    }
  };

  treeList.forEach(addChildToNode);

  if (!parentFound) {
    treeList.push(...newChildren);
  }

  return treeList;
}

export const getFoldersContentAction = ({ folderId, onSuccess, onError }) => {
  return async (dispatch) => {
    try {
      if (folderId) {
      }

      const foldersDoc = await driverReference
        .where('parentFolderId', '==', folderId)
        .where('type', '==', 'folder')
        .where('isDeleted', '==', false)
        .orderBy('updatedAt', 'asc')
        .get();

      let items = [];

      foldersDoc.docs.map((doc) => {
        const data = doc?.data();
        items.push({ name: data?.name, id: doc?.id });
      });

      if (onSuccess) {
        onSuccess({ parentFolderId: folderId, items });
      }
    } catch (error) {
      console.error(error);
      if (onError) onError();
    }
  };
};

const _getFolderPath = async (folderId, path = [], topLevel) => {
  const folderDoc = await driverReference.doc(folderId).get();
  if (folderDoc.exists) {
    const folderData = folderDoc.data();
    path.unshift({ name: folderData?.name, id: folderData?.id });

    if (folderData?.parentFolderId && folderData?.id !== topLevel) {
      await _getFolderPath(folderData?.parentFolderId, path, topLevel);
    }
  }

  return path;
};

export const getFolderPath = ({ currentFolder, topLevel, onSuccess, onError }) => {
  return async (dispatch) => {
    try {
      const path = await _getFolderPath(currentFolder, [], topLevel);
      if (onSuccess) onSuccess(path);
    } catch (error) {
      if (onError) onError(error);
      console.error(error);
    }
  };
};
//#endregion

//#region FILES
export const addFileAction = ({ parentId, name, url, type, size, items, onSuccess, onError }) => {
  return async () => {
    try {
      const { uid } = auth.currentUser;
      const fileRef = driverReference.doc();
      const fileId = fileRef.id;

      const element = checkNameExistanceAndRename({ existItems: items, element: { name, type } });

      let toSave = {
        name: element?.name,
        url,
        size,
        type,
        tags: [],
        id: fileId,
        sharedWith: [],
        updatedBy: uid,
        uploadedBy: uid,
        isDeleted: false,
        createdAt: serverTime(),
        updatedAt: serverTime(),
        parentFolderId: parentId
      };

      await fileRef.set(toSave, { merge: true });
      if (onSuccess) onSuccess(fileId);
    } catch (error) {
      if (onError) onError(error);
      console.error(error);
    }
  };
};
//#endregion

//#region BOTH FOLDER FILE
export const updateElementNameAction = ({ elementId, name, onSuccess, onError }) => {
  return async () => {
    try {
      const { uid } = auth.currentUser;
      const folderRef = driverReference.doc(elementId);

      let toUpdate = {
        name,
        updatedBy: uid,
        updatedAt: serverTime()
      };

      await folderRef.set(toUpdate, { merge: true });
      if (onSuccess) onSuccess(elementId);
    } catch (error) {
      if (onError) onError(error);
      console.error(error);
    }
  };
};

export const updateElementDescriptionAction = ({ elementId, description, onSuccess, onError }) => {
  return async () => {
    try {
      const { uid } = auth.currentUser;
      const folderRef = driverReference.doc(elementId);

      let toUpdate = {
        description,
        updatedBy: uid,
        updatedAt: serverTime()
      };

      await folderRef.set(toUpdate, { merge: true });
      if (onSuccess) onSuccess(elementId);
    } catch (error) {
      if (onError) onError(error);
      console.error(error);
    }
  };
}

/**
 *
 * @param {{
 * favorite: Array<{elementId:string, favorite: object }>;
 * onSuccess: ()=>{};
 * onError: () =>{}
 * }} props
 * @returns
 */
export const updateElementFavorisAction = ({ favorite, onSuccess, onError }) => {
  return async () => {
    try {
      const batch = firestore.batch();

      favorite.forEach((element) => {
        const folderRef = driverReference.doc(element?.elementId);
        batch.update(folderRef, { favorite: element?.favorite });
      });
      await batch.commit();

      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError) onError(error);
      console.error(error);
    }
  };
};

/**
 *
 * @param {{
 * elementId: string;
 * tags: Array<string>,
 * onSuccess: ()=>{};
 * onError: () =>{}
 * }} props
 * @returns
 */
export const updateElementTagsAction = ({ elementId, tags, onSuccess, onError }) => {
  return async () => {
    try {
      const { uid } = auth.currentUser;

      let elementRef = driverReference.doc(elementId);
      elementRef.update({ tags });
      const tagToArray = tags[uid];
      elementRef.collection('tags').doc(uid).set({ tags: tagToArray, elementId }, { merge: true });
      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError) onError(error);
      console.error(error);
    }
  };
};

/**
 *
 * @param {{
 * items: Array<IOkyDriverFile | IOkyDriverFolder>;
 * parentId: string
 * onSuccess: ()=>{};
 * onError: () =>{}
 * }} props
 * @returns
 */
export const copyElementAction = ({ items, parentId, onSuccess, onError }) => {
  return async () => {
    try {
      const { uid } = auth.currentUser;

      const batch = firestore.batch();

      items.forEach((element) => {
        const folderRef = driverReference.doc();
        batch.set(folderRef, {
          ...element,
          id: folderRef.id,
          parentFolderId: parentId,
          updatedBy: uid,
          updatedAt: serverTime()
        });
      });
      await batch.commit();

      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError) onError(error);
      console.error(error);
    }
  };
};

/**
 *
 * @param {{
 * items: Array<IOkyDriverFile | IOkyDriverFolder>;
 * parentId: string
 * onSuccess: ()=>{};
 * onError: () =>{}
 * }} props
 * @returns
 */
export const moveElementAction = ({ items, parentId, onSuccess, onError }) => {
  return async () => {
    try {
      const { uid } = auth.currentUser;

      const batch = firestore.batch();

      items.forEach((element) => {
        const folderRef = driverReference.doc(element?.id);

        batch.update(folderRef, {
          parentFolderId: parentId,
          updatedBy: uid,
          updatedAt: serverTime()
        });
      });
      await batch.commit();

      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError) onError(error);
      console.error(error);
    }
  };
};

const _movetoTrash = async (elementId, batch, uid, updateValue) => {
  const elementRef = driverReference.doc(elementId);
  const subItemsSnapshot = await driverReference.where('parentFolderId', '==', elementId).get();

  batch.update(elementRef, { ...updateValue });

  for (const doc of subItemsSnapshot.docs) {
    await _movetoTrash(doc?.id, batch, uid, {
      ...updateValue,
      parentFolderId: elementId,
      tempParentFolderId: elementId
    });
  }
};

/**
 *
 * @param {{
 * items: Array<IOkyDriverFile | IOkyDriverFolder>;
 * onSuccess: ()=>{};
 * onError: () =>{}
 * }} props
 * @returns
 */
export const toTrashElementAction = ({ items, onSuccess, onError }) => {
  return async () => {
    try {
      const { uid } = auth.currentUser;

      const batch = firestore.batch();

      for (const element of items) {
        await _movetoTrash(element?.id, batch, uid, {
          isDeleted: true,
          updatedBy: uid,
          updatedAt: serverTime(),
          parentFolderId: uid,
          tempParentFolderId: element?.parentFolderId
        });
      }
      console.log('end');
      await batch.commit();

      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError) onError(error);
      console.error(error);
    }
  };
};

const _moveOutToTrash = async (elementId, batch, uid, updateValue) => {
  const elementRef = driverReference.doc(elementId);
  const subItemsSnapshot = await driverReference.where('parentFolderId', '==', elementId).get();

  batch.update(elementRef, { ...updateValue });

  for (const doc of subItemsSnapshot.docs) {
    const tempParentFolderId = doc.data()?.tempParentFolderId;

    await _movetoTrash(doc?.id, batch, uid, {
      ...updateValue,
      parentFolderId: tempParentFolderId,
      tempParentFolderId: null
    });
  }
};

/**
 *
 * @param {{
 * items: Array<IOkyDriverFile | IOkyDriverFolder>;
 * onSuccess: ()=>{};
 * onError: () =>{}
 * }} props
 * @returns
 */
export const restoreFromTrashElementAction = ({ items, onSuccess, onError }) => {
  return async () => {
    try {
      const { uid } = auth.currentUser;

      const batch = firestore.batch();

      for (const element of items) {
        await _moveOutToTrash(element?.id, batch, uid, {
          isDeleted: false,
          updatedBy: uid,
          updatedAt: serverTime(),
          parentFolderId: element?.tempParentFolderId,
          tempParentFolderId: null
        });
      }

      await batch.commit();

      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError) onError(error);
      console.error(error);
    }
  };
};

const _wipetoChildrens = async (elementId, batch) => {
  const elementRef = driverReference.doc(elementId);
  const subItemsSnapshot = await driverReference.where('parentFolderId', '==', elementId).get();

  batch.delete(elementRef);

  for (const doc of subItemsSnapshot.docs) {
    await _wipetoChildrens(doc?.id, batch);
  }
};

/**
 *
 * @param {{
 * items: Array<IOkyDriverFile | IOkyDriverFolder>;
 * onSuccess: ()=>{};
 * onError: () =>{}
 * }} props
 */
export const wipeTrashElementsAction = ({ items, onSuccess, onError }) => {
  return async (dispatch) => {
    try {
      const batch = firestore.batch();

      for (const element of items) {
        await _wipetoChildrens(element?.id, batch);
      }

      await batch.commit();

      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError) onError();
      console.log(error);
    }
  };
};

/**
 *
 * @param {{
 * item: IOkyDriverFile | IOkyDriverFolder;
 * sharedWith: Array,
 * onSuccess: ()=>{};
 * onError: () =>{}
 * }} props
 * @returns
 */
export const shareElementAction = ({ item, sharedWith, onSuccess, onError }) => {
  return async () => {
    try {
      const { uid } = auth.currentUser;
      const folderRef = driverReference.doc(item?.id);
      const sharedWithIds = sharedWith.map((one) => one?.userId);
      console.log({item});

      const { added, removed } = compareArraysOfObjects(item.sharedWith, sharedWith, 'userId');

      if (removed) {
        //console.log({ removed });
        dispatch(
          documentSharedUserLeaveNotification({
            item: item,
            targetIds: [item?.uploadedBy],
            callback: () => {
              console.log('callback');
            }
          })
        )
        //send leave notification to createdby
      }

      if (added) {
        //console.log({ added });
        dispatch(
          documentSharedNotification({
            item: item,
            targetIds: added.map((one) => one?.userId),
            callback: () => {
              console.log('callback');
            }
         })
        );
        //send add notifcation to persons
      }

      let toUpdate = {
        sharedWith,
        sharedWithIds,
        lastShareBy: uid,
        lastShareAt: serverTime()
      };

      await folderRef.set(toUpdate, { merge: true });
      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError) onError(error);
      console.error(error);
    }
  };
};

/**
 *
 * @param {{
 * item: IOkyDriverFile | IOkyDriverFolder;
 * privacy: Array,
 * isPublic: boolean,
 * onSuccess: ()=>{};
 * onError: () =>{}
 * }} props
 * @returns
 */
export const makeElementAsPrivateOrPublic = ({ item, privacy, isPublic = false, onSuccess, onError }) => {
  return async () => {
    try {
      const { uid } = auth.currentUser;
      const folderRef = driverReference.doc(item?.id);

      let toUpdate = {
        private: {
          by: uid,
          value: isPublic ? false : true,
          canAutorize: isPublic ? [] : privacy
        },
        updatedAt: serverTime()
      };

      await folderRef.set(toUpdate, { merge: true });
      if (onSuccess) onSuccess(toUpdate);
    } catch (error) {
      console.error(error);
      if (onError) onError();
    }
  };
};

//#endregion

//#region TAGS
/**
 *
 * @param {{
 * tag: {label: string, color: string},
 * onError: ()=> {},
 * onSuccess: () =>{}
 * }} props
 */
export const createTagAction =
  ({ tag, onSuccess, onError }) =>
  async (dispatch) => {
    try {
      const { uid } = auth.currentUser;
      const tagRef = driverTagReference.doc();
      const tagId = tagRef.id;

      const toSave = {
        id: tagId,
        label: tag?.label,
        color: tag?.color,
        sharedWith: [],
        createdBy: uid,
        updatedBy: uid,
        createdAt: serverTime(),
        updatedAt: serverTime()
      };
      await tagRef.set(toSave, { merge: true });
      if (onSuccess) onSuccess(tagId);
    } catch (error) {
      if (onError) onError(error);
      console.log(error);
    }
  };

/**
 *
 * @param {{
 * tag: {id: string, label: string, color: string},
 * onError: ()=> {},
 * onSuccess: () =>{}
 * }} props
 */
export const editTagAction =
  ({ tag, onSuccess, onError }) =>
  async (dispatch) => {
    try {
      const { uid } = auth.currentUser;
      const tagRef = driverTagReference.doc(tag?.id);

      const toSave = {
        label: tag?.label,
        color: tag?.color,
        updatedBy: uid,
        updatedAt: serverTime()
      };

      await tagRef.set(toSave, { merge: true });
      if (onSuccess) onSuccess(tag?.id);
    } catch (error) {
      if (onError) onError(error);
      console.log(error);
    }
  };

/**
 *
 * @param {{
 * tagId: string,
 * onError: ()=> {},
 * onSuccess: () =>{}
 * }} props
 */
export const getTagsElemntsAction =
  ({ tagId, onSuccess, onError }) =>
  async (dispatch) => {
    try {
      const { uid } = auth.currentUser;

      const elementTags = await firestore.collectionGroup('tags').where('tags', 'array-contains', tagId).get();
      const docs = [];

      const getDocuments = async () => {
        await asyncForEach(elementTags.docs, async (doc) => {
          const elementId = doc?.data()?.elementId;
          const element = await driverReference.doc(elementId).get();
          const data = element.data();
          docs.push({ id: element.id, ...data });
        });

        if (onSuccess) onSuccess(docs);
      };

      getDocuments();
    } catch (error) {
      if (onError) onError(error);
      console.log(error);
    }
  };

//#endregion
