import { Checkbox, Input, Option, Select, Sheet } from '@mui/joy';
import { Autocomplete, Box, ClickAwayListener, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { MuiCustomDateInput } from './MuiCustomDateInput';
import MuiUploadFileInput from './upload/MuiUploadFileInput';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { isArray, isEmpty, isString } from 'lodash';
import { CustomAutocompletInput } from './CustomInput';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/**
 *
 * @param {{
 * type: 'text' | 'date' | 'file' | 'checkbox' | 'select' | 'Autocomplete' ,
 * field: string,
 * label: string,
 * value: any,
 * options?: Array<any>,
 * error: boolean,
 * required: boolean,
 * helperText: string,
 * noWritable: boolean,
 * disabled: boolean,
 * fileView: React.ReactNode,
 * onChange: (field:string, value:any){},
 * onBlur: (field:string, value:any){},
 * onClickAway: ()=>{},
 * sx : import('@mui/material').SxProps,
 * sxSheet :  import('@mui/material').SxProps,
 * }} props
 * @returns {React.ReactNode}
 */
const InputLine = ({
  type = 'text',
  field,
  label,
  value,
  onChange,
  onBlur,
  onClickAway,
  options = [],
  error,
  required,
  helperText,
  noWritable = false,
  disabled = false,
  fileView = null,
  sx,
  sxSheet
}) => {
  const [data, setDate] = useState(value);

  return (
    <Stack width={1} spacing={0.5}>
      <Box fontSize={13} fontWeight={400}>
        {label}
        {required ? (
          <Box component="span" position="relative" pl={0.5} width={50}>
            <Typography position="absolute" top={-5} left={0} color="red" fontSize={16}>
              *
            </Typography>
          </Box>
        ) : (
          ''
        )}
      </Box>
      <Sheet
        variant="soft"
        sx={{
          borderRadius: 'sm',
          height: 40,
          display: 'flex',
          alignItems: 'center',
          ...(error && { outline: `0.1px solid red` }),
          ...(fileView && { bgcolor: 'transparent', border: 'none' }),
          ...sxSheet
        }}
      >
        {type === 'date' && (
          <MuiCustomDateInput disabled={disabled} value={value} onChange={(val) => onChange(field, val)} />
        )}
        {type === 'checkbox' && (
          <Checkbox
            readOnly={noWritable}
            disabled={disabled}
            sx={{ ml: 1, ...sx }}
            checked={Boolean(value)}
            onChange={(e) => onChange(field, e.target.checked)}
          />
        )}
        {type === 'file' && (
          <MuiUploadFileInput
            disabled={disabled}
            placeholder="Pièces jointes"
            value={value}
            onChange={(val) => onChange(field, val)}
            fileView={fileView}
          />
        )}
        {(type === 'text' || type === 'number') && (
          <ClickAwayListener onClickAway={onClickAway}>
            <Input
              type={type}
              fullWidth
              value={value}
              readOnly={noWritable}
              disabled={disabled}
              onChange={(e) => onChange(field, e.currentTarget.value)}
              variant="soft"
              multiline
              sx={{ fontSize: 11, fontWeight: 'bold', ...sx }}
            />
          </ClickAwayListener>
        )}
        {type === 'select' && (
          <Select
            variant="plain"
            disabled={disabled}
            value={value}
            onChange={(_, val) => onChange(field, val)}
            slotProps={{ listbox: { sx: { zIndex: (t) => t.zIndex.modal + 1002, fontSize: 13, fontWeight: 600 } } }}
            sx={{ width: 1, bgcolor: 'transparent', fontSize: 13, fontWeight: 600, ...sx }}
          >
            {options.map((opt, index) => (
              <Option key={opt + index} value={opt}>
                {opt}
              </Option>
            ))}
          </Select>
        )}

        {type === 'Autocomplete' && (
          <ClickAwayListener onClickAway={onClickAway}>
            <Autocomplete
              options={options}
              freeSolo
              multiple
              size="small"
              disabled={disabled}
              disableCloseOnSelect
              value={isString(data) && !isEmpty(data) ? [data] : isArray(data) ? data : []}
              onChange={(_, val) => {
                onChange(field, val);
                setDate(val);
              }}
              renderInput={(params) => (
                <CustomAutocompletInput {...params} p="1px 3px" border="none" size="small" fullWidth onBlur={onBlur} />
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    color="neutral"
                    variant="plain"
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              sx={{ width: 1, bgcolor: 'transparent', fontSize: 13, fontWeight: 600, p: 0, ...sx }}
            />
          </ClickAwayListener>
        )}
      </Sheet>
      <Typography fontSize={9} color="error">
        {type === 'date' && error ? 'Date invalide' : helperText}
      </Typography>
    </Stack>
  );
};

export default InputLine;
