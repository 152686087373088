import { createSlice } from '@reduxjs/toolkit';
import { auth, firebasedb } from 'src/contexts/FirebaseContext';
import { ChannelType, CommMessageType } from 'src/models/communication_types';
import { changeOnObjectWithOldVal } from 'src/utils/changeOnObject';
import { serverTime } from 'src/utils/serverTime';
import { multipleFilesSave, saveFile } from '../document';
import {
  createCommunicationChannelNotif,
  createCommunityMessageAnswerNotif,
  createCommunityMessageNotif
} from './notification';

const initialState = {
  error: false,
  isLoading: false,
  channels: [],
  parentId: null,
  selected: null,
  selectedMessage: null,
  messages: {}
};

const slice = createSlice({
  name: 'communication',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    createdSuccess(state, action) {
      state.isLoading = false;
    },

    onSelectedChannel(state, action) {
      const exist = state.channels.find((chan) => chan.id === action.payload.id);
      state.selected = exist;
      if (exist && action.payload?.callback) {
        action.payload?.callback();
      }
    },

    onSelectedMessage(state, action) {
      const channelMessage = state.messages[state?.selected?.id] || [];
      const exist = channelMessage.find((msg) => msg.id === action.payload?.messageId);
      state.selectedMessage = exist;
    },

    onRemoveSelectedChannel(state) {
      state.selectedMessage = null;
      state.selected = null;
    },

    onRemoveSelectedMessage(state) {
      state.selectedMessage = null;
    },

    addChannelsAdd(state, action) {
      if (state.channels) {
        const exist = state.channels.find((chan) => chan.id === action.payload.id);
        if (!exist) {
          state.channels = [...(state.channels || []), action.payload];
        }
        return;
      }
      state.channels = [action.payload];
    },

    updateChannels(state, action) {
      const { channel } = action.payload;

      if (state.channels?.length) {
        const exist = state.channels.findIndex((chan) => chan.id === channel.id);

        if (exist !== -1) {
          const newChannels = [...(state.channels || [])];
          newChannels.splice(exist, 1, channel);
          state.channels = newChannels;
          return;
        }
        state.channels = [...(state.channels || []), channel];
        return;
      }
      state.channels = [channel];
    },

    removeChannel(state, action) {
      const { channelId } = action.payload;
      const channels = state.channels || [];
      if (channelId === state?.selected?.id) {
        state.selected = null;
        state.selectedMessage = null;
      }

      if (channels?.length) {
        state.channels = channels.filter((_chan) => _chan?.id !== channelId);
      }
    },

    addMessageList(state, action) {
      const { channelId, message, allMessages } = action.payload;

      const messages = state.messages[channelId];

      if (allMessages) {
        state.messages[channelId] = allMessages;
        return;
      }

      if (messages?.length) {
        const exist = messages.findIndex((msg) => msg?.id === message?.id);

        if (exist !== -1) {
          const newMessages = [...messages];
          newMessages.splice(exist, 1, message);
          state.messages[channelId] = newMessages;
          return;
        }

        state.messages[channelId] = [...(messages || []), message];
        return;
      }
      state.messages[channelId] = [message];
    },

    removeMessage(state, action) {
      const { channelId, messageId } = action.payload;

      const messages = state.messages[channelId];

      if (messageId === state.selectedMessage?.id) {
        state.selectedMessage = null;
      }

      if (messages?.length) {
        const exist = messages.findIndex((chan) => chan.id === messageId);

        if (exist === -1) return;

        const newMessages = [...messages];
        newMessages.splice(exist, 1);
        state.messages[channelId] = newMessages;
      }
    },

    updateMessageAttachment(state, action) {
      const { messageId, attachments, channelId } = action.payload;

      const messages = state.messages[channelId];

      const exist = messages.find((chan) => chan?.id === messageId);

      if (exist) {
        exist.attachments = attachments;
      }

      state.messages[channelId] = messages;
    },

    updateMessageVoice(state, action) {
      const { messageId, voice, channelId } = action.payload;
      const messages = state.messages[channelId];
      const exist = messages.find((chan) => chan.id === messageId);

      if (exist) {
        exist.voice = voice;
      }
      state.messages[channelId] = messages;
    }
  }
});

export default slice.reducer;
export const {
  onSelectedChannel,
  addChannelsAdd,
  addMessageList,
  onRemoveSelectedChannel,
  onSelectedMessage,
  onRemoveSelectedMessage
} = slice.actions;

// --------------------------------CHANEL-----------------------------------
/**
 *
 * @param {{ channel: ChannelType, callback: function?, projectId: string?, onError: function }} param
 * @returns
 */
export function createChannel({ channel, callback, projectId, onError }) {
  return async (dispatch) => {
    try {
      const { displayName, uid, email } = auth.currentUser;

      const baseRef = projectId || 'global';
      const channelRef = firebasedb.ref('channels').child(baseRef);
      const id = channelRef.push().key;
      let tosave = {
        ...channel,
        id: id,
        created_by: {
          id: uid,
          name: displayName
        },
        isClosed: false,
        members: [{ id: uid, name: displayName, email: email }, ...(channel.members || [])],
        created_at: serverTime(true)
      };

      console.group('createChannel');
      //console.log('channel', tosave);
      //console.log('path', channelRef.child(id).parent);
      console.groupEnd();

      await channelRef.child(id).update(tosave);

      if (callback) {
        callback();
      }
    } catch (error) {
      if (onError) {
        onError();
      }
      console.error(error);
    }
  };
}

/**
 *
 * @param {{ channel: ChannelType, oldChannel:ChannelType, callback: function?, projectId: string?, onError: function }} param
 * @returns
 */
export function updateChannel({ channel, oldChannel, projectId = null, callback, onError }) {
  return async (dispatch) => {
    try {
      const baseRef = projectId || 'global';

      const channelRef = firebasedb.ref('channels').child(baseRef);

      await channelRef.child(channel.id).update(channel);
      if (callback) {
        callback();
      }

      const change = changeOnObjectWithOldVal(oldChannel, channel);

      dispatch(
        createCommunicationChannelNotif({
          change,
          channel
        })
      );
    } catch (error) {
      if (onError) {
        onError();
      }
      console.error(error);
    }
  };
}

export function deleteChannel({ channelId, projectId = null, callback, onError }) {
  return async (dispatch) => {
    try {
      const baseRef = projectId || 'global';
      const channelRef = firebasedb.ref('channels').child(baseRef);

      await channelRef.child(channelId).remove();

      if (callback) callback();

      await firebasedb.ref('com_messages').child(channelId).remove();
    } catch (error) {
      if (onError) {
        onError();
      }
      console.error(error);
    }
  };
}

export function showChannelList({ projectId, onChange, onEndLoading }) {
  return async (dispatch) => {
    try {
      const baseRef = projectId || 'global';
      const channelsRef = firebasedb.ref('channels').child(baseRef);

      onChange(channelsRef);

      channelsRef.on('child_added', (snap) => {
        dispatch(slice.actions.addChannelsAdd(snap.val()));
        onEndLoading();
      });

      channelsRef.on('child_changed', (snap) => {
        dispatch(slice.actions.updateChannels({ channel: { id: snap.key, ...snap.val() } }));
        onEndLoading();
      });
      channelsRef.on('child_removed', (snap) => {
        dispatch(slice.actions.removeChannel({ channelId: snap.key }));
        onEndLoading();
      });

      channelsRef.on('value', (snap) => {
        dispatch(slice.actions.addChannelsAdd(snap.val()));
        onEndLoading();
      });

      channelsRef.onDisconnect(() => {
        onEndLoading();
      });
    } catch (error) {
      console.error(error);
    }
  };
}

// --------------------------------MESSAGE-----------------------------------
/**
 *
 * @param {{channel: ChannelType, messageObject: CommMessageType, callback: function?, onError: function?, users: Array }} param
 * @returns
 */
export function createCommMessage({ channel, messageObject, callback, onError, users = [] }) {
  return async (dispatch) => {
    try {
      const { displayName, uid } = auth.currentUser;
      const messageRef = firebasedb.ref('com_messages');

      const send_message_object = {
        user: {
          name: displayName,
          id: uid
        },
        created_at: serverTime(true),
        ...messageObject
      };

      dispatch(slice.actions.addMessageList({ channelId: channel.id, message: send_message_object }));

      await messageRef.child(channel.id).child(messageObject.id).set(send_message_object);

      const members = channel?.members || [];
      const removeSender = members?.filter((_one) => _one.id !== uid);

      const _readState = channel?.readState || {};
      let readState = { ..._readState };

      removeSender.forEach((_one) => (readState[_one.id] = [...(readState[_one.id] || []), messageObject.id]));

      dispatch(updateChannel({ channel: { ...channel, readState }, oldChannel: channel }));

      dispatch(createCommunityMessageNotif({ message: send_message_object, channel: channel, users }));

      if (callback) {
        callback();
      }
    } catch (error) {
      if (onError) {
        onError();
      }
      console.error(error);
    }
  };
}

export function createCommMessageWithAttachement({ channel, messageObject, files, callback, onError, users = [] }) {
  return async (dispatch) => {
    try {
      const { uid } = auth.currentUser;
      const messageRef = firebasedb.ref('com_messages');

      const tempFiles = files.map((_file) => {
        const { file, ...other } = _file;
        return { ...other };
      });

      const filesToSave = files.map((_file) => {
        const { file, ...other } = _file;
        return file;
      });

      dispatch(
        slice.actions.addMessageList({
          channelId: channel.id,
          message: { ...messageObject, attachments: tempFiles }
        })
      );

      if (callback) {
        callback();
      }

      const onFileSave = (returnFiles) => {
        const send_message_object = { ...messageObject, attachments: returnFiles };
        messageRef
          .child(channel.id)
          .child(messageObject.id)
          .set(send_message_object)
          .then(() => {
            callback && callback();
            dispatch(createCommunityMessageNotif({ message: send_message_object, channel: channel, users }));

            const members = channel?.members || [];
            const removeSender = members?.filter((_one) => _one.id !== uid);

            const _readState = channel?.readState || {};
            let readState = { ..._readState };

            removeSender.forEach((_one) => (readState[_one.id] = [...(readState[_one.id] || []), messageObject.id]));

            dispatch(updateChannel({ channel: { ...channel, readState }, oldChannel: channel }));
          });
      };

      let uploadingFile = [];
      const messageId = messageObject.id;

      const updateOnLoadingFile = (_callback) => {
        const result = _callback(uploadingFile);
        uploadingFile = result;
        dispatch(
          slice.actions.updateMessageAttachment({
            channelId: channel.id,
            messageId,
            attachments: result
          })
        );
      };

      await multipleFilesSave(filesToSave, onFileSave, null, updateOnLoadingFile);
    } catch (error) {
      if (onError) {
        onError();
      }
      console.error(error);
    }
  };
}

export function createCommMessageWithVoice({ channel, messageObject, voice, callback, onError, users = [] }) {
  return async (dispatch) => {
    try {
      const { uid } = auth.currentUser;
      const messageRef = firebasedb.ref('com_messages');

      const { file: filesToSave, ...tempFiles } = voice;

      dispatch(
        slice.actions.addMessageList({ channelId: channel.id, message: { ...messageObject, voice: tempFiles } })
      );

      if (callback) {
        callback();
      }

      const onFileSave = (returnVoice) => {
        const send_message_object = { ...messageObject, voice: { ...returnVoice, seconds: voice?.seconds || 0 } };

        messageRef
          .child(channel.id)
          .child(messageObject.id)
          .set(send_message_object)
          .then(() => {
            callback && callback();
            dispatch(createCommunityMessageNotif({ message: send_message_object, channel: channel, users }));

            const members = channel?.members || [];
            const removeSender = members?.filter((_one) => _one.id !== uid);

            const _readState = channel?.readState || {};
            let readState = { ..._readState };

            removeSender.forEach((_one) => (readState[_one.id] = [...(readState[_one.id] || []), messageObject.id]));

            dispatch(updateChannel({ channel: { ...channel, readState }, oldChannel: channel }));
          });
      };

      const messageId = messageObject.id;

      const updateOnLoadingFile = (uploadingVoice) => {
        dispatch(
          slice.actions.updateMessageVoice({
            channelId: channel.id,
            messageId,
            voice: { ...uploadingVoice, seconds: voice?.seconds }
          })
        );
      };

      await saveFile(filesToSave, onFileSave, null, updateOnLoadingFile);
    } catch (error) {
      if (onError) {
        onError();
      }
      console.error(error);
    }
  };
}

export function showMessageList({ channelId, onChange, onLoadingEnd }) {
  return async (dispatch) => {
    try {
      const messageRef = firebasedb.ref('com_messages').child(channelId);

      onChange(messageRef);

      messageRef.on('value', (snap) => {
        if (snap.exists()) {
          const data = [];
          Object.entries(snap.val()).forEach(([_, one]) => data.push(one));
          dispatch(slice.actions.addMessageList({ channelId, allMessages: data }));
        }
        onLoadingEnd();
      });

      // messageRef.on('child_added', (snap) => {
      //   if (!firstCharge) {
      //     dispatch(slice.actions.addMessageList({ channelId, message: snap.val() }));
      //     onLoadingEnd();
      //   }
      // });

      messageRef.on('child_changed', (snap) => {
        //console.log(snap.val());
        dispatch(slice.actions.addMessageList({ channelId, message: snap.val() }));
        onLoadingEnd();
      });

      messageRef.on('child_removed', (snap) => {
        //console.log(snap.val());
        dispatch(slice.actions.removeMessage({ channelId, messageId: snap.key }));
        onLoadingEnd();
      });

      messageRef.onDisconnect(() => {
        onLoadingEnd();
      });
    } catch (error) {
      console.error(error);
    }
  };
}

/**
 *
 * @param {{channelId: string, message: any, callback: function, onError: function}} param
 */
export function updateCommMessageReaction({ channelId, message, callback, onError }) {
  return async (dispatch) => {
    try {
      const messageRef = firebasedb.ref('com_messages').child(channelId).child(message.id);

      await messageRef.set(message);
      if (callback) callback();
    } catch (error) {
      console.error(error);
      if (onError) {
        onError();
      }
    }
  };
}

export function createCommResponseMessage({
  channel,
  answer,
  messageId,
  files = null,
  voice = null,
  messageObject,
  users = [],
  callback,
  onError
}) {
  return async (dispatch, getState) => {
    try {
      const channelId = channel?.id;
      const answersId = answer?.id;
      const theAnswers = messageObject?.answers || [];
      const thisAnswerIndex = theAnswers?.findIndex((_one) => _one.id === answersId);

      const messageRef = firebasedb.ref('com_messages').child(channelId).child(messageId);

      if (!files && !voice) {
        await messageRef.set(messageObject);
        dispatch(createCommunityMessageAnswerNotif({ message: messageObject, channel, answer, users }));
        if (callback) callback();
        return;
      }

      if (voice) {
        const { file: filesToSave, ...tempFiles } = voice;

        if (thisAnswerIndex !== -1) {
          const thisAnswer = theAnswers[thisAnswerIndex];
          const theFinalAnswers = [...theAnswers];
          theFinalAnswers.splice(thisAnswerIndex, 1, { ...thisAnswer, voice: tempFiles });

          dispatch(
            slice.actions.addMessageList({ channelId, message: { ...messageObject, answers: theFinalAnswers } })
          );

          if (callback) {
            callback();
          }

          const onFileSave = (returnVoice) => {
            let _theFinalAnswers = [...theFinalAnswers];
            _theFinalAnswers.splice(thisAnswerIndex, 1, {
              ...thisAnswer,
              voice: { ...returnVoice, seconds: voice?.seconds || 0 }
            });
            const send_message = { ...messageObject, answers: _theFinalAnswers };

            messageRef.set(send_message).then(() => {
              dispatch(createCommunityMessageAnswerNotif({ message: send_message, channel, answer, users }));
              if (callback) callback();
            });
          };

          const updateOnLoadingFile = (uploadingVoice) => {
            let _theFinalAnswers = [...theFinalAnswers];
            _theFinalAnswers.splice(thisAnswerIndex, 1, {
              ...thisAnswer,
              voice: { ...uploadingVoice, seconds: voice?.seconds }
            });

            dispatch(
              slice.actions.addMessageList({ channelId, message: { ...messageObject, answers: _theFinalAnswers } })
            );
          };

          await saveFile(filesToSave, onFileSave, null, updateOnLoadingFile);
        }
      }

      if (files && files?.length) {
        const tempFiles = files.map((_file) => {
          const { file, ...other } = _file;
          return { ...other };
        });

        const filesToSave = files.map((_file) => {
          const { file, ...other } = _file;
          return file;
        });

        if (thisAnswerIndex !== -1) {
          const thisAnswer = theAnswers[thisAnswerIndex];
          const theFinalAnswers = [...theAnswers];
          theFinalAnswers.splice(thisAnswerIndex, 1, { ...thisAnswer, attachments: tempFiles });

          dispatch(
            slice.actions.addMessageList({ channelId, message: { ...messageObject, answers: theFinalAnswers } })
          );

          if (callback) {
            callback();
          }

          const onFileSave = (returnFiles) => {
            let _theFinalAnswers = [...theFinalAnswers];
            _theFinalAnswers.splice(thisAnswerIndex, 1, { ...thisAnswer, attachments: returnFiles });
            const send_message = { ...messageObject, answers: _theFinalAnswers };

            messageRef.set(send_message).then(() => {
              dispatch(createCommunityMessageAnswerNotif({ message: send_message, channel, answer, users }));
              if (callback) callback();
            });
          };

          let uploadingFile = [];

          const updateOnLoadingFile = (_callback) => {
            const result = _callback(uploadingFile);
            uploadingFile = result;
            let _theFinalAnswers = [...theFinalAnswers];
            _theFinalAnswers.splice(thisAnswerIndex, 1, { ...thisAnswer, attachments: result });

            dispatch(
              slice.actions.addMessageList({ channelId, message: { ...messageObject, answers: _theFinalAnswers } })
            );
          };

          await multipleFilesSave(filesToSave, onFileSave, null, updateOnLoadingFile);
        }
      }
    } catch (error) {
      console.error(error);
      if (onError) return onError();
    }
  };
}

export function deleteCommMessage({ channelId, messageId, message, answersId = null, callback }) {
  return async (dispatch) => {
    const messageRef = firebasedb.ref('com_messages').child(channelId).child(messageId);

    if (!answersId) {
      await messageRef.remove();
      if (callback) callback();
      return;
    }

    let answers = message?.answers;
    if (answers?.length) {
      const answerIndex = answers.findIndex((_one) => _one.id === answersId);
      let theAnswers = [...answers];
      theAnswers.splice(answerIndex, 1);
      //console.log({ theAnswers });
      await messageRef.set({ ...message, answers: theAnswers });
      if (callback) callback();
    }
  };
}
