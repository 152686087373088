import { gDate } from 'src/utils/formatTime';

export default class Mail {
  constructor({ id, from, to, cc, cci, subject, body, sentDate, isRead, isImportant, hasAttachments, orginalMail }) {
    this.id = id;
    this.from = from;
    this.to = to;
    this.cc = cc;
    this.cci = cci;
    this.subject = subject;
    this.body = body;
    this.sentDate = sentDate;
    this.isRead = isRead;
    this.isImportant = isImportant;
    this.hasAttachments = hasAttachments;
    this.orginalMail = orginalMail;
  }

  /**
   *
   * @param msMail
   * @returns {Mail}
   */
  static blank() {
    return new Mail({
      id: 'bank',
      from: {
        name: 'Random user',
        email: 'random@user.com'
      },
      to: [
        {
          name: 'Random user',
          email: 'random@user.com'
        }
      ],
      cc: [],
      cci: [],
      subject: 'Blank email',
      body: 'Mail content',
      sentDate: new Date(),
      isRead: false,
      isImportant: false,
      hasAttachments: false,
      orginalMail: {}
    });
  }

  /**
   *
   * @param msMail
   * @returns {Mail}
   */
  static fromOutlook(msMail) {
    return new Mail({
      id: msMail?.id,
      from: transformToPerson(msMail?.from?.emailAddress),
      to: transformToPersonFromList(msMail?.toRecipients),
      cc: transformToPersonFromList(msMail?.ccRecipients),
      cci: transformToPersonFromList(msMail?.bccRecipients),
      subject: msMail?.subject,
      body: msMail?.body?.content,
      sentDate: gDate(msMail?.sentDateTime),
      isRead: msMail?.isRead,
      isImportant: msMail?.importance !== 'normal',
      hasAttachments: msMail?.hasAttachments || false,
      orginalMail: msMail?.orginalMail
    });
  }
}

const transformToPerson = (user) => {
  return {
    email: user?.address,
    name: user?.name
  };
};

const transformToPersonFromList = (list = []) => {
  return list?.map((el) => transformToPerson(el?.emailAddress));
};
