import { Autocomplete, Button } from '@mui/material';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { useState, createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ActionModal from 'src/components/ActionModal';
import { CustomAutocompletInput } from 'src/components/CustomInput';
import { AFFECT_STATE_VALIDATION, NOTIFICATION_TYPES } from 'src/constants';
import { AFFECT_STEP } from 'src/constants/affectation';
import { transformUserToAccess } from 'src/helpers/user';
import useToggle, { useToggleV2 } from 'src/hooks/useToggle';
import useUserList from 'src/hooks/useUserList';
import { AffectationPdfModal } from 'src/pages/dashboard/AffectationPdf';
import { getAffectationById, updateAffectation } from 'src/redux/slices/affectations';
import { useDispatch, useSelector } from 'src/redux/store';
import AffectDetails from 'src/section/affectation/AffectDetails';

export const AffectationContextProvider = createContext({
  openStep: null,
  activeStep: 0,
  affectType: '',
  toprint: (val) => {},
  onDirectPrint: (val) => {},
  closeprint: () => {},
  currentStep: AFFECT_STEP[0],
  onClose: (val) => {},
  openDetails: (val) => {},
  openDetailsById: (id, type = null) => {},
  handleNextStep: () => {},
  handleBackStep: () => {},
  setOpenStep: (val) => {},
  setActiveStep: (val) => {},
  setAffectType: (val) => {},
  setCurrentStep: (val) => {}
});

export const useAffectationContext = () => useContext(AffectationContextProvider);

export const AffectationProvider = ({ children }) => {
  const [details, openDetails] = useState(null);
  const [record, setRecord] = useState(null);
  const [openStep, setOpenStep] = useState({ 0: true });
  const [activeStep, setActiveStep] = useState(0);
  const [currentStep, setCurrentStep] = useState(AFFECT_STEP[0]);
  const [affectType, setAffectType] = useState('');
  const [show, onShow, endShow] = useToggleV2();
  const { open, handleOpen, handleClose } = useToggle();
  const [canPrint, setCanPrint] = useState(null);
  const { users } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleNextStep = () => {
    setActiveStep((prev) => {
      const step = prev + 1;
      setCurrentStep(AFFECT_STEP[step]);
      const _opens = openStep;
      _opens[step] = true;
      setOpenStep(_opens);
      return step;
    });
  };

  const handleBackStep = () => {
    setActiveStep((prev) => {
      const step = prev - 1;
      setCurrentStep(AFFECT_STEP[step]);
      const _opens = openStep;
      _opens[step + 1] = false;
      _opens[step] = true;
      setOpenStep(_opens);
      return step;
    });
  };

  const closePrint = () => {
    endShow();
    setCanPrint(null);
  };

  const onDirectPrint = (_details = null) => {
    const res = _details || record;
    setRecord(res);
    closePrint();
    handleOpen();
    // navigate('/affectation-print', { replace: false, state: res });
    // setRecord(null);
  };

  const onHandleClosePrint = () => {
    handleClose();
    setRecord(null);
  };

  const addPrinter = () => {
    closePrint();
    const res = details || record;
    dispatch(
      updateAffectation({
        affect: { ...res, state: AFFECT_STATE_VALIDATION.PENDING, canPrint: transformUserToAccess(canPrint) },
        oldAffect: details,
        isPrint: true,
        callback: () => {
          enqueueSnackbar('Demande envoyée avec succès', { variant: 'success' });
        }
      })
    );
  };

  const toPrint = (val) => {
    setRecord(val);
    onShow();
  };

  const openDetailsById = (id, type) => {
    dispatch(
      getAffectationById(
        id,
        (value) => openDetails(value),
        () => {
          enqueueSnackbar(`Cette affectation n'existe plus`, { variant: 'warning' });
        }
      )
    );
  };

  const store = {
    openStep,
    onDirectPrint,
    toprint: toPrint,
    closeprint: closePrint,
    activeStep,
    openDetails,
    openDetailsById,
    setOpenStep,
    currentStep,
    setActiveStep,
    setCurrentStep,
    handleNextStep,
    handleBackStep,
    setAffectType,
    affectType
  };

  return (
    <AffectationContextProvider.Provider value={store}>
      {children}
      {details && <AffectDetails open={!isEmpty(details)} onClose={() => openDetails(null)} affect={details} />}
      {show && (
        <ActionModal
          open={show}
          onClose={closePrint}
          title="Voulez-vous imprimer l'affectation ?"
          color="info"
          desc="Envoyer la demande d'impression à:"
          removeZIndex
          moreAction={
            <Autocomplete
              size="small"
              fullWidth
              options={users}
              getOptionLabel={(op) => op.displayName || ''}
              onChange={(e, val) => setCanPrint(val)}
              isOptionEqualToValue={(op, va) => op.id === va.id}
              renderInput={(params) => <CustomAutocompletInput {...params} size="small" />}
            />
          }
          action={
            <Button color="info" variant="contained" onClick={canPrint ? addPrinter : onDirectPrint}>
              {canPrint ? 'Envoyé' : 'Imprimer'}
            </Button>
          }
        />
      )}
      {open && <AffectationPdfModal open={open} onClose={onHandleClosePrint} affect={record} />}
    </AffectationContextProvider.Provider>
  );
};
