import React, { useState, useRef, useMemo } from 'react';
import { Icon } from '@iconify/react';
import frLocale from 'date-fns/locale/fr';
import { isEmpty, isEqual, isString } from 'lodash';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Drawer,
  IconButton,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  styled,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { AnimatePresence, motion } from 'framer-motion';
import { varFadeInLeft } from 'src/components/animate';
import CIconButton from 'src/components/CIconButton';
import { AffectationType } from 'src/models/Affectation_m';
import { format } from 'date-fns';
import { gDate } from 'src/utils/formatTime';
import { CustomAutocompletInput, MuiCustomInput } from 'src/components/CustomInput';
import { Avatar, DatePicker } from 'antd';
import createAvatar from 'src/utils/createAvatar';
import useAuth from 'src/hooks/useAuth';
import AffectAttach from './AffectAttach';
import { baseColors } from 'src/constants/color';
import useToggle, { useToggleV2 } from 'src/hooks/useToggle';
import { MAvatar } from 'src/components/@material-extend';
import MenuPopover from 'src/components/MenuPopover';
import { SelectCollaborates } from '../project/ProjectCardOPtion';
import Scrollbar from 'src/components/Scrollbar';
import { AFFECT_ANNOTATION, AFFECT_OPERATION_TYPE } from 'src/constants/affectation';
import { nanoid } from '@reduxjs/toolkit';
import AffectValidation from './AffectValidation';
import EllipsisText from 'src/components/EllipsisText';
import { AFFECT_STATE_VALIDATION } from 'src/constants';
import ActionModal from 'src/components/ActionModal';
import { useDispatch, useSelector } from 'src/redux/store';
import { useSnackbar } from 'notistack';
import { AffectComment } from './AffectComment';
import { priorityColor } from 'src/helpers/priorityColor';
import { affectValidateAction } from './affectValidateAction';
import PriorityFlags from '../tasks/PriorityFlags';
import { useAffectationContext } from 'src/contexts/AffectationContext';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const LabelStyle = styled(Typography)(({ theme, ...rest }) => ({
  ...theme.typography.body2,
  fontWeight: 'bold',
  fontSize: 13,
  flexShrink: 0,
  // color: theme.palette.text.secondary,
  ...rest
}));

/**
 *
 * @param {{
 *   open: Boolean,
 *   onClose: Function,
 *   affect: AffectationType
 * }}
 *
 */
export default function AffectDetails({ open, onClose, affect }) {
  const theme = useTheme();
  const { user } = useAuth();
  const { users } = useSelector((state) => state.user);
  const [details, setDetails] = useState({ ...affect });
  const [openAlert, setOpenAlert] = useState(null);
  const [saveAlert, setSaveAlert] = useState(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { toprint, onDirectPrint } = useAffectationContext();

  const handleCloseAlert = () => {
    setOpenAlert(null);
  };

  const handleDetailChange = (name, value) => {
    let _newDetailsValues = { ...details, [name]: value };
    setDetails({ ..._newDetailsValues });
  };

  const isManager = useMemo(
    () => Boolean(details.assigne.responsable.find((_one) => _one?.id === user.id)),
    [details, user.id]
  );

  const isSupManager = useMemo(
    () => Boolean(user.id === details?.responsable?.id || user.id === details?.createdBy?.id),
    [details, user.id]
  );

  const onValidate = (state = null) => {
    const current_user = users.find((_user) => _user.id === details.responsable.id);
    let canPrint = null;

    if (current_user) {
      canPrint = current_user?.affectation_print || null;
    }

    affectValidateAction(
      details,
      affect,
      state,
      user,
      setOpenAlert,
      onClose,
      enqueueSnackbar,
      dispatch,
      toprint,
      canPrint
    );
  };

  const closeWithoutSave = () => {
    setSaveAlert(null);
    onClose();
  };

  const closeWithSaved = () => {
    setSaveAlert(null);
    onClose();
    onValidate(null);
  };

  const handleSaveonClose = () => {
    if (!isEqual(affect, details)) {
      return setSaveAlert('Voulez-vous enregistrer les modifications apportées ?');
    }
    onClose();
  };

  const saveColor = () => {
    const isUnAssigned = details?.state === AFFECT_STATE_VALIDATION.NOT_ASSIGN;
    return isUnAssigned ? 'inherit' : 'success';
  };

  const onPrint = () => {
    onClose();
    onDirectPrint(affect);
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleSaveonClose}
        transitionDuration={2000}
        PaperProps={{
          sx: { width: { xs: 1, md: 1 }, bgcolor: 'transparent' }
        }}
      >
        <AnimatePresence>
          <Card
            component={motion.div}
            {...varFadeInLeft}
            sx={{ position: 'relative', m: { xs: 1, sm: 3 }, height: { xs: 1, md: '99vh' } }}
          >
            <Stack
              px={2}
              py={0.5}
              direction="row"
              width={1}
              bgcolor={(t) => t.palette.action.selected}
              justifyContent="space-between"
              alignItems="center"
            >
              <AffectNumber
                details={affect}
                handleDetailChange={handleDetailChange}
                disabled={Boolean(affect.correspondance.annotation?.length)}
              />
              {/* <Typography fontWeight="bold" fontSize={15} color="grey.500">
                {affect.correspondance.reference}
              </Typography> */}

              <Stack alignItems="center" py={0.5} width={0.6}>
                <LabelStyle color="secondary" fontSize={11}>
                  Expéditeur
                </LabelStyle>
                <EllipsisText
                  text={affect.expediteur.titre}
                  style={{ fontSize: 14, fontWeight: 'bold', color: 'text.secondary', align: 'center' }}
                />
              </Stack>

              <Stack direction={{ xs: 'column-reverse', md: 'row' }} spacing={3} alignItems="flex-end">
                <Stack direction="row" spacing={1}>
                  {details?.canPrint?.id === user.id && (
                    <IconButton size="small" onClick={onPrint}>
                      <Icon icon="fluent:print-24-filled" height={25} />
                    </IconButton>
                  )}
                  <Stack>
                    <Button variant="contained" color={saveColor()} size="small" onClick={closeWithSaved}>
                      Enregistrer
                    </Button>
                  </Stack>
                  <AffectValidation affect={details} user={user} onChange={onValidate} isButton />
                </Stack>
                <Stack width="max-content">
                  <Tooltip title="Back">
                    <CIconButton color="error" onClick={handleSaveonClose} sx={{ mr: 1 }}>
                      <Icon icon="eva:close-fill" height={20} color="red" />
                    </CIconButton>
                  </Tooltip>
                </Stack>
              </Stack>
            </Stack>
            <Divider />
            <Stack height={1}>
              <Stack
                flexWrap="wrap"
                height="max-content"
                width={1}
                direction="row"
                spacing={2}
                rowGap={3}
                p={2}
                justifyContent="space-between"
                borderBottom={`1px solid ${baseColors.GREYLIGHT}`}
              >
                <Stack
                  spacing={2}
                  direction="row"
                  divider={<Divider sx={{ height: 50 }} light orientation="vertical" />}
                >
                  <ResponsableView details={details} handleAssigne={handleDetailChange} />

                  <PriorityView details={details} handleDetailChange={handleDetailChange} />

                  <DateView
                    details={details}
                    handleDetailChange={handleDetailChange}
                    title="Date d'arrivée"
                    field="dateArr"
                    disabled={Boolean(affect.correspondance.annotation?.length)}
                  />

                  <DateView
                    details={details}
                    handleDetailChange={handleDetailChange}
                    title="Date d'échéance"
                    field="dateDue"
                    disabled={!isSupManager}
                  />

                  <Stack width={1} spacing={1} justifyContent="start">
                    <LabelStyle align="start" fontSize={14} sx={{ position: 'relative' }}>
                      Destinataire
                      <span
                        style={{
                          position: 'absolute',
                          left: 90,
                          fontSize: 25,
                          top: -10,
                          color: theme.palette.primary.main
                        }}
                      >
                        *
                      </span>
                    </LabelStyle>
                    <Stack direction="row" spacing={3} alignItems="center">
                      <IntervenantView
                        details={details}
                        handleAssigne={handleDetailChange}
                        field="responsable"
                        disabled={!isSupManager}
                      />
                      <Stack>
                        <LabelStyle>
                          {details?.assignedAt?.date ? format(gDate(details.assignedAt.date), 'dd/MM/yyyy') : ''}
                        </LabelStyle>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>

                <Stack
                  spacing={2}
                  direction="row"
                  divider={<Divider sx={{ height: 50 }} light orientation="vertical" />}
                >
                  <IntervenantView
                    details={details}
                    handleAssigne={handleDetailChange}
                    title="Assigner aux collaborateurs"
                    field="collaborateur"
                    disabled={!isSupManager && !isManager}
                  />
                  <DateView
                    details={details}
                    handleDetailChange={handleDetailChange}
                    title="Échéance des collaborateurs"
                    field="collabDue"
                    disabled={!isSupManager && !isManager}
                  />
                </Stack>
              </Stack>

              <Stack height={500} direction={{ xs: 'column', md: 'row' }} px={2} pt={2}>
                <Stack width={{ xs: 1, md: 0.55 }} height={500} spacing={2} position="relative">
                  <Scrollbar>
                    <Stack height={500} spacing={2} pr={2} pl={1}>
                      <Stack spacing={2} width={1}>
                        <LabelStyle fontSize={14}> Objet : </LabelStyle>
                        <MuiCustomInput
                          fullWidth
                          value={details.correspondance.objet}
                          onChange={(e) =>
                            handleDetailChange('correspondance', {
                              ...details.correspondance,
                              objet: e.currentTarget.value
                            })
                          }
                        />
                      </Stack>

                      <Stack spacing={1} width={1}>
                        <LabelStyle fontSize={14} sx={{ position: 'relative' }}>
                          Annotation
                          <span
                            style={{
                              position: 'absolute',
                              left: 80,
                              fontSize: 25,
                              top: -10,
                              color: theme.palette.primary.main
                            }}
                          >
                            *
                          </span>
                        </LabelStyle>
                        <Autocomplete
                          options={AFFECT_ANNOTATION}
                          freeSolo
                          multiple
                          size="small"
                          disabled={!isSupManager}
                          disableCloseOnSelect
                          value={
                            isString(details.correspondance.annotation)
                              ? [details.correspondance.annotation]
                              : details.correspondance.annotation
                          }
                          onChange={(_, newVal) => {
                            handleDetailChange('correspondance', {
                              ...details.correspondance,
                              annotation: newVal
                            });
                          }}
                          renderInput={(params) => <CustomAutocompletInput {...params} size="small" fullWidth />}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option}
                            </li>
                          )}
                        />
                      </Stack>

                      <Stack width={1} direction={{ xs: 'column', md: 'row' }} spacing={1}>
                        <Stack width={1}>
                          <OperationType details={details} handleDetailChange={handleDetailChange} user={user} />
                        </Stack>
                        <Stack width={1}>
                          <LabelStyle> Rappel </LabelStyle>
                          <DateViewRappel details={details} handleDetailChange={handleDetailChange} />
                        </Stack>
                      </Stack>

                      <Stack width={{ xs: 1 }} pt={{ xs: 5 }} display={{ md: 'none' }} spacing={3}>
                        <Scrollbar>
                          <Stack height={1} spacing={3} pr={2} pl={1}>
                            <AffectComment idQ="affect1" details={details} theme={theme} user={user} />
                          </Stack>
                        </Scrollbar>
                      </Stack>
                      <Box height={250} />
                    </Stack>
                  </Scrollbar>
                </Stack>

                <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', md: 'block' } }} />

                <Stack width={{ xs: 1, md: 0.45 }} spacing={3}>
                  <Scrollbar>
                    <Stack height={1} spacing={3} pr={2} pl={1}>
                      {details?.responsable?.id !== user.uid && (
                        <AffectComment idQ="affect2" details={details} theme={theme} user={user} />
                      )}
                    </Stack>
                  </Scrollbar>
                </Stack>
              </Stack>
            </Stack>
            <Stack bgcolor="background.default" position="absolute" pt={1} px={1} height={110} width={0.6} bottom={20}>
              <OperationFileType details={details} handleDetailChange={handleDetailChange} user={user} />
            </Stack>
          </Card>
        </AnimatePresence>
      </Drawer>
      {!isEmpty(openAlert) && (
        <ActionModal
          open={!isEmpty(openAlert)}
          onClose={handleCloseAlert}
          title="Alert"
          color="error"
          desc={openAlert}
          closeText="OK"
        />
      )}
      {!isEmpty(saveAlert) && (
        <ActionModal
          open={!isEmpty(saveAlert)}
          onClose={closeWithoutSave}
          title="Alerte"
          color="warning"
          desc={saveAlert}
          closeText="Quitter"
          action={
            <Button variant="contained" onClick={closeWithSaved}>
              Enregistrer
            </Button>
          }
        />
      )}
    </>
  );
}

/**
 * @param {{ details: AffectationType, disabled: boolean, handleDetailChange: Function }}
 */
export const AffectNumber = ({ details, disabled, handleDetailChange }) => {
  const [value, setValue] = useState(details.correspondance.reference);

  const handleChange = (e) => {
    const val = e.currentTarget.value;
    setValue(val);
    handleDetailChange('correspondance', {
      ...details.correspondance,
      reference: val
    });
  };

  return (
    <MuiCustomInput
      value={value}
      onChange={handleChange}
      disabled={disabled}
      sx={{
        width: 120,
        border: 'none',
        typography: 'overline',
        fontSize: 16,
        fontWeight: 'fontWeightBold',
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent',
          border: 'none',
          outline: 'none'
        }
      }}
    />
  );
};

export const OneAvatar = ({ user, idx, handleRemove }) => {
  const [hover, onHover, endHover] = useToggleV2();

  return (
    <Box display="inline-block" ml={-0.5} onMouseEnter={onHover} onMouseLeave={endHover}>
      {hover ? (
        <Box p={0.1} border={`2px solid ${createAvatar(user?.name).color2}`} borderRadius={10} position="relative">
          <Tooltip key={user.id + idx} title={user?.displayName || user?.name} arrow>
            <Avatar
              alt={user?.displayName || user?.name}
              src={user?.photoURL || user?.avatar}
              style={{
                color: 'white',
                backgroundColor: createAvatar(user?.displayName || user?.name).color2
              }}
            >
              {createAvatar(user?.displayName || user?.name).name}
            </Avatar>
          </Tooltip>
          <Box
            position="absolute"
            borderRadius={10}
            top={0}
            right={0}
            onClick={() => handleRemove(user.id)}
            sx={{ transform: 'translateY(-40%)', bgcolor: 'white', height: 20, cursor: 'pointer' }}
          >
            <Icon icon="eva:close-circle-fill" color={createAvatar(user?.name).color2} height={20} />
          </Box>
        </Box>
      ) : (
        <Tooltip key={user.id + idx} title={user?.displayName || user?.name} arrow>
          <Avatar
            alt={user?.displayName || user?.name}
            src={user?.photoURL || user?.avatar}
            style={{ color: 'white', backgroundColor: createAvatar(user?.displayName || user?.name).color2 }}
          >
            {createAvatar(user?.displayName || user?.name).name}
          </Avatar>
        </Tooltip>
      )}
    </Box>
  );
};

/**
 *
 * @param {{ details: AffectationType, handleAssigne: Function, disabled: boolean }} props
 */
export const ResponsableView = ({ details, handleAssigne, disabled = false }) => {
  const anchorRef = useRef(null);
  const { open, handleOpen, handleClose } = useToggle();
  const [hover, onHover, endHover] = useToggleV2();

  const handeChange = (field, value) => {
    const val = value.map((newValue) => {
      return {
        id: newValue.id,
        name: newValue?.displayName || newValue?.name || null,
        email: newValue.email,
        avatar: newValue?.photoURL || newValue?.photoUrl || newValue?.avatar || null
      };
    });
    handleAssigne('responsable', val.at(0));
  };

  const handleRemove = (id) => {
    handleAssigne('responsable', null);
  };

  return (
    <Stack spacing={1}>
      <LabelStyle width="max-content" fontSize={14}>
        Responsable
      </LabelStyle>
      <Tooltip
        title={details?.responsable?.name || details?.responsable?.displayName || 'Ajouter / Retirer le responsable'}
        arrow
      >
        <Stack alignItems="center" onMouseEnter={onHover} onMouseLeave={endHover}>
          {details?.responsable ? (
            hover ? (
              <Box
                p={0.1}
                border={(t) => `2px solid ${createAvatar(details?.responsable?.name).color2}`}
                borderRadius={10}
                position="relative"
              >
                <MAvatar
                  alt={details?.responsable?.name || details?.responsable?.displayName}
                  src={details?.responsable?.photoURL || details?.responsable?.photoUrl || details?.responsable?.avatar}
                  color={createAvatar(details?.responsable?.name || details?.responsable?.displayName).color}
                  style={{ color: 'white', height: 30, width: 30, fontSize: 13 }}
                >
                  {createAvatar(details?.responsable?.name || details?.responsable?.displayName).name}
                </MAvatar>

                <Box
                  position="absolute"
                  borderRadius={10}
                  top={0}
                  right={0}
                  onClick={handleRemove}
                  sx={{ transform: 'translateY(-40%)', bgcolor: 'white', height: 20, cursor: 'pointer' }}
                >
                  <Icon
                    icon="eva:close-circle-fill"
                    color={createAvatar(details?.responsable?.name).color2}
                    height={20}
                  />
                </Box>
              </Box>
            ) : (
              <Stack>
                <MAvatar
                  alt={details?.responsable?.name || details?.responsable?.displayName}
                  src={details?.responsable?.photoURL || details?.responsable?.photoUrl || details?.responsable?.avatar}
                  color={createAvatar(details?.responsable?.name || details?.responsable?.displayName).color}
                  style={{ color: 'white', height: 35, width: 35, fontSize: 13 }}
                >
                  {createAvatar(details?.responsable?.name || details?.responsable?.displayName).name}
                </MAvatar>
              </Stack>
            )
          ) : (
            <IconButton
              disabled={disabled}
              color="inherit"
              sx={{ border: (t) => `1px dashed ${t.palette.divider}` }}
              onClick={handleOpen}
              ref={anchorRef}
            >
              <Icon icon="octicon:person-add-24" color={baseColors.GREY} width={20} height={20} />
            </IconButton>
          )}
        </Stack>
      </Tooltip>
      {open && (
        <MenuPopover
          zIndex={(theme) => theme?.zIndex?.modal + 5}
          width={350}
          sx={{
            height: { xs: 1, md: 550 },
            bgcolor: 'transparent',
            border: 'none',
            outline: 'none',
            boxShadow: 'none'
          }}
          open={open}
          onClose={handleClose}
          disabledArrow
          anchorEl={anchorRef.current}
          arrow="right-center"
        >
          <SelectCollaborates
            title="intervenants"
            field="responsable"
            currents={details?.responsable ? [details?.responsable] : []}
            onClose={handleClose}
            onChange={handeChange}
            single
          />
        </MenuPopover>
      )}
    </Stack>
  );
};

export const IntervenantView = ({ details, handleAssigne, disabled = false, title = null, field }) => {
  const anchorRef = useRef(null);
  const { open, handleOpen, handleClose } = useToggle();

  const handeChange = (_field, value) => {
    const val = value.map((newValue) => {
      return {
        id: newValue.id,
        name: newValue?.displayName || newValue?.name || null,
        email: newValue.email,
        avatar: newValue?.photoURL || newValue?.photoUrl || newValue?.avatar || null
      };
    });
    handleAssigne('assigne', { ...details.assigne, [field]: value });
  };

  const handleRemove = (id) => {
    const rest = details.assigne[field].filter((one) => one?.id !== id);
    handleAssigne('assigne', { ...details.assigne, [field]: rest });
  };

  return (
    <Stack alignItems="center">
      {!isEmpty(title) && (
        <LabelStyle width="max-content" fontSize={12}>
          {title}
        </LabelStyle>
      )}

      <Stack alignItems="center" direction="row" sx={{ cursor: 'pointer' }} ref={anchorRef}>
        {!isEmpty(details.assigne[field]) && (
          <Avatar.Group maxCount={4}>
            {details.assigne[field].map((user, idx) => (
              <OneAvatar key={user.id + idx} user={user} idx={idx} handleRemove={handleRemove} />
            ))}
          </Avatar.Group>
        )}
        <Tooltip title=" Ajouter / Retirer" arrow>
          <IconButton
            disabled={disabled}
            color="inherit"
            sx={{ border: (t) => `1px dashed ${t.palette.divider}` }}
            onClick={handleOpen}
          >
            <Icon icon="fluent:people-community-add-28-regular" color={baseColors.GREY} width={20} height={20} />
          </IconButton>
        </Tooltip>
      </Stack>

      {open && (
        <MenuPopover
          zIndex={(theme) => theme?.zIndex?.modal + 5}
          width={350}
          sx={{
            height: { xs: 1, md: 550 },
            bgcolor: 'transparent',
            border: 'none',
            outline: 'none',
            boxShadow: 'none'
          }}
          open={open}
          onClose={handleClose}
          disabledArrow
          anchorEl={anchorRef.current}
          arrow="right-center"
        >
          <SelectCollaborates
            field={`assigne.${field}`}
            currents={details.assigne[field] || []}
            onClose={handleClose}
            onChange={handeChange}
          />
        </MenuPopover>
      )}
    </Stack>
  );
};

export const DateView = ({
  details,
  handleDetailChange,
  title,
  field,
  color = null,
  notTime = false,
  disabled = false
}) => {
  const [hover, onHover, endHover] = useToggleV2();
  const [open, onOpen, _, handleToogle] = useToggleV2();

  const onChange = (value, dateString) => {
    return handleDetailChange('correspondance', { ...details.correspondance, [field]: new Date(dateString) });
  };

  const onRemove = () => {
    handleDetailChange('correspondance', { ...details.correspondance, [field]: null });
  };

  return (
    <Stack
      onMouseEnter={disabled ? undefined : onHover}
      alignItems="center"
      spacing={1}
      onMouseLeave={endHover}
      sx={{ cursor: 'pointer' }}
    >
      {!isEmpty(title) && (
        <LabelStyle width="max-content" fontSize={14}>
          {title}
        </LabelStyle>
      )}
      <Stack direction="row" spacing={1}>
        <Box width={17} onClick={onRemove}>
          {hover && <Icon icon="eva:close-circle-fill" height={17} color="red" />}
        </Box>
        <Stack position="relative">
          <DatePicker
            open={open}
            onOpenChange={handleToogle}
            style={{ position: 'absolute', opacity: 0 }}
            popupStyle={{ zIndex: 1550 }}
            {...(!notTime && { showTime: { format: 'HH:mm' } })}
            format="YYYY-MM-DD HH:mm"
            onChange={onChange}
          />
          <Typography
            fontSize={12}
            {...(color && { color: color })}
            sx={{ ...(hover && { textDecoration: 'underline', textDecorationStyle: 'dashed' }) }}
          >
            {details.correspondance[field] ? (
              <LabelStyle fontSize={12} textAlign="center">
                {format(gDate(details.correspondance[field]), notTime ? 'dd MMM yyyy' : 'dd MMM yy', {
                  locale: frLocale
                })}
              </LabelStyle>
            ) : (
              <Icon icon="healthicons:i-schedule-school-date-time" height={30} color={baseColors.GREY} />
            )}
          </Typography>
          <Box
            onClick={disabled ? undefined : onOpen}
            position="absolute"
            sx={{ cursor: 'pointer' }}
            top={0}
            bottom={0}
            right={0}
            left={0}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export const DateViewRappel = ({ details, handleDetailChange }) => {
  const [rappel, setRappel] = useState(details.correspondance.rappel || { id: nanoid(), date: null, desc: '' });

  const [open, __, _, handleToogle] = useToggleV2();

  const onChange = (value, dateString) => {
    setRappel({ id: rappel.id, date: new Date(dateString) });
    handleDetailChange('correspondance', {
      ...details.correspondance,
      rappel: { id: rappel.id, date: new Date(dateString) }
    });
  };

  const onRemove = () => {
    setRappel({ id: rappel.id, date: null });
  };

  return (
    <Stack width={1} position="relative" sx={{ cursor: 'pointer' }}>
      <Stack>
        <MuiCustomInput
          fullWidth
          size="small"
          placeholder="Ajouter un rappel"
          value={gDate(rappel?.date) ? gDate(rappel?.date).toLocaleString() : ''}
          readOnly
        />
      </Stack>
      <Stack direction="row" width={1} position="absolute" height={1}>
        <Stack width={0.92} justifyContent="end">
          <DatePicker
            open={open}
            onOpenChange={handleToogle}
            style={{ opacity: 0, height: 60 }}
            popupStyle={{ zIndex: 1550 }}
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            onChange={(_, sd) => onChange(_, sd)}
          />
        </Stack>
        <Stack width={17} justifyContent="center" onClick={() => onRemove()}>
          <Icon icon="eva:close-circle-fill" height={20} color="red" />
        </Stack>
      </Stack>
    </Stack>
  );
};

export const OperationType = ({ details, handleDetailChange, user, disabled = false }) => {
  const handleChange = (e) => {
    handleDetailChange('correspondance', { ...details.correspondance, typeOp: e.target.value });
  };

  return (
    <Stack width={1}>
      <LabelStyle fontSize={14}>Type d'opération</LabelStyle>
      <Select
        size="small"
        value={details.correspondance.typeOp}
        onChange={handleChange}
        disabled={disabled}
        input={<MuiCustomInput fullWidth size="small" sx={{ p: 1.1 }} />}
      >
        {AFFECT_OPERATION_TYPE.map((val, idx) => (
          <MenuItem key={idx} value={val}>
            {val}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};

export const OperationFileType = ({
  details,
  handleDetailChange,
  user,
  disabled = false,
  incoming = 'attachments',
  outcoming = 'documents'
}) => {
  return (
    <Stack direction="row" spacing={1} justifyContent="space-between" width={1}>
      <Box height={20} />
      <Stack width={1}>
        <LabelStyle fontSize={14}>Pièces jointes reçues</LabelStyle>
        <AffectAttach
          details={details}
          handleDetailChange={handleDetailChange}
          field={incoming}
          user={user}
          placeholder=" "
        />
      </Stack>
      <Box height={20} />
      <Stack width={1}>
        <LabelStyle fontSize={14}>Pièces jointes envoyées</LabelStyle>
        <AffectAttach
          details={details}
          handleDetailChange={handleDetailChange}
          field={outcoming}
          user={user}
          placeholder=" "
        />
      </Stack>
    </Stack>
  );
};

export const PriorityView = ({ details, handleDetailChange, disabled = false }) => {
  const [openP, onOpenP, onCloseP] = useToggleV2();
  const priorityRef = useRef();
  const [priority, setPriority] = useState(details?.correspondance.priority);

  const handleClose = (val) => {
    onCloseP();
    setPriority(val);
    handleDetailChange('correspondance', { ...details.correspondance, priority: val });
  };

  return (
    <Stack spacing={1}>
      <LabelStyle width="max-content" fontSize={14}>
        Priorité
      </LabelStyle>
      {!isEmpty(priority) ? (
        <Stack width="max-content" justifyContent="center">
          <Tooltip title={priority} arrow>
            <IconButton
              ref={priorityRef}
              disabled={disabled}
              onClick={onOpenP}
              sx={{ border: `1px solid ${priorityColor(priority)}` }}
            >
              <Icon icon="eva:flag-fill" color={priorityColor(priority)} width={15} height={15} />
            </IconButton>
          </Tooltip>
        </Stack>
      ) : (
        <Stack width="max-content" justifyContent="center">
          <Tooltip title="Ajouter une priorité" arrow>
            <IconButton
              disabled={disabled}
              ref={priorityRef}
              onClick={onOpenP}
              sx={{ border: (t) => `1px dashed ${t.palette.divider}` }}
            >
              <Icon icon="eva:flag-outline" width={20} height={20} />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
      {openP && (
        <PriorityFlags
          value={priority}
          open={openP}
          onClose={handleClose}
          onChange={setPriority}
          anchorRef={priorityRef}
        />
      )}
    </Stack>
  );
};
