import { useEffect, useMemo, useState } from 'react';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { AffectationType } from 'src/models/Affectation_m';
import { useSelector } from '../redux/store';

/**
 *
 * @returns {{ loading: Boolean, affectations: Array<AffectationType> }}
 */
export function useAffectationTodo({ id }) {
  const [loading, setLoading] = useState(false);
  const getAffectation = useSelector((state) => state.firestore.ordered[`affectation-Todo-${id}`]);
  const affectations = useMemo(() => getAffectation || [], [getAffectation]);

  useEffect(() => setLoading(!isLoaded(getAffectation)), [getAffectation]);

  useFirestoreConnect(() => [
    {
      collection: 'affectation',
      where: [
        ['toDoIds', 'array-contains', id],
        ['isDelete', '==', false]
      ],
      orderBy: [['updatedAt', 'desc']],
      storeAs: `affectation-Todo-${id}`
    }
  ]);

  return { loading, affectations };
}

/**
 *
 * @returns {{ loading: Boolean, affectations: Array<AffectationType> }}
 */
export function useAffectationToReview({ id }) {
  const [loading, setLoading] = useState(false);
  const getAffectation = useSelector((state) => state.firestore.ordered[`affectation-toReview-${id}`]);
  const affectations = useMemo(() => getAffectation || [], [getAffectation]);

  useEffect(() => setLoading(!isLoaded(getAffectation)), [getAffectation]);

  useFirestoreConnect(() => [
    {
      collection: 'affectation',
      where: [
        ['toReviewIds', 'array-contains', id],
        ['isDelete', '==', false]
      ],
      orderBy: [['updatedAt', 'desc']],
      storeAs: `affectation-toReview-${id}`
    }
  ]);

  return { loading, affectations };
}

/**
 *
 * @returns {{ loading: Boolean, affectations: Array<AffectationType> }}
 */
export function useAffectationToValidate({ id }) {
  const [loading, setLoading] = useState(false);
  const getAffectation = useSelector((state) => state.firestore.ordered[`affectation-toValidate-${id}`]);
  const affectations = useMemo(() => getAffectation || [], [getAffectation]);

  useEffect(() => setLoading(!isLoaded(getAffectation)), [getAffectation]);

  useFirestoreConnect(() => [
    {
      collection: 'affectation',
      where: [
        ['toValidate', 'array-contains', id],
        ['isDelete', '==', false]
      ],
      orderBy: [['updatedAt', 'desc']],
      storeAs: `affectation-toValidate-${id}`
    }
  ]);

  return { loading, affectations };
}

/**
 *
 * @returns {{ loading: Boolean, affectations: Array<AffectationType> }}
 */
export function useAffectationHistory({ id }) {
  const [loading, setLoading] = useState(false);
  const getAffectation = useSelector((state) => state.firestore.ordered[`affectation-hostiry-${id}`]);
  const affectations = useMemo(() => getAffectation || [], [getAffectation]);

  useEffect(() => setLoading(!isLoaded(getAffectation)), [getAffectation]);

  useFirestoreConnect(() => [
    {
      collection: 'affectation',
      where: [
        ['historyIds', 'array-contains', id],
        ['isDelete', '==', false]
      ],
      orderBy: [['updatedAt', 'desc']],
      storeAs: `affectation-hostiry-${id}`
    }
  ]);

  return { loading, affectations };
}

export function useAffectationComment({ id }) {
  const [loading, setLoading] = useState(false);
  const getAffectation = useSelector((state) => state.firestore.ordered[`affectation-comment-${id}`]);
  const comments = useMemo(() => getAffectation || [], [getAffectation]);

  useEffect(() => setLoading(!isLoaded(getAffectation)), [getAffectation]);

  useFirestoreConnect(() => [
    {
      collection: `affectation/${id}/comments`,
      orderBy: [['createdAt', 'desc']],
      storeAs: `affectation-comment-${id}`
    }
  ]);

  return { loading, comments };
}
