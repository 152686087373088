import React, { useMemo } from 'react';
import { useV4ProjectContext } from '..';
import DataGrid from 'src/components/data-grid';
import { Box, Stack, TableCell, TableRow, Typography } from '@mui/material';
import TextMaxLine from 'src/components/TextMaxLine';
import { orderBy } from 'lodash';
import { getFileThumb } from 'src/utils/getFileFormat';
import { fr as LocalFr } from 'date-fns/locale';
import { formatDistanceToNowStrict } from 'date-fns';
import { gDate } from 'src/utils/formatTime';
import CIconButton from 'src/components/CIconButton';
import Iconify from 'src/components/Iconify';
import { useSelector } from 'src/redux/store';
import { useTaskV4Context } from 'src/contexts/TaskContext';
import { downloadFile } from 'src/helpers/downloadFile';
import useToggle from 'src/hooks/useToggle';
import FilesReader from 'src/components/FilesReader';
import VideoPlayerModal from 'src/components/VideoPlayerModal';
import LightboxModal from 'src/components/LightboxModal';

const COL_HEADER = [
  { id: 'name', label: 'Nom du fichier', alignItem: false, click: false },
  { id: 'task', label: 'Tâche', alignItem: false, click: false },
  { id: 'update', label: 'Dernière modification', alignItem: false, click: false },
  { id: 'update_by', label: 'Modifié par', alignItem: false, click: false },
  { id: 'actions', label: 'Actions', alignItem: false, click: false }
];

export default function V4ProjectFileContent() {
  //TODO: add filter
  const { board, loading } = useV4ProjectContext();

  const files = useMemo(() => {
    let allFile = [];
    board.cardsOnArray
      .filter((one) => !one?.isDeleted && one?.attachments?.length)
      .map((card) => {
        const reformAttachment = card?.attachments?.map((att) => ({ ...att, taskId: card?.id, taskName: card?.name }));
        allFile = [...allFile, ...reformAttachment];
      });

    return orderBy(allFile, 'createdAt', 'desc');
  }, [board]);

  return (
    <Stack width={1} px={2}>
      <DataGrid
        columns={COL_HEADER}
        data={files}
        hideEmpty
        noCkeck
        loading={loading}
        filterPropertie="name"
        RowItem={FileItemLine}
      />
    </Stack>
  );
}

const FileItemLine = ({ id, row, selected, tabIndex, handleClick, sx }) => {
  const { users } = useSelector((state) => state.user);
  const { openTaskDetails } = useTaskV4Context();
  const { board } = useV4ProjectContext();
  const { open: loading, handleOpen: handleLoading, handleClose: handleCloseLoading } = useToggle();
  const { open, handleOpen, handleClose } = useToggle();

  const handleDetail = () => {
    const card = board.cards[row?.taskId];
    //console.log(card);
    if (card) {
      openTaskDetails(card);
    }
  };

  const handleDownload = () => {
    handleLoading();
    downloadFile(
      row?.url,
      row?.name,
      () => handleCloseLoading(),
      () => handleCloseLoading()
    );
  };

  const canOpenType = row?.type?.includes('video') || row?.type?.includes('image') || row?.type?.includes('pdf');

  return (
    <TableRow hover key={id} tabIndex={tabIndex} selected={selected} style={{ cursor: 'pointer' }}>
      <TableCell sx={sx} onClick={canOpenType ? handleOpen : handleDownload}>
        <Stack direction="row" spacing={0.5} width={1} textAlign="center">
          <Box height={24} width={24} borderRadius={2}>
            {getFileThumb(row?.url)}
          </Box>
          <TextMaxLine fontSize={13}>{row?.name}</TextMaxLine>
        </Stack>
      </TableCell>

      <TableCell sx={sx} onClick={handleDetail}>
        <TextMaxLine fontSize={13} sx={{ maxWidth: 200, ':hover': { textDecoration: 'underline' } }}>
          {row?.taskName}
        </TextMaxLine>
      </TableCell>

      <TableCell sx={sx}>
        <Typography fontSize={10}>
          {formatDistanceToNowStrict(gDate(row?.createAt) || new Date(), { locale: LocalFr, addSuffix: true })}
        </Typography>
      </TableCell>

      <TableCell sx={sx}>
        <TextMaxLine fontSize={12}>{users.find((one) => one.id === row?.userId)?.displayName || ''}</TextMaxLine>
      </TableCell>

      <TableCell sx={sx}>
        <Stack direction="row" spacing={1} alignItems="center">
          {canOpenType && (
            <CIconButton title="Consulter" size="small" onClick={handleOpen}>
              <Iconify icon="lets-icons:view-light" />
            </CIconButton>
          )}
          <CIconButton onClick={handleDownload} title="Télécharger" size="small">
            <Iconify icon={loading ? 'line-md:uploading-loop' : 'material-symbols-light:download'} />
          </CIconButton>
        </Stack>
      </TableCell>
      {open && row?.type?.includes('image') ? (
        <LightboxModal
          images={[row?.url]}
          photoIndex={0}
          setPhotoIndex={() => { }}
          isOpen={open}
          onClose={handleClose}
        />
      ) : null}

      {open && row?.type?.includes('video') ? (
        <VideoPlayerModal url={row?.url} name={row?.name} type={row?.type} open={open} onClose={handleClose} />
      ) : null}

      {open && row?.type?.includes('pdf') ? (
        <FilesReader open={open} onClose={handleClose} docUrl={row?.url} fileName={row?.name} docType={row?.type} />
      ) : null}
    </TableRow>
  );
};
