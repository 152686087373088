import { Dialog } from '@mui/material';

import useAuth from 'src/hooks/useAuth';

import { getFileFormat } from '../utils/getFileFormat';

import LightboxModal from './LightboxModal';
import WordFileEditor from './WordFileEditor';
import PdfFileEditor from './PdfFileEditor';
import ExcelFileEditor from './ExcelFileEditor';
import VideoPlayerModal from 'src/components/VideoPlayerModal';

export const isFileHasReader = (file) => {
  const supported = ['image', 'word', 'excel', 'pdf', 'video'];

  if (file) {
    const type = getFileFormat(file?.type.replace('/', '.'));
    return supported.includes(type);
  }

  return false;
};
export default function AttachmentReader({ file, open, onClose, onUpdate }) {
  const { user } = useAuth();

  if (!open) return <></>;
  if (!file) return <></>;

  const type = getFileFormat(file?.type.replace('/', '.'));

  const handleUpdate = (metas) => {
    if (onUpdate) {
      onUpdate(metas);
    }
  };

  const handleCloseView = (metas) => {
    onClose();
    if (onUpdate) {
      onUpdate(metas);
    }
  };

  if (type === 'image') {
    return <LightboxModal isOpen images={[file?.url]} photoIndex={0} onClose={onClose} setPhotoIndex={() => {}} />;
  }

  if (type === 'word') {
    return (
      <Modal>
        <WordFileEditor
          pathUrl={file?.url}
          fileName={file?.name}
          type={file?.type}
          email={user?.email}
          fileId={file?.id || ''}
          handleUpdate={handleUpdate}
          onClose={handleCloseView}
        />
      </Modal>
    );
  }

  if (type === 'excel') {
    return (
      <Modal>
        <ExcelFileEditor file={file} email={user?.email} handleUpdate={handleUpdate} onClose={handleCloseView} />
      </Modal>
    );
  }

  if (type === 'pdf') {
    return (
      <Modal>
        <PdfFileEditor file={file} email={user?.email} handleUpdate={handleUpdate} onClose={handleCloseView} />
      </Modal>
    );
  }

  if (type?.includes('video')) {
    return (
      <VideoPlayerModal
        open
        file={file}
        {...file}
        email={user?.email}
        handleUpdate={handleUpdate}
        onClose={handleCloseView}
      />
    );
  }

  return <></>;
}

const Modal = ({ children }) => {
  return (
    <Dialog open fullWidth fullScreen>
      {children}
    </Dialog>
  );
};
