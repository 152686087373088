import { createSlice } from '@reduxjs/toolkit';
import { NOTIFICATION_TYPES } from 'src/constants';
import { auth, firestore } from 'src/contexts/FirebaseContext';
import { getCurrentUserAccess } from 'src/helpers/user';
import { serverTime } from 'src/utils/serverTime';
import {
  affectationAssignNotification,
  courielArchiveNotification,
  courielCoReceiverNotification,
  courielDefaultReceiverNotification,
  courielHistoryNotification,
  courielToDoNotification,
  courielToReviewNotification,
  courielTrashNotification,
  createNotification,
  courielAssignedNotification,
  courielAddAttachmentNotification,
  courielCreationNotification,
  courielDeleteNotification,
  courielUnArchiveNotification,
  courielUnTrashNotification
} from 'src/redux/slices/notifications';
import { changeOnObject, compareArraysOfObjects } from 'src/utils/changeOnObject';
import { Affectation_v4_Type } from 'src/models/Affectation_v4_type';
import { RAPPEL_TYPE } from 'src/constants/rappel';
import { getOnlyDate } from 'src/utils/formatTime';
import { multipleFilesSave } from './document';
import { add, uniq } from 'lodash';
import { AffectationType } from 'src/models/Affectation_m';

const getTargetIds = (affect) => {
  return [
    ...(affect?.defaultReceiver || []),
    ...(affect?.toDoIds || []),
    ...(affect?.toReviewIds || []),
    ...(affect?.archiveIds || []),
    ...affect?.historyIds,
    ...(affect?.toDoIdsTemp || []),
    ...(affect?.toReviewIdsTemp || []),
    ...(affect?.archiveIdsTemp || []),
    ...(affect?.historyIdsTemp || []),
    ...(affect?.toDoIdsArchived || []),
    ...(affect?.toReviewIdsArchived || []),
    ...(affect?.archiveIdsArchived || []),
    ...(affect?.historyIdsArchived || []),
    ...(affect?.toDoIdsTrashed || []),
    ...(affect?.toReviewIdsTrashed || []),
    ...(affect?.archiveIdsTrashed || []),
    ...(affect?.historyIdsTrashed || [])
  ];
};

const initialState = {
  error: false,
  isLoading: false,
  affectation: [],
  loadingSetting: false,
  settings: null
};

const slice = createSlice({
  name: 'affectation_v4',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    settingLoading(state, action) {
      state.loadingSetting = action.payload;
    },

    getSettings(state, action) {
      state.settings = action.payload;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    createdSuccess(state, action) {
      state.isLoading = false;
    }
  }
});

export default slice.reducer;

// Keys used on firestore connector with the property [saveAs]
const courielkeys = {
  todo: 'couriel_todo',
  reviews: 'couriel_reviews'
};

const courielCollection = firestore.collection('affectation');

export function getAffectationSetting(settings, loading) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.settingLoading(loading));
      dispatch(slice.actions.getSettings(settings));
    } catch (error) {
      //console.log({ error });
    }
  };
}

export function insertAffectationV4Settings({ data, onResolve, onReject }) {
  return async () => {
    try {
      await courielCollection.doc('affectation_settings').set({ ...data }, { merge: true });
      if (onResolve) onResolve();
    } catch (e) {
      //console.log(e);
      if (onReject) onReject();
    }
  };
}

export function generateRef(setval) {
  return async (dispatch) => {
    try {
      const numberDoc = await firestore.collection('references').doc('affectation_v4').get();
      if (numberDoc.exists) {
        const val = numberDoc.data()?.number;
        setval(val);
        numberDoc.ref.update({ number: val + 1 });
        return;
      }
      setval(1);
      numberDoc.ref.set({ number: 2 });
    } catch (error) {
      console.error(error);
    }
  };
}

export const getCourielById = (id, resolve, reject) => {
  return async (dispatch, getStore) => {
    try {
      //console.log('id', id);

      const snap = await courielCollection.doc(id).get();

      if (snap.exists) {
        resolve({ ...snap.data(), id: snap.id });
      } else {
        reject();
      }
    } catch (error) {
      if (reject) reject();
    }
  };
};

export const save_referenceAlreadyExist = ({ save_reference, resolve, reject }) => {
  return async (dispatch, getStore) => {
    try {
      const snap = await courielCollection.where('save_reference', '==', save_reference).get();
      console.log(snap.empty);
      resolve(!Boolean(snap.empty));
    } catch (error) {
      console.error(error);
      reject();
    }
  };
};

/**
 * @param {{
 *  affectation: Affectation_v4_Type,
 * callback?:() => void
 * onError?:() =>
 * }}
 */
export function createCouriel({ affectation, callback = null, onError = null }) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      //console.log(affectation);

      const addRef = await courielCollection.add(affectation);

      dispatch(slice.actions.createdSuccess());

      dispatch(
        courielCreationNotification({
          couriel: { ...affectation, id: addRef.id },
          targetIds: [...affectation?.toDoIds, ...affectation?.toReviewIds]
        })
      );

      if (callback) callback(addRef.id);
    } catch (error) {
      console.error(error);
      if (onError) onError();
      dispatch(slice.actions.hasError(error));
    }
  };
}

/**
 *
 * @param {{
 *  newAffectation: Affectation_v4_Type,
 *  oldAffectation: Affectation_v4_Type,
 *  callback: ()=> void,
 *  onError: () => void
 * }} props
 * @returns
 */

export function updateAffectation({ newAffectation, oldAffectation, callback, onError }) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    try {
      const { id, ...rest } = newAffectation;

      //console.log(newAffectation, oldAffectation);
      const newFiles = [...newAffectation?.attachments?.send, ...newAffectation?.attachments?.received];
      const oldFiles = [...oldAffectation?.attachments?.send, ...oldAffectation?.attachments?.received];
      const onlyNewFiles = newFiles.filter((f) => !oldFiles.find((o) => o?.name === f?.name));
      let newAttachments = rest?.attachments;

      //console.log(onlyNewFiles);
      if (onlyNewFiles.length > 0) {
        let filesPath = [];
        const filesPathRoot = `affectation/${id}/attachments/`;
        await multipleFilesSave(
          onlyNewFiles,
          (file) => console.log(file),
          (data) => (filesPath = data),
          null,
          filesPathRoot
        );

        //console.log(filesPath);
        const newSendAttachments = newAffectation?.attachments?.send?.map((file) => {
          const found = filesPath.find((f) => f?.name === file?.name);
          //console.log(found);
          if (!found) return file;
          return {
            ...file,
            url: found?.url,
            id: found?.id,
            name: found?.name,
            type: found?.type,
            size: found?.size
          };
        });

        const newReceivedAttachments = newAffectation?.attachments?.received?.map((file) => {
          //console.log(file, filesPath);
          const found = filesPath.find((f) => f?.name === file?.name);
          //console.log(found);
          if (!found) return file;
          return {
            ...file,
            url: found?.url,
            id: found?.id,
            name: found?.name,
            type: found?.type,
            size: found?.size
          };
        });

        newAttachments = {
          ...rest?.attachments,
          send: uniq([...newSendAttachments, ...oldAffectation?.attachments?.send]),
          received: uniq([...newReceivedAttachments, ...oldAffectation?.attachments?.received])
        };
      }

      //console.log(newAttachments);

      const newRest = {
        ...rest,
        attachments: newAttachments
      };

      //console.log(newRest);

      const docRef = courielCollection.doc(id);

      await docRef.set({ ...newRest }, { merge: true });

      // get difference between old and new affectation

      callback && callback();

      //TODO add affectations attachement to save in firebase storage and get the path

      if (newAffectation?.toReviewIds?.length !== oldAffectation?.toReviewIds?.length) {
        const { added, modified, removed } = compareArraysOfObjects(oldAffectation?.toDoIds, newAffectation?.toDoIds);
        //console.log(added,modified,removed);
        if (modified.length > 0) {
          dispatch(courielAssignedNotification({ couriel: newAffectation, targetIds: [...modified] }));
        }
      }
      if (onlyNewFiles.length > 0) {
        dispatch(
          courielAddAttachmentNotification({
            couriel: newAffectation,
            targetIds: [...newAffectation?.toDoIds, ...newAffectation?.toReviewIds]
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
}

//coReceiver defaultReceiver assignationOrganigramme
export function initiateRappel({ newAffectation, oldAffectation }) {
  return async (dispatch, getState) => {
    try {
      const rappelCollection = firestore.collection('rappel');

      if (!newAffectation?.rappel?.date && !oldAffectation?.rappel?.date) return;

      const result = await rappelCollection
        .where('type', '==', RAPPEL_TYPE.AFFECTATION)
        .where('docId', '==', newAffectation?.id)
        .limit(1)
        .get();

      const rappel = result.docs.length != 0 ? result.docs.at(0) : null;

      if ((!newAffectation?.rappel?.date && oldAffectation?.rappel?.date && rappel) || newAffectation?.completed) {
        await rappelCollection.doc(rappel?.id).delete();

        return;
      }

      const runAt = getOnlyDate(newAffectation?.rappel?.date);
      const userIds = [...newAffectation?.toDoIds, ...newAffectation?.toReviewIds];

      const allTokens = getState().notifications.tokens;

      const tokensWithoutSender = allTokens.filter((one) => one.id !== auth.currentUser.uid);

      const canReceivNotification = [...userIds].map((id) => {
        return [...tokensWithoutSender].find((tk) => tk.id === id);
      });

      const web = [];
      const mobile = [];

      canReceivNotification.forEach((us) => {
        if (us?.token) {
          web.push(us.token);
        }
        if (us?.mobileToken) {
          mobile.push(us?.mobileToken);
        }
      });

      const fcm = {
        web,
        mobile
      };

      await rappelCollection.doc(rappel?.id).set(
        {
          type: RAPPEL_TYPE.AFFECTATION,
          docId: newAffectation?.id,
          runAt,
          nextRunAt: null,
          updatedAt: new Date(),
          userIds,
          fcm,
          metadata: {
            object: newAffectation?.courriel_object,
            ref: newAffectation?.courriel_reference
          }
        },
        { merge: true }
      );
    } catch (error) {
      //console.error(error)
    }
  };
}

/**
 *
 * @param {{
 * affect: Array<Affectation_v4_Type>,
 * callback: () => void
 * }} param0
 */

export function archiveCouriel({ affect, callback }) {
  return async (dispatch) => {
    try {
      const batch = firestore.batch();
      //console.log({affect});
      affect.forEach((aff) => {
        //console.log(aff);
        const affRef = courielCollection.doc(aff?.id);
        batch.update(affRef, {
          ...aff,
          isArchived: true,
          archiveIds: [
            auth.currentUser.uid,
            ...(aff?.archiveIds || []),
            ...(aff?.toDoIds || []),
            ...(aff?.toReviewIds || []),
            ...aff?.historyIds
          ],
          toDoIdsTemp: [...aff?.toDoIds, ...aff?.toDoIdsTemp],
          trashedIdsTemp: [...(aff?.trashedIds || [])],
          toReviewIdsTemp: [...(aff?.toReviewIds || [])],
          historyIdsTemp: [...(aff?.historyIds || [])],
          trashedIds: [],
          toDoIds: [],
          historyIds: [],
          toReviewIds: [],
          toDoIdsArchived: [...(aff?.toDoIdsTemp || [])],
          toReviewIdsArchived: [...(aff?.toReviewIdsTemp || [])],
          historyIdsArchived: [...(aff?.historyIdsTemp || [])],
          trashedIdsArchived: [...(aff?.trashedIdsTemp || [])]
        });

        dispatch(
          courielArchiveNotification({
            couriel: aff,
            targetIds: getTargetIds(aff)
          })
        );
      });

      await batch.commit();

      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
}

/**
 *
 * @param {{
 * affect: Array<Affectation_v4_Type>,
 * callback: () => void
 * }} param0
 * @returns
 */
export function unArchiveCouriel({ affect, callback }) {
  return async (dispatch) => {
    try {
      //console.log({affect});
      const batch = firestore.batch();

      affect.forEach((aff) => {
        const affRef = courielCollection.doc(aff?.id);
        batch.update(affRef, {
          ...aff,
          isArchived: false,
          archiveIds: [],
          toDoIds: [...(aff?.toDoIdsTemp || [])],
          toReviewIds: [...(aff?.toReviewIdsTemp || [])],
          historyIds: [...aff?.historyIdsTemp] || [],
          trashedIds: [...(aff?.trashedIdsTemp || [])],
          toDoIdsTemp: [...(aff?.toDoIdsArchived || [])],
          toReviewIdsTemp: [...(aff?.toReviewIdsArchived || [])],
          historyIdsTemp: [...(aff?.historyIdsArchived || [])],
          trashedIdsTemp: [...(aff?.trashedIdsArchived || [])],
          toDoIdsArchived: [],
          toReviewIdsArchived: [],
          historyIdsArchived: [],
          trashedIdsArchived: []
        });

        dispatch(
          courielUnArchiveNotification({
            couriel: aff,
            targetIds: getTargetIds(aff)
          })
        );
      });

      await batch.commit();
      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
}

/**
 *
 * @param {{
 * affect: Array<Affectation_v4_Type>,
 * callback: () => void
 * }} param0
 * @returns
 */

export function deleteCouriel({ affect, callback }) {
  return async (dispatch) => {
    try {
      const batch = firestore.batch();
      //console.log({affect});
      // await courielCollection.doc(affect.id).set({ isDelete: true }, { merge: true });
      affect.forEach((aff) => {
        const affRef = courielCollection.doc(aff?.id);
        batch.update(affRef, {
          ...aff,
          isDelete: true
        });
        dispatch(
          courielDeleteNotification({
            couriel: aff,
            targetIds: getTargetIds(aff)
          })
        );
      });

      await batch.commit();

      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
}

export function restoreCouriel({ affect, callback }) {
  return async (dispatch) => {
    try {
      const batch = firestore.batch();
      //console.log({affect});
      // await courielCollection.doc(affect.id).set({ isDelete: true }, { merge: true });
      affect.forEach((aff) => {
        const affRef = courielCollection.doc(aff?.id);
        batch.update(affRef, {
          ...aff,
          isDelete: false
        });
      });

      await batch.commit();

      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
}

export function addCourielToHistory({ affect, callback }) {
  return async (dispatch) => {
    try {
      //console.log({affect});
      await courielCollection.doc(affect.id).set(
        {
          ...affect,
          validation: {
            ...affect?.validation,
            state: true,
            by: auth.currentUser.uid
          },
          historyIds: [
            auth.currentUser.uid,
            ...(affect?.historyIds || []),
            ...(affect?.toDoIds || []),
            ...(affect?.toReviewIds || []),
            ...(affect?.toDoIdsTemp || []),
            ...(affect?.toReviewIdsTemp || [])
          ],
          toDoIds: [],
          trashedIds: [],
          toReviewIds: [],
          archiveIds: [],
          toDoIdsTemp: [],
          toReviewIdsTemp: [],
          historyIdsTemp: [],
          archiveIdsTemp: []
        },
        { merge: true }
      );

      callback && callback();
      dispatch(
        courielHistoryNotification({
          couriel: affect,
          targetIds: getTargetIds(affect)
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
}

export function addCourielToTrash({ affect, callback }) {
  return async (dispatch) => {
    try {
      const batch = firestore.batch();
      //console.log({affect});
      // await courielCollection.doc(affect.id).set({ isDelete: true }, { merge: true });
      affect.forEach((aff) => {
        const affRef = courielCollection.doc(aff?.id);
        batch.update(affRef, {
          ...aff,
          //isDelete: true,
          trashedIds: [
            auth.currentUser.uid,
            ...(aff?.trashedIds || []),
            ...(aff?.toDoIds || []),
            ...(aff?.toReviewIds || []),
            ...(aff?.toDoIdsTemp || []),
            ...(aff?.toReviewIdsTemp || []),
            ...(aff?.historyIds || []),
            ...(aff?.archiveIds || [])
          ],
          toDoIds: [],
          historyIds: [],
          toReviewIds: [],
          archiveIds: [],
          toDoIdsTemp: [...(aff?.toDoIds || [])],
          toReviewIdsTemp: [...(aff?.toReviewIds || [])],
          historyIdsTemp: [...(aff?.historyIds || [])],
          archiveIdsTemp: [...(aff?.archiveIds || [])],
          toDoIdsTrashed: [...(aff?.toDoIdsTemp || [])],
          toReviewIdsTrashed: [...(aff?.toReviewIdsTemp || [])],
          historyIdsTrashed: [...(aff?.historyIdsTemp || [])],
          archiveIdsTrashed: [...(aff?.archiveIdsTemp || [])]
        });
        dispatch(
          courielTrashNotification({
            couriel: aff,
            targetIds: getTargetIds(aff)
          })
        );
      });

      await batch.commit();

      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
}

export function restoreCourielFromTrash({ affect, callback }) {
  return async (dispatch) => {
    try {
      const batch = firestore.batch();
      //console.log({affect});
      // await courielCollection.doc(affect.id).set({ isDelete: true }, { merge: true });
      affect.forEach((aff) => {
        const affRef = courielCollection.doc(aff?.id);
        batch.update(affRef, {
          ...aff,
          //isDelete: true,
          trashedIds: [],
          toDoIds: [...(aff?.toDoIdsTemp || [])],
          toReviewIds: [...(aff?.toReviewIdsTemp || [])],
          historyIds: [...(aff?.historyIdsTemp || [])],
          archiveIds: [...(aff?.archiveIdsTemp || [])],
          toDoIdsTemp: [...(aff?.toDoIdsTrashed || [])],
          toReviewIdsTemp: [...(aff?.toReviewIdsTrashed || [])],
          historyIdsTemp: [...(aff?.historyIdsTrashed || [])],
          archiveIdsTemp: [...(aff?.archiveIdsTrashed || [])],
          toDoIdsTrashed: [],
          toReviewIdsTrashed: [],
          historyIdsTrashed: [],
          archiveIdsTrashed: []
        });
        dispatch(
          courielUnTrashNotification({
            couriel: aff,
            targetIds: getTargetIds(aff)
          })
        );
      });

      await batch.commit();

      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
}

export function addCourielComment({ commentObject, generateId, affect, callback }) {
  return async (dispatch) => {
    try {
      await courielCollection.doc(affect.id).collection('comments').doc(generateId).set(commentObject);

      callback && callback();

      // dispatch(commentNotif({ affect: affect}));
    } catch (error) {
      console.error(error);
    }
  };
}

export function removeCourielComment({ id, callback }) {
  return async (dispatch) => {
    try {
      await courielCollection.doc(id).collection('comments').doc(id).delete();
      callback && callback();
    } catch (error) {
      console.error(error);
    }
  };
}

export function courielAssignNotification({ couriel, targetIds = [], callback }) {
  return async (dispatch, getState) => {
    try {
      if (targetIds?.length === 0) return;

      const settings = getState()?.affectation_v4?.settings;
      const isMaiReceiver = settings?.defaultRecevierID?.includes(auth?.currentUser?.uid);

      const description = `Objet: ${couriel?.courriel_object}`;
      const descriptionHtml = `Objet: <strong>${couriel?.courriel_object}</strong>`;

      const baseNotData = {
        description,
        createdAt: serverTime(),
        type: NOTIFICATION_TYPES.COURIEL_ASSIGN,
        returnId: couriel?.id,
        canAccess: [...targetIds],
        isUnRead: transformIdToUnreadFormat([...targetIds]),
        by: {
          id: auth.currentUser.uid,
          displayName: auth.currentUser.displayName || '',
          photoURL: auth.currentUser.photoURL || ''
        },
        action: {
          id: couriel?.id,
          object: couriel?.courriel_object,
          courriel_reference: couriel?.courriel_reference,
          save_reference: couriel?.save_reference,
          operation_type: couriel?.operation_type
        },
        html: {
          description: descriptionHtml
        }
      };

      dispatch(
        createNotification({
          data: {
            title: `Vous avez été assigné sur l'affectation ${couriel?.save_reference} `,
            ...baseNotData,
            html: {
              ...baseNotData?.html,
              title: `Vous avez été assigné sur l'affectation <strong>${couriel?.save_reference}</strong>`
            }
          }
        })
      );

      if (callback) callback(couriel?.id);
    } catch (error) {
      console.error(error);
    }
  };
}

export function courielValidateNotification({ couriel, targetIds = [], callback }) {
  return async (dispatch, getState) => {
    try {
      if (targetIds?.length === 0) return;

      const settings = getState()?.affectation_v4?.settings;
      const isMaiReceiver = settings?.defaultRecevierID?.includes(auth?.currentUser?.uid);

      const description = `Objet: ${couriel?.courriel_object}`;
      const descriptionHtml = `Objet: <strong>${couriel?.courriel_object}</strong>`;

      const baseNotData = {
        description,
        createdAt: serverTime(),
        type: NOTIFICATION_TYPES.COURIEL_VALIDATE,
        returnId: couriel?.id,
        canAccess: [...targetIds],
        isUnRead: transformIdToUnreadFormat([...targetIds]),
        by: {
          id: auth.currentUser.uid,
          displayName: auth.currentUser.displayName || '',
          photoURL: auth.currentUser.photoURL || ''
        },
        action: {
          id: couriel?.id,
          object: couriel?.courriel_object,
          courriel_reference: couriel?.courriel_reference,
          save_reference: couriel?.save_reference,
          operation_type: couriel?.operation_type
        },
        html: {
          description: descriptionHtml
        }
      };

      dispatch(
        createNotification({
          data: {
            title: `Affectation validée `,
            ...baseNotData,
            html: {
              ...baseNotData?.html,
              title: `L'affectation <strong>${couriel?.save_reference}</strong> a été validée par <strong>${baseNotData?.by?.displayName}</strong> `
            }
          }
        })
      );

      if (callback) callback(couriel?.id);
    } catch (error) {
      console.error(error);
    }
  };
}

/**
 *
 * @param {string} id
 * @param {()=>AffectationType} callback
 *
 * @returns
 */
export function getAffectationById(id, callback) {
  return async (dispatch) => {
    try {
      const snap = await courielCollection.doc(id).get();
      if (snap.exists) {
        callback({ ...snap.data(), id: snap.id });
      }
    } catch (error) {
      console.error(error);
    }
  };
}

const transformIdToUnreadFormat = (list = []) => {
  let listIds = {};
  list.forEach((id) => (listIds[id] = true));
  return listIds;
};
