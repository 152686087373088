import { filter, indexOf } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import firestore from '../../utils/firestore';
import { audienceNotification, audienceUpdateNotification } from './notifications';
import { APPOINTMENT_TYPE } from '../../section/_dashboard/acceuil/audience/Audience';
import axiosRequest from './../../utils/axiosRequest';
import { AUDIENCE_BASE_TYPE } from 'src/constants';
import { getCurrentUserAccess, transformUserToAccess } from 'src/helpers/user';
import { gDate, getRappelLikeDate, gfDateTime } from 'src/utils/formatTime';
import { PATH_DASHBOARD } from 'src/routes/paths';

import axios from 'axios';
import { MAIL_CONFIG, cloudAudience, mail } from 'src/config';
import { emitMail } from 'src/redux/slices/mail';

const initialState = {
  isLoading: false,
  error: false,
  audiences: [],
  notifications: null
};

const slice = createSlice({
  name: 'audience',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    //AUDIENCE ADD AND UPDATE SUCCESS
    addSuccess(state, action) {
      state.isLoading = false;
      state.audiences.push(action.payload);
    },

    getAudienceList(state, action) {
      state.isLoading = false;
      state.audiences = action.payload;
    },

    updateSuccess(state, action) {
      state.isLoading = false;
      const toUpdate = indexOf(state.audiences, (audience) => audience.id === action.payload);
      let newAudience = [...state.audiences];
      newAudience.splice(toUpdate, 1, action.payload);
      state.audiences = newAudience;
    },

    //GET AUDIENCE SUCCESS
    getAudienceSuccess(state, action) {
      state.isLoading = false;
      state.audiences = action.payload;
    },

    deleteAudienceSuccess(state, action) {
      state.loading = false;
      state.audiences = filter(state.audiences, (audience) => audience.id !== action.payload);
    }
  }
});

export default slice.reducer;

export function getAudienceList(audiences) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      dispatch(slice.actions.getAudienceList(audiences));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createAudience(audience, callback = null) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());

      const { id, numFile, ...rest } = audience;
      const doc = await firestore.collection('audience').doc(id).set(rest, { merge: true });

      dispatch(slice.actions.addSuccess({ id, ...audience }));

      if (callback) callback(id);

      // dispatch(audienceCreationNotification({data: audience}));

      dispatch(
        audienceNotification({
          audience,
          title: "Nouvelle demande d'audience",
          description: `Vous avez reçu une nouvelle demande d'audience pour ${audience?.lastName || ''} ${
            audience?.firstName || ''
          }`
        })
      );

      if (audience?.accessLink) {
        const header = `Vous avez été invité à une audience`;
        const description = `Vous avez été invité à une audience avec ${audience?.personToMeet
          ?.map((el) => transformUserToAccess(el))
          ?.map((el) => el?.name)}`;

        const request = {
          data: {
            header,
            description,
            link: audience?.accessLink,
            name: transformUserToAccess(audience)?.name || audience?.email
          },
          email: audience?.email
        };

        //console.log('invitation', request);

        dispatch(emitMail({ ...request }));
      }

      if (audience?.audienceType === APPOINTMENT_TYPE.MakingAppointment) {
        const from = MAIL_CONFIG.from;
        const templateId = MAIL_CONFIG.template.audience;

        const persons = audience?.personToMeet.map((pers) => `Nom: ${pers?.displayName} Poste: ${pers?.poste}`);
        const data = {
          numFile: audience.numFile,
          auteur: audience?.displayName,
          createdAt: audience?.createAt?.toLocaleDateString(),
          guest_lastName: audience?.lastName,
          guest_firstName: audience?.firstName,
          guest_phone: audience?.tel,
          guest_email: audience?.email,
          society: audience?.society,
          societyAdress: audience?.societyAdress,
          societyContact: audience?.societyContact,
          societyActivity: audience?.societyActivity,
          info: audience?.info,
          personnes: persons,
          suite: '',
          delay: 'Maximum de 15 jours',
          back: '',
          back_tel: '',
          back_email: ''
        };

        await axiosRequest.post('/mail/template', { to: audience.email, from, templateId: templateId, data });
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateAudience(audience, callback = null, old) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());

      const { id, numFile, ...rest } = audience;
      await firestore
        .collection('audience')
        .doc(id)
        .set({ ...rest }, { merge: true });

      dispatch(slice.actions.updateSuccess({ id: id, ...audience }));

      if (callback) callback(id);

      // dispatch(audienceUpdateNotification({data: audience}));

      const buffer = { ...old, ...audience };

      const target = transformUserToAccess(buffer);

      if (buffer?.appointmentDate && buffer?.state === AUDIENCE_BASE_TYPE.FINISHED) {
        const event = {
          title: `Audience avec ${target?.name}`,
          start: getRappelLikeDate(buffer?.appointmentDate),
          end: getRappelLikeDate(buffer?.appointmentDate),
          textColor: '#1890FF',
          by: getCurrentUserAccess(),
          eventRappel: {
            label: 'Une seule fois',
            value: 0
          },
          updatedAt: new Date(),
          userId: buffer?.personToMeet[0]?.id || null,
          shared: buffer?.personToMeet?.map((el) => el?.id)
        };

        await firestore.collection('calendar').doc(buffer?.id).set(event, { merge: true });
        //console.log('event', event);
      }
      if (buffer?.state !== AUDIENCE_BASE_TYPE.FINISHED) {
        await firestore.collection('calendar').doc(buffer?.id).delete();
        //console.log('remove event', buffer?.id);
      }

      const emit = async (user, header, description) => {
        const request = {
          data: {
            header,
            description,
            name: transformUserToAccess(user)?.name || user?.email
          },
          email: user?.email
        };

        console.group('Audience update: Emit emails ');
        //console.log('old', old);
        //console.log('audience', audience);
        //console.log('request', request);
        console.groupEnd();

        dispatch(emitMail({ ...request }));
      };

      if (
        old &&
        old?.state !== audience?.state &&
        audience?.state === AUDIENCE_BASE_TYPE.FINISHED &&
        audience?.appointmentDate
      ) {
        const header = `Votre audience a été acceptée`;
        const description = `Votre audience avec ${audience?.personToMeet
          ?.map((el) => transformUserToAccess(el))
          ?.map((el) => el?.name)} est planifiée pour le ${gfDateTime(audience?.appointmentDate)}`;

        await emit(audience, header, description);

        dispatch(
          audienceNotification({
            audience,
            title: 'Une audience a été acceptée',
            description: `L'audience avec ${audience?.lastName || ''} ${
              audience?.firstName || ''
            } a été acceptée. Elle est planifiée pour le ${gfDateTime(audience?.appointmentDate)}`
          })
        );
      }

      if (
        old &&
        old?.state === audience?.state &&
        audience?.state === AUDIENCE_BASE_TYPE.FINISHED &&
        gDate(old?.appointmentDate) !== gDate(audience?.appointmentDate)
      ) {
        const header = `L'horaire de votre audience a été changé`;
        const description = `Votre audience avec ${audience?.personToMeet
          ?.map((el) => transformUserToAccess(el))
          ?.map((el) => el?.name)} est planifiée pour le ${gfDateTime(audience?.appointmentDate)}`;

        await emit(audience, header, description);

        dispatch(
          audienceNotification({
            audience,
            title: "L'horraire d'une audience a été changé",
            description: `L'audience avec ${audience?.lastName || ''} ${
              audience?.firstName || ''
            }  a été reprogramé pour le ${gfDateTime(audience?.appointmentDate)}`
          })
        );
      }

      if (old && old?.state !== audience?.state && audience?.state === AUDIENCE_BASE_TYPE.REJECTED) {
        const header = ` Votre audience a été rejetée`;
        const description = `Votre audience avec ${audience?.personToMeet
          ?.map((el) => transformUserToAccess(el))
          ?.map((el) => el?.name)} a été rejetée. La raison etant ${audience?.comment || ''}`;

        await emit(audience, header, description);

        dispatch(
          audienceNotification({
            audience,
            title: 'Une audience a été rejetée',
            description: `L'audience avec ${audience?.lastName || ''} ${audience?.firstName || ''} a été rejetée`
          })
        );
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function respToAudience({ audience, old, callback = null }) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());

      const { id, numFile, ...rest } = audience;

      await firestore
        .collection('audience')
        .doc(id)
        .set({ ...rest }, { merge: true });

      dispatch(slice.actions.updateSuccess({ id: id, ...audience }));

      if (callback) callback(id);

      dispatch(audienceUpdateNotification({ data: audience }));

      if (old && audience?.appointmentDate) {
        const from = MAIL_CONFIG.from;
        const subject = MAIL_CONFIG.from;
        const domaine = window.location.host;

        const person = transformUserToAccess(getCurrentUserAccess());

        const emit = async (user) => {
          const invited = transformUserToAccess(audience);

          const title = `Réponse à l'invitation d'audience`;
          const description = `${invited?.name || invited?.lastName || invited?.email} propose la date du ${gfDateTime(
            audience?.appointmentDate
          )} pour l'audience`;
          const accessLink = `https://${domaine}${PATH_DASHBOARD.general.accueil.audience}/?id=${audience?.id}`;

          const data = {
            description: description,
            name: user?.name || user?.displayName || user?.lastName,
            salutation: '',
            link: accessLink,
            subject: subject,
            header: title
          };

          //console.log('old', old);
          //console.log('audience', audience);
          //console.log('data', data);

          await axiosRequest.post('/mail/template', {
            to: user?.email,
            from,
            templateId: MAIL_CONFIG.template.audience,
            data
          });
        };

        const people = audience?.personToMeet?.map((el) => transformUserToAccess(el));

        for (const person of people) {
          await emit(person);
        }
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteAudience(id, callback = null) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      await firestore.collection('audience').doc(id).delete();
      dispatch(slice.actions.deleteAudienceSuccess(id));

      if (callback) return callback();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAudience(id, resolve, reject) {
  return async () => {
    try {
      const snap = await axios.post(cloudAudience.get, { id });
      resolve(snap.data);
    } catch (e) {
      reject(e);
    }
  };
}

export function setAudience(audience, old, resolve, reject) {
  return async () => {
    try {
      const snap = await axios.post(cloudAudience.set, { audience, old });
      resolve(snap.data);

      if (old && audience?.appointmentDate) {
        //console.log('audience?.appointmentDate', audience?.appointmentDate);

        const from = MAIL_CONFIG.from;
        const subject = MAIL_CONFIG.from;
        const domaine = window.location.host;

        const emit = async (user) => {
          const invited = transformUserToAccess(audience);

          const title = `Réponse à l'invitation d'audience`;
          const description = `${invited?.name || invited?.lastName || invited?.email} propose la date du ${gfDateTime(
            audience?.appointmentDate
          )} pour l'audience`;
          const accessLink = `https://${domaine}${PATH_DASHBOARD.general.accueil.audience}/?id=${audience?.id}`;

          const data = {
            description: description,
            name: user?.name || user?.displayName || user?.lastName,
            salutation: '',
            link: accessLink,
            subject: subject,
            header: title
          };

          //console.log('old', old);
          //console.log('audience', audience);
          //console.log('data', data);

          await axiosRequest.post('/mail/template', {
            to: user?.email,
            from,
            templateId: MAIL_CONFIG.template.audience,
            data
          });
        };

        const people = audience?.personToMeet?.map((el) => transformUserToAccess(el));

        for (const person of people) {
          await emit(person);
        }
      }
    } catch (e) {
      reject(e);
    }
  };
}

export const getAudienceById = (id, resolve, reject) => {
  return async (dispatch, getStore) => {
    try {
      //console.log('id', id);
      // const user = getCurrentUserAccess();
      //
      //
      // const reviews = getStore().firestore.ordered[`affectation-toReview-${user?.id}`]
      // const todos = getStore().firestore.ordered[`affectation-Todo-${user?.id}`]
      // const list = [...todos, ...reviews]
      // const find = list?.find(el => el?.id === id);
      //
      // if (find) {
      //     resolve(find);
      //     return;
      // }

      const snap = await firestore.collection('audience').doc(id).get();

      if (snap.exists) {
        resolve({ ...snap.data(), id: snap.id });
      } else {
        reject();
      }
    } catch (error) {
      reject();
    }
  };
};
