import { nanoid } from '@reduxjs/toolkit';
import { TASK_STATE_VALIDATION, TASK_VISIBILITY } from 'src/constants';

/**
 * @typedef {{
 * id: string,
 * name: string,
 * completed: boolean,
 * attachments: Array,
 * comments: Array,
 * description: string,
 * due: Array,
 * isDefaultTask: boolean,
 * observers: Array,
 * reponsable: any,
 * progess: number,
 * rappel: any,
 * isDeleted: boolean,
 * rappels: Array,
 * deadLine: any,
 * mamangers: Array,
 * assignee: Array,
 * assigneIds: Array,
 * assigneByIds: Array,
 * priority: string,
 * cardIds: Array,
 * visibility: string,
 * projectState: string,
 * projectKey: string | null,
 * projectName: string | null,
 * state: string,
 * editor_mentions; Array,
 * name_mentions: Array,
 * desc_mentions: Array
 * }}
 */
export let TaskType = {
  name: '',
  completed: false,
  attachments: [],
  comments: [],
  description: '',
  due: [null, null],
  isDefaultTask: false,
  observers: [],
  progess: 0,
  rappel: null,
  isDeleted: false,
  rappels: [],
  deadLine: null,
  mamangers: [],
  assignee: [],
  assigneIds: [],
  assigneByIds: [],
  priority: '',
  cardIds: [],
  visibility: TASK_VISIBILITY.PUBLIC,
  projectState: 'open',
  projectKey: null,
  state: TASK_STATE_VALIDATION.PENDING,
  projectName: '',
  editor_mentions: [],
  name_mentions: [],
  desc_mentions: []
};
