import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToggleV2 } from 'src/hooks/useToggle';
import {
  addFileAction,
  addFolderAction,
  copyElementAction,
  createTagAction,
  editTagAction,
  makeElementAsPrivateOrPublic,
  moveElementAction,
  restoreFromTrashElementAction,
  shareElementAction,
  toTrashElementAction,
  updateElementFavorisAction,
  updateElementNameAction,
  updateElementTagsAction,
  wipeTrashElementsAction,
  updateElementDescriptionAction
} from 'src/redux/slices/driver/driver';
import { dispatch } from 'src/redux/store';
import { multipleFilesSave } from 'src/redux/slices/document';
import UploadingFileView from '../annexes/UploadingFileView';
import { IOkyDriverFile, IOkyDriverFolder } from 'src/models/IOkyDriver';
import { V4_ONLY_MY_TASK_ORDER } from 'src/constants/task';
import {
  V4_DOC_DRIVER_SORT,
  V4_DOC_DRIVER_SORT_ORDER,
  V4_DOC_DRIVER_FILTER_TYPE,
  V4_DOC_DRIVER_SORT_CONVERT,
  V4_DOC_DRIVER_SORT_ORDER_CONVERT
} from 'src/constants/docDriverConstant';

const DocDriverProvider = createContext({
  items: [],
  rename: null,
  details: null,
  /** @type {Array<IOkyDriverFile | IOkyDriverFolder>} */
  selection: [],
  topLevel: null,
  docPath: '/doc',
  currentTag: null,
  setItems: () => {},
  openNewFolder: false,
  menuContextuel: null,
  currentFolderId: null,
  defaultFolderId: null,
  setTopLevel: () => {},
  setSort: (type) => {},
  setRename: (item) => {},
  handleToggleAll: () => {},
  OnOpenNewFolder: () => {},
  onCloseNewFolder: () => {},
  setCurrentTag: (item) => {},
  handleSelection: (item) => {},
  handleCloseSelections: () => {},
  handleShowDetails: (item) => {},
  sortType: V4_DOC_DRIVER_SORT.NAME,
  setMenuContextuel: (anchor) => {},
  handleOpenFolder: (folderId) => {},
  handleFilterTypeChange: (type) => {},
  setCurrentFolderId: (folderId) => {},
  setDefaultFolderId: (folderId) => {},
  sortOrder: V4_DOC_DRIVER_SORT_ORDER.ASC,
  filterType: V4_DOC_DRIVER_FILTER_TYPE.ALL,
  addTag: ({ tag, onSuccess, onError }) => {},
  addFile: ({ files, onSuccess, onError }) => {},
  addToTrash: ({ elements, onSuccess, onError }) => {},
  wipeFromTrash: ({ elements, onSuccess, onError }) => {},
  restoreFromTrash: ({ elements, onSuccess, onError }) => {},
  addFolder: ({ name, parentId = null, onSuccess, onError }) => {},
  copyElements: ({ elements, parentId, onSuccess, onError }) => {},
  moveElements: ({ elements, parentId, onSuccess, onError }) => {},
  addTagsToElement: ({ elementId, tags, onSuccess, onError }) => {},
  shareElement: ({ element, sharedWith, onSuccess, onError }) => {},
  updateElementName: ({ elementId, name, onSuccess, onError }) => {},
  updateTag: ({ tag: { id, label, color }, onSuccess, onError }) => {},
  updateElementFavoris: ({ favorite: { elementId, favoris }, onSuccess, onError }) => {},
  updateElementDescription: ({ elementId, description, onSuccess, onError }) => {},
  makeAsPublicOrPrivate: ({ element, privacy = [], isPublic = false, onSuccess, onError }) => {}
});

export const useDriverContext = () => useContext(DocDriverProvider);

export default function DriverContext({ children }) {
  const navigate = useNavigate();
  const { user } = useAuth();

  //#region STATE
  const { pathname } = useLocation();
  const [items, setItems] = useState([]);
  const [rename, setRename] = useState(null);
  const [details, setDetails] = useState(null);
  const [selection, setSelection] = useState([]);
  const [currentTag, setCurrentTag] = useState(null);
  const [topLevel, setTopLevel] = useState(user.uid);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [menuContextuel, setMenuContextuel] = useState(null);
  const [showUpload, onShowUpload, onCloseUpload] = useToggleV2();
  const [currentFolderId, setCurrentFolderId] = useState(user.uid);
  const [defaultFolderId, setDefaultFolderId] = useState(user.uid);
  const [openNewFolder, OnOpenNewFolder, onCloseNewFolder] = useToggleV2();
  const [sortOrder, setSortOrder] = useState(V4_DOC_DRIVER_SORT_ORDER.ASC);
  const [sortType, setSortType] = useState(V4_DOC_DRIVER_SORT.NAME);
  const [filterType, setFilterType] = useState(V4_DOC_DRIVER_FILTER_TYPE.ALL);

  //#endregion

  //#region ACTION
  const docPath = useMemo(() => {
    const split = pathname.split('/');
    if (split.length > 2) {
      return `${split[0]}/${split[1]}/${split[2]}`;
    }
    return pathname;
  }, [pathname]);

  useEffect(() => {
    setSelection([]);
    setDetails(null);
  }, [pathname]);
  //#endregion

  //#region HANDLER
  const handleToggleAll = () => {
    if (selection.length === items.length) {
      return setSelection([]);
    }
    setSelection(items);
  };

  const handleSelection = (item) => {
    const cloneSelection = [...selection];

    const existIndex = cloneSelection.findIndex((one) => one?.id === item?.id);

    if (existIndex !== -1) {
      cloneSelection.splice(existIndex, 1);
      return setSelection(cloneSelection);
    }
    setSelection((old) => [...old, item]);
  };

  const handleOpenFolder = (folderId) => {
    setSelection([]);
    navigate(`${docPath}/${folderId}`);
  };

  const handleCloseUpload = () => {
    onCloseUpload();
    setUploadingFiles([]);
  };

  const handleShowDetails = (_details) => {
    setDetails(_details);
    if (_details === null) setSelection([]);
  };

  const handleCloseSelections = () => setSelection([]);
  //#endregion

  //#region DISPATCHER
  const addFolder = ({ name, parentId = null, onSuccess, onError }) => {
    dispatch(addFolderAction({ parentId: parentId || currentFolderId, name, items, onSuccess, onError }));
  };

  const updateElementName = ({ elementId, name, onSuccess, onError }) => {
    dispatch(updateElementNameAction({ elementId, name, onSuccess, onError }));
  };

  const updateElementDescription = ({ elementId, description, onSuccess, onError }) => {
    dispatch(updateElementDescriptionAction({ elementId, description, onSuccess, onError }));
  };

  const updateElementFavoris = ({ favorite, onSuccess, onError }) => {
    const _callback = () => {
      onSuccess();
      setSelection([]);
    };
    dispatch(updateElementFavorisAction({ favorite, onSuccess: _callback, onError }));
  };

  const addFile = ({ files, folderId, onSuccess, onError }) => {
    const onSave = () => {};

    const _parentId = folderId || currentFolderId;

    const onEveryFileUpload = (file) => {
      dispatch(
        addFileAction({
          parentId: _parentId,
          name: file?.name,
          url: file?.url,
          type: file?.type,
          size: file?.size,
          items,
          onSuccess,
          onError
        })
      );
    };

    onShowUpload();

    multipleFilesSave(files, onSave, null, setUploadingFiles, `okydook_driver/${_parentId}`, onEveryFileUpload, true);
  };

  const copyElements = ({ elements, parentId, onSuccess, onError }) => {
    dispatch(copyElementAction({ items: elements, parentId, onSuccess, onError }));
  };
  const moveElements = ({ elements, parentId, onSuccess, onError }) => {
    dispatch(moveElementAction({ items: elements, parentId, onSuccess, onError }));
  };

  const addToTrash = ({ elements, onSuccess, onError }) => {
    dispatch(toTrashElementAction({ items: elements, onSuccess, onError }));
  };

  const restoreFromTrash = ({ elements, onSuccess, onError }) => {
    dispatch(restoreFromTrashElementAction({ items: elements, onSuccess, onError }));
  };

  const wipeFromTrash = ({ elements, onSuccess, onError }) => {
    dispatch(wipeTrashElementsAction({ items: elements, onSuccess, onError }));
  };

  const shareElement = ({ element, sharedWith, onSuccess, onError }) => {
    dispatch(shareElementAction({ item: element, sharedWith, onSuccess, onError }));
  };

  const addTag = ({ tag, onSuccess, onError }) => {
    dispatch(createTagAction({ tag, onSuccess, onError }));
  };

  const updateTag = ({ tag, onSuccess, onError }) => {
    dispatch(editTagAction({ tag, onSuccess, onError }));
  };

  const addTagsToElement = ({ elementId, tags, onSuccess, onError }) => {
    dispatch(updateElementTagsAction({ elementId, tags, onSuccess, onError }));
  };

  const setSort = (type) => {
    setSortType(type);
    setSortOrder(
      sortOrder === V4_DOC_DRIVER_SORT_ORDER.ASC ? V4_DOC_DRIVER_SORT_ORDER.DESC : V4_DOC_DRIVER_SORT_ORDER.ASC
    );
  };

  const handleFilterTypeChange = (type) => {
    setFilterType(type);
  };

  const makeAsPublicOrPrivate = ({ element, privacy, isPublic, onSuccess, onError }) => {
    dispatch(makeElementAsPrivateOrPublic({ item: element, privacy, isPublic, onSuccess, onError }));
  };

  //#endregion

  const store = {
    items,
    rename,
    addTag,
    docPath,
    details,
    addFile,
    setSort,
    setItems,
    topLevel,
    addFolder,
    setRename,
    updateTag,
    sortType,
    sortOrder,
    filterType,
    selection,
    addToTrash,
    currentTag,
    setTopLevel,
    shareElement,
    copyElements,
    moveElements,
    wipeFromTrash,
    setCurrentTag,
    openNewFolder,
    menuContextuel,
    currentFolderId,
    handleSelection,
    OnOpenNewFolder,
    handleToggleAll,
    defaultFolderId,
    handleOpenFolder,
    addTagsToElement,
    onCloseNewFolder,
    restoreFromTrash,
    setMenuContextuel,
    updateElementName,
    handleShowDetails,
    setDefaultFolderId,
    setCurrentFolderId,
    updateElementFavoris,
    handleCloseSelections,
    handleFilterTypeChange,
    makeAsPublicOrPrivate,
    updateElementDescription
  };

  return (
    <DocDriverProvider.Provider value={store}>
      {children}
      {Boolean(uploadingFiles.length && showUpload) ? (
        <UploadingFileView uploadings={uploadingFiles} onClose={handleCloseUpload} />
      ) : null}
    </DocDriverProvider.Provider>
  );
}
