import PropTypes from 'prop-types';
// @mui
import { Dialog, DialogTitle, TextField, DialogActions, Button, Stack } from '@mui/material';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { LocalizationProvider } from '@mui/lab';
import frLocale from 'date-fns/locale/fr';
import AdapterDayjs from '@mui/lab/AdapterDateFns';
//import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

// ----------------------------------------------------------------------

DatePickerDialog.propTypes = {
  open: PropTypes.bool,
  startTime: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Date)]),
  endTime: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Date)]),
  onChangeEndTime: PropTypes.func,
  onChangeStartTime: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date)
};

export default function DatePickerDialog({
  startTime,
  endTime,
  onChangeStartTime,
  onChangeEndTime,
  open,
  onClose,
  onSave,
  minDate,
  maxDate
}) {
  const handleOnSave = () => {
    onClose();
    onSave();
  };

  const cancel = () => {
    onChangeEndTime(null);
    onChangeStartTime(null);
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle> Date d'échéance </DialogTitle>

      <Stack width={1} justifyContent="center" direction="row" spacing={2} sx={{ px: 3, my: 3 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} locale={frLocale}>
          <DateTimePicker
            label="Date début"
            value={startTime}
            ampmInClock
            onChange={onChangeStartTime}
            renderInput={(params) => <TextField {...params} />}
            minDate={minDate}
            maxDate={endTime ? endTime : maxDate}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} locale={frLocale}>
          <DateTimePicker
            label="Date fin"
            value={endTime}
            onChange={onChangeEndTime}
            renderInput={(params) => <TextField {...params} />}
            maxDate={maxDate}
            minDate={startTime ? startTime : minDate}
          />
        </LocalizationProvider>
      </Stack>

      <DialogActions>
        <Button variant="outlined" onClick={cancel}>
          Annuler
        </Button>
        <Button variant="contained" onClick={handleOnSave}>
          Appliquer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
