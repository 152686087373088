import React, { createContext, useContext, useMemo, useState } from 'react';
import { TaskType } from 'src/models/task_m';
import { getTaskById } from 'src/redux/slices/kanban';
import { dispatch } from 'src/redux/store';
import { PATH_DASHBOARD } from 'src/routes/paths';
import TaskItemDetail from 'src/section/tasks/v4/TaskItemDetail';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const TaskContextProvider = createContext({
  /**
   * @param {TaskType} task
   */
  openTaskDetails: (task, canEdit = null, canDelete = null, canCreate = null) => {},
  closeTaskDetails: () => {},
  openV4TaskById: (taskId, create = null, edit = null, del = null) => {}
});

export const useTaskV4Context = () => useContext(TaskContextProvider);

export default function TaskProvider({ children }) {
  //TODO disable input when user right is not enough
  const [open, setOpen] = useState(false);
  const [task, setTasks] = useState(null);
  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canCreate, setCanCreate] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const openTaskDetails = (_task, canEdit = null, canDelete = null, canCreate = null) => {
    setTasks(_task);
    setOpen(true);
    if( canEdit === null && canDelete === null && canCreate === null){
      return;
    }
    canEdit !== null && setCanEdit(canEdit);
    canDelete !== null && setCanDelete(canDelete);
    canCreate !== null && setCanCreate(canCreate);
  };

  const openV4TaskById = (taskId , create=null, edit=null, del=null) => {
    //console.log({ taskId, create, edit, del });
    dispatch(
      getTaskById({
        taskId,
        resolve: (task) => {
          const projectKey = task?.projectKey || task?.idProject;
          if (projectKey)
            navigate(`${PATH_DASHBOARD.general.tasks}/${projectKey}`, {
              state: {
                projectName: ' ',
                detailId: taskId
              }
            });

          openTaskDetails(task, edit, del, create);
        },
        reject: () => enqueueSnackbar(`Cette tâche n'existe plus`, { variant: 'warning' })
      })
    );
  };

  const closeTaskDetails = () => {
    setOpen(false);
    setTasks(null);
  };

  const store = { openTaskDetails, closeTaskDetails, openV4TaskById };

  return (
    <TaskContextProvider.Provider value={store}>
      {children}
      {open && (
        <TaskItemDetail task={task} open={open} onClose={closeTaskDetails} canEdit={canEdit} canDelete={canDelete} canCreate={canCreate} />
      )}
    </TaskContextProvider.Provider>
  );
}
