import { createSlice, nanoid } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import { TASK_STATE_VALIDATION } from 'src/constants';
import { auth } from 'src/contexts/FirebaseContext';
import { taskDefaultModel } from 'src/helpers/task';
import { changeOnObject } from 'src/utils/changeOnObject';
import firestore from 'src/utils/firestore';
import { serverTime } from 'src/utils/serverTime';
import { taskCreationNotification, taskUpdateNotification } from './notifications';

const initialState = {
  tasks: []
};

const slice = createSlice({
  name: 'task_without_project',
  initialState,
  reducers: {
    addTask(state, action) {
      state.tasks.shift(action.payload);
    },
    addTasks(state, action) {
      state.tasks = action.payload;
    },
    updateTasks(state, action) {
      const task = action.payload;

      //console.log('task', task);

      const position = state.tasks.findIndex((_one) => _one.id === task?.id);
      if (position !== -1) {
        state.tasks[position] = { ...state.tasks[position], ...task };
      }
    }
  }
});

export default slice.reducer;

export const { addTasks } = slice.actions;

export function createTaskWithoutProject(values, callback) {
  return async (dispatch, getState) => {
    const {
      kanban: { currentProject }
    } = getState();
    let id = values?.id || nanoid(26);
    const { uid, displayName, email } = auth.currentUser;

    let task = {
      id,
      ...taskDefaultModel,
      ...values,
      createdBy: { id: uid, email, name: displayName },
      projectState: currentProject?.state || 'open'
    };

    let observerIds = task.observers.map((pers) => pers.id);
    let canAccessId = task.assignee.map((pers) => pers.id);

    canAccessId.push(task.createdBy.id);
    canAccessId.concat(observerIds);

    const assigneIds = task?.assignee ? task?.assignee?.map((_one) => _one.id) : [];
    let assigneByIds = task?.assigneByIds || [];

    if (task.assignee?.length || task?.responsable !== null) {
      assigneByIds = uniq([uid, ...assigneByIds]);
    }

    task = {
      ...task,
      name: task?.name?.replace(/<[^>]+>/g, ''),
      state: TASK_STATE_VALIDATION.PENDING,
      isDeleted: false,
      canAccessId,
      assigneIds,
      assigneByIds,
      isDefaultTask: true,
      projectState: 'open',
      deadLine: task?.due?.at(1),
      createdAt: serverTime(),
      updatedAt: serverTime(),
      subTasksCount: 0
    };

    try {
      dispatch(slice.actions.addTask(task));
      await firestore
        .collection('tasks')
        .doc(id)
        .set({ ...task }, { merge: true });
      if (callback) {
        callback(id);
      }
      //console.log('task', task);
      dispatch(taskCreationNotification({ task }));
    } catch (error) {
      console.error(error);
    }
  };
}

export function updateTaskWithoutProject(oldTask, values, callback) {
  //TODO: fix why if is new task  the deadline is not updated
  return async (dispatch, getState) => {
    let temp = { ...values };
    const { id } = oldTask;
    const isAssigne = values?.assignee || false;
    const oldDueDate = oldTask?.due;

    const {
      kanban: { currentProject, sProject }
    } = getState();

    let project = currentProject || sProject;

    try {
      if (temp?.due) {
        temp = { ...temp, deadLine: temp?.due?.at(1) };
        //console.log('temp', temp.deadLine,values?.due?.at(1));
      }
      if (id) {
        dispatch(slice.actions.updateTasks({ id, values: temp }));

        if (temp?.assignee) {
          const assigneIds = values?.assignee?.map((ass) => ass?.id);
          const canAccessId = uniq([oldTask?.createdBy?.id || '', ...(assigneIds || [])]);
          temp = { ...temp, canAccessId };
        }

        if (!oldTask?.completed && values?.completed) {
          let metadata = { ...(oldTask?.metadata ?? {}) };

          metadata = {
            ...metadata,
            done: {
              doneDate: serverTime(),
              doneBy: {
                id: auth.currentUser?.uid
              }
            }
          };

          temp = { ...temp, metadata };
        }

        if (oldTask?.completed && !values?.completed) {
          let metadata = { ...(oldTask?.metadata ?? {}) };
          metadata = {
            ...metadata,
            done: null
          };
        }

        if (oldDueDate !== temp?.due) {
          //console.log('dateDue is updated');
        }

        await firestore.collection('tasks').doc(id).set(temp, { merge: true });

        const change = changeOnObject(oldTask, values);
        dispatch(
          taskUpdateNotification({
            card: { ...oldTask, projectState: 'open', ...values },
            change,
            projectName: project?.name,
            theOld: oldTask
          })
        );
        if (callback) callback();
      }
    } catch (error) {
      console.error(error);
    }
  };
}
