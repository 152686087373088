import { useRef, useState, useMemo } from 'react';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import closeFill from '@iconify/icons-eva/close-circle-fill';
import suspendreFill from '@iconify/icons-eva/pause-circle-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Card,
  Stack,
  Alert,
  Button,
  MenuItem,
  Typography,
  IconButton,
  CardHeader,
  CardContent,
  CardActionArea,
  CircularProgress
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { useDispatch } from 'src/redux/store';
import { projectActuSelected, selectProject } from 'src/redux/slices/kanban';
import useToggle from '../../../hooks/useToggle';
import MenuPopover from '../../../components/MenuPopover';
import NotistackProvider from '../../../components/NotistackProvider';
import { useSnackbar } from 'notistack';
import { deleteProject, updateProject } from '../../../redux/slices/kanban';
import { MAvatar } from '../../../components/@material-extend';
import createAvatar from 'src/utils/createAvatar';
import play32Filled from '@iconify/icons-fluent/play-32-filled';
import ProgressView from 'src/components/ProgressView';

export const PROJECT_STATE = {
  OPEN: 'open',
  CLOSED: 'closed',
  SUSPENDED: 'suspended'
};

export default function ProjectCard({ project, handleOpen, role }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const anchorRef = useRef(null);
  const anchorMRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [pending, setPending] = useState(false);
  const { open: hover, handleClose: closeHover, handleOpen: openHover } = useToggle();
  const { open: openD, handleClose: closeDelete, handleOpen: openDelete } = useToggle();
  const isDisabled = useMemo(() => project?.closed || project?.suspended, [project?.closed, project?.suspended]);

  const handleSelectProject = () => {
    dispatch(selectProject(project.id));
    dispatch(projectActuSelected(project));
    navigate(`${PATH_DASHBOARD.general.tasks}/${project?.id}`, {
      state: { projectName: project.name, projectState: project?.state }
    });
  };

  const handleDelete = () => {
    const callback = () => {
      setPending(false);
      closeDelete();
      enqueueSnackbar('Projet supprimé avec succès', { variant: 'warning' });
      navigate(PATH_DASHBOARD.general.accueil);
    };

    dispatch(deleteProject(project.id, callback));
  };

  const handleOnCloture = () => {
    dispatch(
      updateProject({ ...project, state: 'closed', closed: true }, () => {
        closeHover();
        enqueueSnackbar('Projet clôturé avec succès', { variant: 'info' });
      })
    );
  };

  const handleReopen = () => {
    dispatch(
      updateProject({ ...project, state: 'open', closed: false, suspended: false }, () => {
        closeHover();
        enqueueSnackbar('Projet réouvert avec succès', { variant: 'info' });
      })
    );
  };

  const handleOnSuspends = () => {
    dispatch(
      updateProject({ ...project, state: 'suspended', suspended: true }, () => {
        closeHover();
        enqueueSnackbar('Projet Suspendu avec succès', { variant: 'info' });
      })
    );
  };

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        <Card
          elevation={3}
          sx={{ borderRadius: 1, width: 270, cursor: 'pointed', bgcolor: (theme) => theme.palette.background.default }}
        >
          <CardHeader
            sx={{
              backgroundImage: `url(/static/bg-images/${project?.background}.jpg)`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover'
            }}
            avatar={
              <MAvatar
                aria-label="recipe"
                sx={{ mb: 1.5, mt: -1, ml: -2 }}
                src={project?.createBy?.avatar}
                alt={project?.createBy?.displayName}
                color={project?.createBy?.avatar ? 'default' : createAvatar(project?.createBy?.displayName).color}
              >
                {createAvatar(project?.createBy?.displayName).displayName}
              </MAvatar>
            }
            action={
              <IconButton sx={{ mr: -1.5, mt: -3 }} aria-label="settings" onClick={openHover} ref={anchorMRef}>
                <MoreVertIcon sx={{ color: 'white' }} />
              </IconButton>
            }
            title={`créé par ${project?.createBy?.displayName || '...'}`}
            titleTypographyProps={{
              variant: 'overline',
              sx: {
                color: 'white',
                fontSize: 10
              }
            }}
            subheader={
              <Typography color="white" fontSize={10}>
                {' '}
                Le {project?.createdAt?.toDate().toLocaleDateString('fr') || '...'}{' '}
              </Typography>
            }
          />
          <CardActionArea
            onClick={handleSelectProject}
            sx={{ bgcolor: (theme) => (isDisabled ? theme.palette.action.disabled : theme.palette.background.default) }}
          >
            <CardContent sx={{ justifyContent: 'center', textAlign: 'center' }}>
              <Typography variant="overline" fontSize={12}>
                {project?.name?.toString()}
              </Typography>
            </CardContent>
            <Stack width={1} sx={{ px: 1, bgcolor: (theme) => theme.palette.background.paper }}>
              <ProgressView height={5} size={10} value={Number(Number(project?.progress || 0).toFixed(0))} />
            </Stack>
            {isDisabled && (
              <Stack
                sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, bgcolor: alpha('#FFFFFF', 0.4) }}
              ></Stack>
            )}
          </CardActionArea>
        </Card>
      </Box>

      <MenuPopover open={hover} onClose={closeHover} anchorEl={anchorMRef.current}>
        {role?.task?.edit && (
          <MenuItem onClick={handleOpen}>
            <Box component={Icon} icon={editFill} sx={{ width: 20, height: 20, flexShrink: 0, mr: 1 }} />
            <Typography variant="body2">Editer</Typography>
          </MenuItem>
        )}

        {role?.task?.edit && (project?.closed || project?.suspended) && (
          <MenuItem onClick={handleReopen}>
            <Box component={Icon} icon={play32Filled} sx={{ width: 20, height: 20, flexShrink: 0, mr: 1 }} />
            <Typography variant="body2">{'Réouvrir'}</Typography>
          </MenuItem>
        )}

        {role?.task?.edit && !project?.closed && !project?.suspended && (
          <MenuItem onClick={handleOnCloture}>
            <Box component={Icon} icon={closeFill} sx={{ width: 20, height: 20, flexShrink: 0, mr: 1 }} />
            <Typography variant="body2">{'Clôturer'}</Typography>
          </MenuItem>
        )}

        {role?.task?.edit && !project?.suspended && !project?.closed && (
          <MenuItem onClick={handleOnSuspends}>
            <Box component={Icon} icon={suspendreFill} sx={{ width: 20, height: 20, flexShrink: 0, mr: 1 }} />
            <Typography variant="body2">Suspendre</Typography>
          </MenuItem>
        )}

        {role?.task.delete && (
          <MenuItem onClick={openDelete} sx={{ py: 0.75, px: 1.5 }} ref={anchorRef}>
            <Box component={Icon} icon={trash2Outline} sx={{ width: 20, height: 20, flexShrink: 0, mr: 1 }} />
            <Typography variant="body2">Supprimer</Typography>
          </MenuItem>
        )}
      </MenuPopover>

      <MenuPopover sx={{ width: 500 }} open={openD} onClose={closeDelete} anchorEl={anchorRef.current}>
        <NotistackProvider>
          <Alert severity="error">Vous êtes sur le point de supprimer un projet, Continuer ?</Alert>
        </NotistackProvider>
        <Stack direction="row" spacing={3} sx={{ p: 1, float: 'right' }}>
          <Button color="info" disabled={pending} onClick={closeDelete}>
            {' '}
            Non, annuler
          </Button>
          <Button color="error" disabled={pending} onClick={handleDelete}>
            {pending ? <CircularProgress color="primary" size={20} /> : ' Oui, supprimer'}
          </Button>
        </Stack>
      </MenuPopover>
    </>
  );
}
