export const pageName = {
  RECENT: 'Fichiers récents',
  MYSHARES: 'Mes partages',
  MYFOLDERS: 'Mes dossiers',
  SHAREWITHME: 'Partagé avec moi',
  TRASH: 'Corbeille',
  FAVORITE: 'Mes favoris',
  TAGS: 'Mes tags'
};


export const V4_DOC_DRIVER_SORT_ORDER = {
  ASC: 'ASC',
  DESC: 'DESC'
};

export const V4_DOC_DRIVER_SORT_ORDER_CONVERT = {
  [V4_DOC_DRIVER_SORT_ORDER.ASC]: 'Croissant',
  [V4_DOC_DRIVER_SORT_ORDER.DESC]: 'Décroissant'
};

export const V4_DOC_DRIVER_SORT = {
  NAME: 'name',
  MODIFIED_DATE: 'updatedAt',
  CREATED_DATE: 'createdAt',
};

export const V4_DOC_DRIVER_SORT_CONVERT = {
  [V4_DOC_DRIVER_SORT.NAME]: 'Nom',
  [V4_DOC_DRIVER_SORT.MODIFIED_DATE]: 'Date de modification',
  [V4_DOC_DRIVER_SORT.CREATED_DATE]: 'Date de création'
};

export const V4_DOC_DRIVER_FILTER_TYPE = {
  ALL: 'Tout',
  FOLDER: 'Dossiers',
  DOCUMENT: 'Documents',
  EXCEL: 'Feuilles de calcul',
  POWERPOINT: 'Présentations',
  PDF: 'PDF',
  IMAGE: 'Images',
  AUDIO: 'Audio',
  VIDEO: 'Vidéos',
  OTHERS: 'Autres'
};

export const V4_DOC_DRIVER_FILTER_TYPE_EXTENTION = {
  [V4_DOC_DRIVER_FILTER_TYPE.ALL]: [],
  [V4_DOC_DRIVER_FILTER_TYPE.FOLDER]: ['folder'],
  [V4_DOC_DRIVER_FILTER_TYPE.IMAGE]: ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp'],
  [V4_DOC_DRIVER_FILTER_TYPE.EXCEL]: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.spreadsheetml.template', 'application/vnd.ms-excel.sheet.macroEnabled.12', 'application/vnd.ms-excel.template.macroEnabled.12', 'application/vnd.ms-excel.addin.macroEnabled.12', 'application/vnd.ms-excel.sheet.binary.macroEnabled.12',"text/csv"],
  [V4_DOC_DRIVER_FILTER_TYPE.POWERPOINT]: ['application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.openxmlformats-officedocument.presentationml.template', 'application/vnd.openxmlformats-officedocument.presentationml.slideshow', 'application/vnd.ms-powerpoint.addin.macroEnabled.12', 'application/vnd.ms-powerpoint.presentation.macroEnabled.12', 'application/vnd.ms-powerpoint.template.macroEnabled.12', 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12'],
  [V4_DOC_DRIVER_FILTER_TYPE.PDF]: ['application/pdf'],
  [V4_DOC_DRIVER_FILTER_TYPE.DOCUMENT]: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.wordprocessingml.template', 'application/vnd.ms-word.document.macroEnabled.12', 'application/vnd.ms-word.template.macroEnabled.12'],
  [V4_DOC_DRIVER_FILTER_TYPE.AUDIO]: ['audio/mpeg', 'audio/ogg', 'audio/wav', 'audio/webm', 'audio/flac', 'audio/aac', 'audio/mp4', 'audio/x-ms-wma', 'audio/x-ms-wax', 'audio/x-ms-wmv', 'audio/x-ms-wmx'],
  [V4_DOC_DRIVER_FILTER_TYPE.VIDEO]: ['video/mp4', 'video/ogg', 'video/webm', 'video/x-ms-wmv', 'video/x-ms-wmx', 'video/x-ms-wvx', 'video/x-ms-wax', 'video/x-ms-asf', 'video/x-ms-asx', 'video/x-ms-avi', 'video/x-ms-mov', 'video/x-ms-mp4', 'video/x-ms-mpeg', 'video/x-ms-wm', 'video/x-ms-wmv', 'video/x-ms-wmx', 'video/x-ms-wvx', 'video/x-msvideo'],
  [V4_DOC_DRIVER_FILTER_TYPE.OTHERS]: []
};