import { Box, Button, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { MIconButton } from './@material-extend';
import { Icon } from '@iconify/react';
import audioFill from '@iconify/icons-bi/mic';
import audioOffFill from '@iconify/icons-bi/mic-mute';
import trashFill from '@iconify/icons-eva/trash-fill';
import { timerDisplay } from '../section/tasks/kanban/KanbanTaskCard';
import useAudioRecorder from '../hooks/useAudioRecorder';
import { useRef, useState } from 'react';
import { CustomMenuPopover } from './MenuPopover';
import MusicPlayerSlider from './MusicPlayerSlider';
import { nanoid } from '@reduxjs/toolkit';
import { serverTime } from '../utils/serverTime';
import { saveFile } from '../redux/slices/document';
import UploadingOneFileProgress from './UploadingOneFileProgress';
import useAuth from '../hooks/useAuth';
import { baseColors } from 'src/constants/color';
import Iconify from './Iconify';
import {
  Close, ArrowBackIos, Send,
  StopCircle,
  Delete,
  Mic
} from '@mui/icons-material';


const AudioRecordingAnimation = () => {
  return (
    <Box
      sx={{
        width: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

      }}
      className="shimmer-animation"
      style={{
        backgroundImage: `url('https://i.pinimg.com/originals/9e/0a/39/9e0a396664e576895185704830813734.gif')`
      }}
    />
  );

}


/**
 *
 * @param {{
 *  dispatcher: function(file, {commentObject, cleanup }),
 *  size: number?,
 *  setRecording: function(boolean),
 * setLoading: function(boolean),
 * _setOpenVoiceNote: function(boolean),
 * customAction: function({onRecordingClick: function, isRecording: boolean}),
 * getBrutFile? : Boolean,
 * anchorOrigin : {vertical: string, horizontal: string},
 * transformOrigin : {vertical: string, horizontal: string},
 * }} param
 * @returns
 */
export default function AudioRecorder({
  dispatcher,
  size = 25,
  setRecording = null,
  setLoading = null,
  _setOpenVoiceNote = () => { },
  customAction,
  getBrutFile = false,
  anchorOrigin,
  transformOrigin
}) {
  const voiceRef = useRef();

  const {
    user: { email, id, displayName, role, photoURL }
  } = useAuth();

  const [voiceNote, setVoiceNote] = useState(null);
  const [voiceNoteTime, setVoiceNoteTime] = useState(0);
  const [voiceNoteBlob, setVoiceNoteBlob] = useState(null);
  const [openVoiceNote, setOpenVoiceNote] = useState(null);
  const [uploading, setFUploading] = useState(false);

  const { isRecording, recordingTime, startRecording, stopRecording, clearRecordingTime } = useAudioRecorder({
    onNoteDone: setVoiceNote,
    getBlob: setVoiceNoteBlob
  });


  console.log(voiceNoteTime);

  const onRecordingClick = () => {
    if (isRecording) {
      setVoiceNoteTime(recordingTime);
      stopRecording();
      setOpenVoiceNote(true);
      _setOpenVoiceNote(true);
      return;
    }
    startRecording();
    if (setRecording) setRecording(true);
  };

  const onCloseVoiceNote = () => {
    setOpenVoiceNote(false);
    _setOpenVoiceNote(false);
    if (setRecording) setRecording(false);
    clearRecordingTime();
  };

  const handlesendVoiceNote = () => {
    if (isRecording) {
      setVoiceNoteTime(recordingTime);
      stopRecording();
    }
    onSendVoiceNote()
    clearRecordingTime();
  }

  const onSendVoiceNote = () => {
    if (setLoading) setLoading(true);
    setOpenVoiceNote(false);
    _setOpenVoiceNote(false);
    const docId = nanoid();

    const onAudioSave = (_file) => {
      setFUploading(false);

      const commentObject = {
        userId: id,
        avatar: photoURL,
        name: displayName,
        email,
        messageType: 'audio',
        createdAt: serverTime(),
        message: _file?.url,
        read: 0,
        readState: []
      };

      if (dispatcher) {
        dispatcher(
          _file,
          {
            commentObject,
            cleanup: () => {
              setVoiceNote(null);
              setVoiceNoteBlob(null);
            }
          },
          voiceNoteTime
        );
      }
    };

    const fileName = `Note vocale ${nanoid()}`;
    const file = new File([voiceNoteBlob], fileName, { type: 'audio/webm' });

    if (setRecording) setRecording(false);

    if (getBrutFile) {
      onAudioSave(file);
      return;
    }

    saveFile(file, onAudioSave, null, setFUploading, docId).catch((error) => console.error(error));
  };

  return (
    <Stack
      //width={'100%'}
      spacing={1} justifyContent="flex-start" alignItems="center"
      //sx={{ bgcolor: 'lightgrey' }}
      sx={
        {
          width: (!recordingTime && !openVoiceNote) ? 'auto' : '100%',
          //bgcolor: 'lightgrey'
        }
      }
    >
      <Stack direction="row-reverse" spacing={1} justifyContent="flex-end" alignItems="center"
        // sx={{ bgcolor: 'sandybrown' }}
        sx={
          {
            width: (!recordingTime && !openVoiceNote) ? 'auto' : '100%',
            //bgcolor: 'lightgrey'
          }
        }
      >

        {/* {recordingTime ? (
          <CustomMenuPopover
            open={Boolean(recordingTime)}
            anchorEl={voiceRef.current}
            width="max-content"
            anchorOrigin={anchorOrigin || {
              vertical: 'top',
              horizontal: 'center'
            }}
            transformOrigin={transformOrigin || {
              vertical: 'bottom',
              horizontal: 'center'
            }}
            arrow="bottom-center"
            customcolor={baseColors.BLEU_N}
            PaperProps={{ sx: { bgcolor: baseColors.BLEU_N, px: 2, py: 1, overflow: 'hidden' } }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography fontSize={15} fontWeight="bold" color="#FFF">
                {timerDisplay(recordingTime)}
              </Typography>
              <IconButton size="small" onClick={onRecordingClick} sx={{ bgcolor: '#FFF' }}>
                <Icon icon="ic:round-stop" color={baseColors.REDD} />
              </IconButton>
            </Stack>
          </CustomMenuPopover>
        ) : null} */}

        <Stack ref={voiceRef}
          //sx={{ bgcolor: 'royalblue'}}
          sx={
            {
              width: (!recordingTime && !openVoiceNote) ? 'auto' : '100%',
            }
          }
        >
          {customAction ? (
            customAction({ onRecordingClick, isRecording })
          ) : (
            <Stack direction="row" spacing={1} alignItems="center"
            //sx={{cursor: 'pointer',bgcolor: 'GrayText'}}
            >

              {recordingTime && !openVoiceNote ? (
                <Stack direction="row" spacing={1} alignItems="center" mx={1}
                  width={'100%'}
                  justifyContent={'flex-end'}
                //sx={{ bgcolor: 'ButtonText' }}
                >
                  <Stack direction="row" spacing={1} alignItems="center" width={'100%'}
                    px={3}
                    py={1}
                    sx={{
                      cursor: 'pointer',
                      bgcolor: 'whitesmoke',
                      borderRadius: 10,
                      //p: 0.5,

                    }}
                  >
                    <Typography fontSize={15} fontWeight="bold" >
                      {recordingTime ? timerDisplay(recordingTime) : 'Enregistrement ...'}
                    </Typography>

                  </Stack>
                  <Stack direction="row" spacing={1} alignItems="center" width={40}>
                    <IconButton size={'small'} onClick={onRecordingClick} >
                      <StopCircle color={baseColors.REDD} sx={{ color: 'red' }} />
                    </IconButton>
                  </Stack>
                  <IconButton size={'small'} onClick={handlesendVoiceNote}>
                    <Send />
                  </IconButton>
                </Stack>) : null}
              {openVoiceNote ? (
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" width={'100%'} >
                  <MusicPlayerSlider
                    song={voiceNote}
                    duration={voiceNoteTime}
                    sx={{
                      width: '100%',
                      cursor: 'pointer'
                    }}
                  />
                  <Tooltip title="Suprimer la note vocale" arrow>
                    <IconButton size={'small'} onClick={onCloseVoiceNote}>
                      <Delete color={baseColors.REDD} sx={{ color: 'red' }} />
                    </IconButton>
                  </Tooltip>
                  <IconButton size={'small'} onClick={handlesendVoiceNote}>
                    <Send />
                  </IconButton>
                </Stack>
              ) : null}
              {
                !recordingTime && !openVoiceNote && (

                  <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" width={40}
                    sx={{
                      //bgcolor: 'ButtonText'
                    }}
                  >
                    <Tooltip title="Note vocale" arrow>
                      <IconButton size={'small'} onClick={onRecordingClick}>
                        <Mic />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                )}

            </Stack>
          )}
        </Stack>
      </Stack>

      {/* {openVoiceNote && (
        <CustomMenuPopover
          open={openVoiceNote}
          onClose={onCloseVoiceNote}
          anchorEl={voiceRef.current}
          zIndex={2500}
          disabledArrow
          anchorOrigin={anchorOrigin || {
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={transformOrigin || {
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >
          <MusicPlayerSlider song={voiceNote} duration={voiceNoteTime} />
          <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ px: 1, pb: 0.5 }}>
            <Button color="inherit" size="small" variant="outlined" onClick={onCloseVoiceNote}>
              Annuler
            </Button>
            <Button color="primary" size="small" variant="contained" onClick={onSendVoiceNote}>
              Envoyer
            </Button>
          </Stack>
        </CustomMenuPopover>
      )} */}

      {!uploading && <UploadingOneFileProgress uploading={uploading} />}
    </Stack >
  );
}
