export function getTaskRights(task) {
    const url = window.location.href;
    const projectId = task?.projectKey;
    const storeKey = `${projectId}_My_Project`;

    if (!task) {
        //console.log("Task not defined");
        console.trace();
    }


    return {
        create: true,
        update: true,
        delete: true,
        validate: true,
    }
}