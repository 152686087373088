import React, { createContext, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAffectation_v4Settings } from 'src/hooks/useAffectation_v4';

import { AFFECT_TYPE } from 'src/constants/affectation';
import { Affectation_Setting_Type, Affectation_v4_Type } from 'src/models/Affectation_v4_type';

import AffectionForm from 'src/section/affectation_v4/AffectionForm';
import { getCourielById } from 'src/redux/slices/affectation_v4';
import useAuth from 'src/hooks/useAuth';
import AffectationDetails from 'src/section/affectation_v4/AffectationDetails';

const Affection_v4ContextProvider = createContext({
  openForm: (formType) => { },
  openFormById: (id) => { },
  /**@type {Affectation_Setting_Type} */
  settings: null,
  loading: false,
  /** @param {'toDoIds' | 'toReviewIds' | 'historyIds'} type */
  setAffectationTabType: (type) => { },
  /** @type {'toDoIds' | 'toReviewIds' | 'historyIds'}  */
  affectationTabType: 'toDoIds',
  /** @type {Array<Affectation_v4_Type} */
  /** @param {Affectation_v4_Type} affectation */
  handleOpenDetails: (affectation, disabled) => { }
});

export const useAffectation_v4Context = () => useContext(Affection_v4ContextProvider);

export default function Affectation_v4Provider({ children }) {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [affectationTabType, setAffectationTabType] = useState('toDoIds');
  const { settings, loading: settingLoading } = useAffectation_v4Settings();

  //console.log('settings', settings);

  const [open, setOpen] = useState([]);
  const [openDetails, setOpenDetails] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const handleOpen = (type) => {
    setOpen((old) => [...old, { id: old.length, type }]);
  };

  const handleClose = (position) => {
    setOpen((old) => {
      let _old = [...old];
      _old.splice(position, 1);
      return _old;
    });
  };

  const handleOpenById = (id) => {
    dispatch(
      getCourielById(id, (data) => {
        handleOpenDetails(data);
      })
    );
  };

  const handleCloseDetails = () => setOpenDetails(null);
  const handleOpenDetails = (affect, disabled = false) => {
    setDisabled(disabled)
    setOpenDetails(affect)
  };

  const store = {
    openForm: handleOpen,
    openFormById: handleOpenById,
    settings,
    loading: settingLoading,
    setAffectationTabType,
    affectationTabType,
    handleOpenDetails
  };

  return (
    <Affection_v4ContextProvider.Provider value={store}>
      {children}
      {Boolean(open.length) &&
        open.map((one, index) => (
          <AffectionForm
            key={one.id + 'aff'}
            open={Boolean(one)}
            formId={one.id}
            onClose={() => handleClose(index)}
            isEnter={one.type === AFFECT_TYPE.Enter}
          />
        ))}
      {Boolean(openDetails) && (
        <AffectationDetails open={Boolean(openDetails)} onClose={handleCloseDetails} affectation={openDetails} disabled={disabled} />
      )}
    </Affection_v4ContextProvider.Provider>
  );
}
