import React from 'react';
import { Icon } from '@iconify/react';
import { Box, MenuItem, Typography, Tooltip } from '@mui/material';

export const MenuLine = ({
  onClick,
  icon,
  altIcon,
  iconColor,
  title,
  color,
  variant = 'caption',
  divider = false,
  fontSize = null,
  iconSize = null,
  fontWeight = 400,
  disabled = false,
  disabledColor = 'grey',
  tooltipTitle = 'cette action est désactivée'
}) => {
  return (
    <Tooltip title={disabled ? tooltipTitle : ''} placement="right">
      <MenuItem
        divider={divider}
        disabled={disabled}
        onClick={disabled ? undefined : onClick}
        sx={{
          py: 1,
          px: 1.5,
          '&:hover': disabled ? {} : { backgroundColor: 'rgba(0, 0, 0, 0.04)' } // Désactiver le hover si le menu est désactivé
        }}
      >
        <Box
          {...((iconColor || color) && { color: disabled ? disabledColor : iconColor || color })}
          component={Icon}
          icon={icon}
          sx={{ width: 15, height: 15, flexShrink: 0, mr: 1, ...iconSize }}
        />
        <Typography
          variant={variant}
          {...(fontSize && { fontSize: fontSize })}
          {...(color && { color: disabled ? disabledColor : color })}
          fontWeight={fontWeight}
        >
          {title}
        </Typography>
        <Box
          {...(altIcon && { color: disabled ? disabledColor : iconColor || color })}
          component={Icon}
          icon={altIcon}
          sx={{ width: 15, height: 15, flexShrink: 0, ml: 'auto', ...iconSize }}
        />
      </MenuItem>
    </Tooltip>
  );
};
