import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import backFill from '@iconify/icons-eva/arrow-back-fill';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Drawer, Tooltip, CardActionArea, IconButton, CircularProgress } from '@mui/material';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
//
import Dropzone from 'react-dropzone';
import sidebarConfig from './SidebarConfig';
import { useDashbord } from './index';
import useToggle from './../../hooks/useToggle';
import { saveFile } from 'src/redux/slices/document';
import { firestore } from 'src/contexts/FirebaseContext';
import { useDashbord2 } from 'src/layouts/GuestLayout';
import CollapseButton from '../CollapseButton';
import { NAVBAR } from 'src/config';
import AccountPopover from './AccountPopover';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

// ----------------------------------------------------------------------
const Input = styled('input')({
  display: 'none'
});

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ onCloseSidebar }) {
  const { pathname } = useLocation();
  const { setTitle } = useDashbord();
  const navigate = useNavigate();
  const { setTitle: setTitle2 } = useDashbord2();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const { open: hover, handleClose: closeHover, handleOpen: openHover } = useToggle();

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, isOpenSidebar } = useCollapseDrawer();

  useEffect(() => {
    setTitle(null);
    setTitle2(null);
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const onFileDrop = async (acceptedFiles) => {
    setLoading(true);

    const file = acceptedFiles[0];

    await saveFile(file, (_save) => {
      firestore
        .collection('logo')
        .doc('logo')
        .set(isLight ? { logo_light: _save } : { logo_dark: _save }, { merge: true })
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    });
  };

  const logoChanged = async (e) => {
    setLoading(true);
    const file = e.target.files[0];
    await saveFile(file, (_save) => {
      firestore
        .collection('logo')
        .doc('logo')
        .set(isLight ? { logo_light: _save } : { logo_dark: _save }, { merge: true })
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    });
  };

  const forwardAction = () => {
    navigate(-1);
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Stack
        onMouseEnter={openHover}
        onMouseLeave={closeHover}
        spacing={3}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
          ...(isCollapse && {
            alignItems: 'center'
          })
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
            {!isCollapse && (
              <Dropzone onDrop={(acceptedFiles) => onFileDrop(acceptedFiles)}>
                {({ getRootProps }) => (
                  <Box minHeight={110} {...getRootProps()}>
                    <Logo hover={hover} />
                  </Box>
                )}
              </Dropzone>
            )}
            {loading && (
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, height: 150 }}
              >
                <CircularProgress size={20} />
              </Stack>
            )}
          </Box>

          <MHidden width="lgDown">
            {!isCollapse && (
              <IconButton
                onClick={forwardAction}
                size="small"
                sx={{
                  position: 'absolute',
                  top: 5,
                  right: 7,
                  bgcolor: (t) => t.palette.primary.main,
                  '&:hover': { bgcolor: (t) => t.palette.primary.main }
                }}
              >
                <Icon color="white" icon={backFill} width={25} height={25} />
              </IconButton>
            )}
          </MHidden>
          <CollapseButton onToggleCollapse={onToggleCollapse} isCollapse={isCollapse} collapseClick={collapseClick} />
        </Stack>

        {hover && (
          <label htmlFor="icon-button-file" style={{ position: 'absolute', top: -15, right: 60 }}>
            <Input accept="image/*" id="icon-button-file" type="file" onChange={logoChanged} />
            <IconButton component="span">
              <Icon icon={editFill} width={20} height={20} />
            </IconButton>
          </label>
        )}
      </Stack>

      <NavSection navConfig={sidebarConfig} isShow={!isCollapse} />

      <Box sx={{ flexGrow: 1 }} />
      <Stack p={1}>
        <AccountPopover onTop={false} />
      </Stack>

      {!isCollapse && (
        <Stack spacing={3} alignItems="center" sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center' }}></Stack>
      )}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: NAVBAR.DASHBOARD_WIDTH, bgcolor: '#1E1F21' }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              bgcolor: '#1E1F21',
              ...(isCollapse && {
                width: NAVBAR.COLLAPSE_WIDTH,
                overflow: 'hidden'
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
              })
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
