import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import firestore from '../../utils/firestore';
import { isEmpty, omit, reject, uniq, uniqBy } from 'lodash';
import { auth } from '../../contexts/FirebaseContext';
import { NOTIFICATION_TYPES } from '../../constants';
import { PATH_DASHBOARD } from '../../routes/paths';
import axios from 'axios';
import axiosRequest from './../../utils/axiosRequest';
import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { TASK_STATE_VALIDATION } from 'src/constants';
import { serverTime } from 'src/utils/serverTime';
import { getCurrentUserAccess } from 'src/helpers/user';
import { MAIL_CONFIG, NOTIF_CONFIG } from 'src/config';
import { compareArraysOfObjects } from 'src/utils/changeOnObject';

const { subject, from, domaine } = MAIL_CONFIG;

const slice = createSlice({
  name: 'notifications',
  initialState: {
    isLoading: false,
    error: false,
    tokens: [],
    notifications: []
  },
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      console.error(action.payload);
    },

    gotSuccess(state) {
      state.isLoading = false;
    },

    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },

    deleteNotification(state, action) {
      state.notifications = reject(state.notifications, { id: action.payload });
    },

    setTokens(state, action) {
      state.tokens = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;
// Actions
export const { startLoading, gotSuccess, hasError, getNotificationsSuccess } = slice.actions;

//#region async actions
export const setTokens = (users) => {
  return async (dispatch) => {
    const tokens = users
      .filter((u) => u?.token || u?.mobileToken)
      .map((us) => {
        return { id: us.id, token: us.token, mobileToken: us?.mobileToken };
      });
    dispatch(slice.actions.setTokens(tokens));
  };
};

export const getAllNotification = (list, callback = null) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      dispatch(getNotificationsSuccess(list));
      callback && callback();
    } catch (error) {
      dispatch(hasError(error));
    }
  };
};

const sendFirebaseNotification = async (getState, data, canAccessWithoutSender) => {
  const allTokens = getState().notifications.tokens;

  const tokensWithoutSender = allTokens.filter((one) => one.id !== auth.currentUser.uid);

  const canReceivNotification = [...canAccessWithoutSender].map((id) => {
    return [...tokensWithoutSender].find((tk) => tk.id === id);
  });

  const tokens = [];
  const mobileToken = [];

  canReceivNotification.forEach((us) => {
    if (us?.token) {
      tokens.push(us.token);
    }
    if (us?.mobileToken) {
      mobileToken.push(us?.mobileToken);
    }
  });

  const emit = async (tokens = [], link = 'many') => {
    if (tokens.length) {
      const desc = {
        createdAt: data?.createdAt,
        type: data?.type,
        cardTitle: data?.cardTitle,
        title: data?.title,
        by: data?.by,
        description: data?.description,
        other: { ...(data?.other || { projectName: data?.action?.projectName }) },
        action: data?.returnId && {
          id: data?.returnId,
          sub: data?.action?.projectKey,
          ...(data?.action?.title && { title: data?.action?.title }),
          ...(data?.action?.messageId && { messageId: data?.action?.messageId }),
          ...(data?.action?.messageTitle && { messageTitle: data?.action?.messageTitle })
        },
        url: data?.action?.url
      };

      await axios.post(`${NOTIF_CONFIG.fb_notif}/${link}`, {
        tokens,
        title: data.title,
        description: data?.description,
        data: desc
      });
    }
  };

  emit(tokens, 'many');
  emit(mobileToken, 'many-mobile');
};

/**
 *
 * @param {{
 * data:{
 *      createdAt: Date,
 *      isUnRead: string[],
 *      canAccessEmail: string[],
 *      canAccess: string[],
 *      description: string,
 *      title: string,
 *      type: string
 *      action: {url: string?, title: string?, id: string?, sub: string?},
 *      by: {
 *          id: string,
 *          displayName: string,
 *          photoURL: string,
 *      },
 * },
 * callback: Function
 * }}
 * @returns {(function(*): Promise<void>)|*}
 */
//TODO : is use to push notification
export const createNotification = ({ data, callback }) => {
  //console.log('createNotification', { data });
  return async (dispatch, getState) => {
    dispatch(startLoading());
    try {
      const { canAccess } = data;
      const canAccessWithoutSender = canAccess.filter((id) => id !== auth.currentUser.uid);
      //console.log({ canAccessWithoutSender });

      if (canAccessWithoutSender.length === 0) {
        dispatch(gotSuccess());
        callback && callback();
        return;
      }
      await firestore.collection('notifications').add({
        ...{ ...data, canAccess: canAccessWithoutSender },
        by: {
          id: auth.currentUser.uid,
          displayName: auth.currentUser.displayName || '',
          photoURL: auth.currentUser.photoURL || ''
        }
      });

      sendFirebaseNotification(getState, data, canAccessWithoutSender);

      dispatch(gotSuccess());
      callback && callback();
    } catch (error) {
      //console.error(error);
      dispatch(hasError(error));
    }
  };
};

//create Mail is use to send mail to all users
/**
 * @param {{
 * users: {
 *    id: string,
 *    displayName: string,
 *    email: string,
 * }[],
 * subject: string,
 * description: string,
 * link: string,
 * header: string
 * }}
 * @returns {(function(*): Promise<void>)|*}
 * 
 */
export const createMail = ({ users, subject, description, link, header }) => {
  const currentUser = auth.currentUser;
  const currentUserEmail = currentUser?.email;
  const uniqueUsers = uniqBy(users, 'email');
  const uniqueUsersWithoutCurrentUser = uniqueUsers.filter((one) => one?.email !== currentUserEmail);
  return async (dispatch) => {
    uniqueUsersWithoutCurrentUser.map(async (pers) => {
      //console.log('pers', pers?.email , description);
      const data = {
        salutation: '',
        name: pers?.name || pers?.displayName,
        description: description,
        link: link,
        subject: subject,
        header: header
      };

      try {
        await axiosRequest.post('/mail/template', {
          to: pers.email,
          from,
          templateId: MAIL_CONFIG.template.members,
          data
        });
      } catch (error) {
        //console.log('Error while sending mail to ', pers.email, error);
      }
    });
  };
};
  

export const setNotificationsAsRead = createAsyncThunk(
  'notification/mark_as_read',
  async ({ notificationIds = [] }, { dispatch, getState }) => {
    try {
      const {
        notifications: { notifications: list }
      } = getState();

      const userId = getCurrentUserAccess()?.id;
      const batch = firestore.batch();

      // console.log('notificationIds', notificationIds)

      notificationIds.forEach((id) => {
        const ref = firestore.collection('notifications').doc(id);
        batch.set(ref, { isUnRead: { [userId]: false } }, { mergeFields: [`isUnRead.${userId}`] });
      });

      await batch.commit();
    } catch (error) {
      dispatch(hasError(error));
    }
  }
);
//#endregion

//#region PROJECT
export const ProjectCreateNotification = ({ project }) => {
  return async (dispatch, getState) => {
    const { users } = getState().user;
    
    //console.log('ProjectCreateNotification', { project });
    let notifTitle = 'Vous avez été ajouté à un projet';
    let notifType = NOTIFICATION_TYPES.PROJECT_MANAGER;

    const { canAccessId, managerIds,managers  } = project;

    let listIds = {};
    const canAccessIdManagers = managerIds || [];
    const canAccessIds = uniq([...canAccessId]).filter(id => !canAccessIdManagers.includes(id));    
    const canAccess = canAccessIds.map((oneId) => users.find(_user => _user?.id === oneId)).filter(one => one);

    canAccessIds.forEach((id) => (listIds[id] = true));

    //console.log({managerIds, canAccessIdManagers});

    dispatch(
      createNotification({
        data: {
          title: 'Vous avez été nommé gestionnaire',
          description:'Vous avez été ajouté au projet <<' + project?.name + '>> en tant que gestionnaire',
          createdAt: serverTime(),
          type: notifType,
          returnId: project?.id,
          cardTitle: project?.name,
          canAccess: canAccessIdManagers,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectKey: project.id
          }
        },
        callback: (e) => {
          //console.log('ProjectCreateNotification callback', { e });
        }
      })
    );

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: 'Vous avez été ajouté au projet <<' + project?.name + '>>',
          createdAt: serverTime(),
          type: notifType,
          returnId: project?.id,
          cardTitle: project?.name,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectKey: project.id
          }
        },
        callback: (e) => {
          //console.log('ProjectCreateNotification callback', { e });
        }
      })
    );
    const thisUser = auth.currentUser;
    const accessLink = `https://${domaine}/dashboard/task/${project?.id || ''}`;
    dispatch(
      createMail({
        users: canAccess,
        subject: subject,
        description: `Vous avez été ajouté au projet <<${project?.name}>> par ${thisUser?.displayName}`,
        link: accessLink,
        header: notifTitle
      })
    );
    dispatch(
      createMail({
        users: managers,
        subject: subject,
        description: `Vous avez été nommé << Gestionnaire >> du projet <<${project?.name}>> par ${thisUser?.displayName}`,
        link: accessLink,
        header: 'Vous avez été nommé gestionnaire'
      })
    );
  };
};
export const ProjectUpdateNotification = ({ project, oldProject }) => {
  return async (dispatch) => {
    //console.log('ProjectUpdateNotification', { project, oldProject });
    let notifTitle = 'Vous avez été ajouté a un projet';
    let notifType = NOTIFICATION_TYPES.PROJECT_UPDATE;

    const { canAccessId, managers, managerIds,canAccess } = project;
    const convertManagerToManagerIds = (managers) => managers?.map((_one) => _one.id) || [];

    const _managers = convertManagerToManagerIds(managers);
    const _oldManagers = convertManagerToManagerIds(oldProject?.managers);
    const _canAccess = oldProject?.canAccess || [];
    //console.log({ canAccessId, managers,_managers });

    const isAdd= compareArraysOfObjects(_managers, _oldManagers) && compareArraysOfObjects(canAccess, _canAccess);

    let listIds = {};

    const oldcanAccessId = oldProject?.canAccessId || [];
    const oldManager = oldProject?.managers || [];

    const resAccessId = canAccessId.filter((_id) => !oldcanAccessId.find((id) => id === _id)) || [];
    const resAccessManagerId = _managers.filter((_id) => !_oldManagers.find((id) => id === _id)) || [];

    const resAccessIds = uniq([...resAccessId, ...resAccessManagerId]);

    const resAcess = [];
    resAccessIds.forEach((id) => {
      const user = [...canAccess, ..._canAccess , ...managers, ...oldManager].find((u) => u.id === id);
      if (user) resAcess.push(user);
    });


    resAccessIds.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: 'Vous avez été ajouté au projet <<' + project?.name + '>>',
          createdAt: serverTime(),
          type: notifType,
          returnId: project?.id,
          cardTitle: project?.name,
          canAccess: resAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName: project?.name,
            projectKey: project.id,
            id: project?.id
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: resAcess,
        subject: subject,
        description: `Vous avez été ajouté au projet <<${project?.name}>> par ${thisUser?.displayName}`,
        link: `https://${domaine}/dashboard/task/${project?.id || ''}`,
        header: notifTitle
      })
    );
  };
};
//#endregion

//#region Task

export const taskCreateNotification = ({ card, projectName }) => {
  return async (dispatch) => {
    //console.log('taskCreateNotification', { card, projectName });
    let notifTitle = 'Une tâche a été créée';
    let notifType = NOTIFICATION_TYPES.TASK_CREATE;

    const { managers } = card;
    const canAccessId = managers?.map((_one) => _one.id) || [];

    let listIds = {};
    canAccessId.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: 'La tâche ' + card?.name + ' du projet ' + projectName + ' a été créée',
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessId,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: managers,
        subject: subject,
        description: `La tâche ${card?.name} du projet ${projectName} a été créée par ${thisUser?.displayName}`,
        link: `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`,
        header: notifTitle
      })
    );
  };
};

export const taskDeleteNotification = ({ card, projectName,project }) => {
  return async (dispatch) => {
    //console.log('taskDeleteNotification', { card, projectName });
    let notifTitle = 'Une tâche a été supprimée';
    let notifType = NOTIFICATION_TYPES.TASK_DELETE;

    const { managers } = project;
    const canAccessId = managers?.map((_one) => _one.id) || [];

    let listIds = {};
    canAccessId.forEach((id) => (listIds[id] = true));

    //console.log({ canAccessId });

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: 'La tâche ' + card?.name + ' du projet ' + projectName + ' a été supprimée',
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessId,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: managers,
        subject: subject,
        description: `La tâche ${card?.name} du projet ${projectName} a été supprimée par ${thisUser?.displayName}`,
        link: `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`,
        header: notifTitle
      })
    );
  };
};





export const taskRetireNotification = ({ card, assigneChange, projectName }) => {
  return async (dispatch) => {
    let notifTitle = 'Une tâche vous a été retirée';
    let notifType = NOTIFICATION_TYPES.TASK_RETRIEVE;

    let listIds = {};
    const canAccessIdManagers = assigneChange?.map((_one) => _one.id) || [];
    const canAccessIds = uniq([...canAccessIdManagers]);

    canAccessIds.forEach((id) => (listIds[id] = true));

    //console.log({ canAccessIds, canAccessIdManagers, canAccessId: card?.canAccessId });

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: 'Vous avez été retiré de la tâche ' + card?.name + ' du projet ' + projectName,
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey
          }
        }
      })
    );
    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: assigneChange,
        subject: subject,
        description: `La tâche ${card?.name} du projet ${projectName} vous a été retirée par ${thisUser?.displayName}`,
        link: `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`,
        header: `Une tâche vous a été retirée`
      })
    );
  };
};
//TODO: use to add notification to all users
export const taskAssigneeNotification = ({ card, assigneChange, projectName }) => {
  return async (dispatch) => {
    //console.log('taskAssigneeNotification', { card, assigneChange, projectName });
    let notifTitle = 'Une tâche vous est attribuée';
    let notifType = NOTIFICATION_TYPES.TASK_VALIDATION_ASSIGN_USER;

    const { displayName, uid, photoURL } = auth.currentUser;

    let listIds = {};
    const canAccessIdManagers = assigneChange?.map((_one) => _one.id) || [];
    const canAccessIds = uniq([...canAccessIdManagers]);

    canAccessIds.forEach((id) => (listIds[id] = true));

    //console.log({ canAccessIds, canAccessIdManagers, canAccessId: card?.canAccessId });

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description:
            'Vous avez été assigné à la tâche ' + card?.name + `${projectName ? ' du projet ' + projectName : ''}`,
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: uid,
            displayName: displayName || '',
            name: displayName || '',
            photoURL: photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: assigneChange,
        subject: subject,
        description: `La tâche "${card?.name}"  "${
          projectName ? 'du projet ' + projectName : ''
        }" vous a été attribuée par ${thisUser?.displayName}`,
        link: `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`,
        header: `Une tâche vous est attribuée'`
      })
    );
  };
};

export const taskCompletedNotification = ({ card, project }) => {
  return async (dispatch, getState) => {
    const { users } = getState()?.user;

    //TODO : verifier les canAccessId
    let notifTitle = 'Tâche terminée';
    let notifType = NOTIFICATION_TYPES.TASK_VALIDATION_DONE;
    const { displayName, uid } = auth.currentUser;
    const projectName  = project?.name;

    //console.log({ project });
    const managers = project?.managers || [];
    const managersId = managers?.map((_one) => _one.id) || [];
    const canAccess = card?.canAccessId;

    const canAccessIds = uniq([...canAccess, ...managersId])

    let listIds = {};

    const canAccessUsersWithoutMe = canAccessIds.map((_id) => users.find(one => one?.id === _id)).filter((one) => one?.id !== uid);

    canAccessIds.forEach((id) => (listIds[id] = true));

    //console.log({ canAccessUsersWithoutMe });

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: `${displayName} a marqué la tâche << ${card?.name} >> du projet <<${projectName}>> comme terminée`,
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card?.projectKey
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: canAccessUsersWithoutMe,
        subject: subject,
        description: `La tâche ${card?.name} du projet ${projectName} a été marqué comme terminée par ${thisUser?.displayName}`,
        link: `https://${domaine}/dashboard/task/${card?.projectKey}/${card?.id || ''}`,
        header: `Tâche terminée`
      })
    );
  };
};

export const taskValidationDemandeNotification = ({ card, projectName }) => {
  return async (dispatch) => {
    //console.log('taskValidationDemandeNotification', { card, projectName });
    let notifTitle = 'Demande de validation';
    let notifType = NOTIFICATION_TYPES.TASK_VALIDATION_DEMANDE;

    const { managers } = card;
    const canAccessId = managers?.map((_one) => _one.id) || [];

    let listIds = {};
    canAccessId.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: 'Demande de validation pour la tâche ' + card?.name + ' du projet ' + projectName,
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessId,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: managers,
        subject: subject,
        description: `Vous avez reçu une demande de validation sur la tâche ${card?.name} du projet ${projectName} par ${thisUser?.displayName}`,
        link: `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`,
        header: `Une demande de validation`
      })
    );
  };
};

export const taskBeginningNotification = ({ card, projectName }) => {
  return async (dispatch) => {
    let notifTitle = 'Tâche démarrée';
    let notifType = NOTIFICATION_TYPES.TASK_BEGINING;

    const { managers } = card;
    const canAccessId = managers?.map((_one) => _one.id) || [];

    let listIds = {};
    canAccessId.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: 'La tâche ' + card?.name + ' du projet ' + projectName + ' a été démarrée',
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessId,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: managers,
        subject: subject,
        description: `La tâche ${card?.name} du projet ${projectName} a été démarrée par ${thisUser?.displayName}`,
        link: `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`,
        header: notifTitle
      })
    );
  };
};

export const taskValidationRejectNotification = ({ card, projectName }) => {
  return async (dispatch) => {
    let notifTitle = 'Tâche rejetée';
    let notifType = NOTIFICATION_TYPES.TASK_VALIDATION_REJECT;

    const { canAccessId, assignee, managers } = card;

    let listIds = {};
    const canAccessIdManagers = managers?.map((_one) => _one.id) || [];
    const canAccessIds = uniq([...canAccessIdManagers, ...canAccessId]);

    canAccessIds.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: 'Rejet de la tâche: ' + card?.name + ' du projet ' + projectName,
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: uniqBy([...assignee, ...managers], 'email'),
        subject: subject,
        description: `La demande de validation de la tâche ${card?.name} du projet ${projectName} a été rejetée par ${thisUser?.displayName}`,
        link: `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`,
        header: `Demande de validation rejetée`
      })
    );
  };
};

export const taskValidationDoneNotification = ({ card, projectName }) => {
  return async (dispatch) => {
    let notifTitle = 'Tâche validée';
    let notifType = NOTIFICATION_TYPES.TASK_VALIDATION_DONE;

    const { canAccessId, assignee, managers, createdBy } = card;
    
    let listIds = {};
    const canAccessIdManager = managers?.map((_one) => _one.id) || [];
    const assigneeIds = assignee?.map((_one) => _one.id) || [];
    const createdByIds = createdBy?.map((_one) => _one.id) || [];

    const canAccessIds = uniq([...canAccessIdManager, ...canAccessId, ...assigneeIds, ...createdByIds]);
    canAccessIds.forEach((id) => (listIds[id] = true));

    //console.log({ canAccessIds, canAccessIdManager, canAccessId });

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: `Tâche ${card?.name} du projet ${projectName} a marqué comme terminée`,
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: uniqBy([...assignee, ...(managers || [])], 'email'),
        subject: subject,
        description: `La tâche ${card?.name} du projet ${projectName} a été marqué comme terminée par ${thisUser?.displayName}`,
        link: `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`,
        header: `Tâche validée`
      })
    );
  };
};



export const taskValidationAcceptNotification = ({ card, projectName }) => {
  return async (dispatch) => {
    let notifTitle = 'Tâche validée';
    let notifType = NOTIFICATION_TYPES.TASK_VALIDATION_ACCEPTED;

    const { canAccessId, assignee, managers } = card;

    let listIds = {};
    const canAccessIdManager = managers?.map((_one) => _one.id) || [];
    const assigneeIds = assignee?.map((_one) => _one.id) || [];

    const canAccessIds = uniq([...canAccessIdManager, ...canAccessId], 'id');
    canAccessIds.forEach((id) => (listIds[id] = true));

    //console.log({ canAccessIds, canAccessIdManager, canAccessId });

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: `Tâche ${card?.name} du projet ${projectName} a été validée`,
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: uniqBy([...assignee, ...(managers || [])], 'email'),
        subject: subject,
        description: `La demande de validation de la tâche ${card?.name} du projet ${projectName} a été acceptée par ${thisUser?.displayName}`,
        link: `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`,
        header: `Demande de validation acceptée`
      })
    );
  };
};

export const taskDueChangeNotification = ({ card, projectName }) => {
  return async (dispatch) => {
    let notifTitle = 'L’échéance d’une tâche qui vous est attribuée a été modifiée';
    let notifType = NOTIFICATION_TYPES.TASK_DUE_CHANGE;

    const { canAccessId, assignee, managers } = card;

    let listIds = {};
    const canAccessIdManager = managers?.map((_one) => _one.id) || [];

    const canAccessIds = uniq([...canAccessIdManager, ...canAccessId]);
    canAccessIds.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: `L’échéance de la tâche ${card?.name} du projet ${projectName} a été modifiée`,
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: uniqBy([...assignee, ...(managers || [])], 'email'),
        subject: subject,
        description: `L’échéance de la tâche ${card?.name} du projet ${projectName} a été modifiée par ${thisUser?.displayName}`,
        link: `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`,
        header: `L’échéance d’une tâche qui vous est attribuée a été modifiée`
      })
    );
  };
};


export const taskattachmentsChangeNotification = ({ card, projectName ,change,attachCount}) => {
  return async (dispatch) => {
    //console.log({attachCount});
    const attachementsLength = attachCount|| 0;
    const textPluriel = attachementsLength > 1 ? 'de nouvelles pièces jointes' : 'une nouvelle pièce jointe';
    const textPlurielNotif = attachementsLength > 1 ? ' ont été ajoutées' : ' a été ajoutée';
    //console.log('taskattachmentsChangeNotification', { card, projectName });
    let notifTitle = 'Une tâche que vous suivez a ' + textPluriel;
    let notifType = attachementsLength > 1? NOTIFICATION_TYPES.TASK_PIECE_JOINTES : NOTIFICATION_TYPES.TASK_PIECE_JOINTE;

    const { canAccessId, assignee, managers } = card;

    let listIds = {};
    const canAccessIdManager = managers?.map((_one) => _one.id) || [];

    const canAccessIds = uniq([...canAccessIdManager, ...canAccessId]);
    canAccessIds.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: textPluriel+textPlurielNotif+` de la tâche ${card?.name} du projet ${projectName}`,
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: uniqBy([...(assignee || []), ...(managers || [])], 'email'),
        subject: subject,
        description: `La tâche ${card?.name} du projet ${projectName} a ${textPluriel} par ${thisUser?.displayName}`,
        link: `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`,
        header: `Une tâche que vous suivez a ${textPluriel}`
      })
    );
  };
};

export const taskUpdateNotification = createAsyncThunk(
  'notification/task/update',
  async ({ card, change, projectName, theOld = null }, { dispatch, getState }) => {
    // let notifType = NOTIFICATION_TYPES.TASK;
    // let notifTitle = `Voici ce que vous avez manqué`;
    const { currentProject } = getState().kanban;
    //console.log({ currentProject });

    const taskCard = { ...card, mamangers: [...(card?.mamangers || []), ...(currentProject?.managers || [])] };

    const { canAccessId, mamangers } = card;

    let listIds = {};
    const canAccessIdManagers = mamangers?.map((_one) => _one.id) || [];
    const canAccessIds = uniq([...canAccessIdManagers, ...canAccessId]);

    canAccessIds.forEach((id) => (listIds[id] = true));

    //console.log({ taskCard })
    //change :[ { completed: false } ],

    //console.log({ card, change, projectName, theOld });
    if (change.length) {
      const stateChange = change.filter((_one) => _one?.state || false);
      const completedChange = change.filter((_one) => _one?.completed || false);
      const assigneChange = change.filter((_one) => _one?.assignee || false);
      const dueChange = change.filter((_one) => _one?.due || false);
      const attchmentChange = change.filter((_one) => _one?.attachments || false);

      //console.log({change, stateChange, completedChange, assigneChange, dueChange, attchmentChange});
      if (assigneChange?.length) {
        //console.log('assigneChange', assigneChange);
        if (theOld) {
          let oldAssinge = [...(theOld?.assignee || [])];
          let newAssigne = assigneChange[0]?.assignee || [];
          const { added, removed } = compareArraysOfObjects(oldAssinge, newAssigne);
          //console.log({ added, removed });
          removed.length !== 0 &&
            dispatch(taskRetireNotification({ card: taskCard, assigneChange: removed, projectName }));
          added.length !== 0 &&
            dispatch(taskAssigneeNotification({ card: taskCard, assigneChange: added, projectName }));
        } else {
          //console.log('not old task');
        }
      }
      if (change?.length) {
        //console.log(stateChange);
        if (completedChange[0]?.completed) {
          //console.log('completedChange', completedChange);
          dispatch(taskCompletedNotification({ card: taskCard, project: currentProject }));
          return;
        }

        if (stateChange[0]?.state === TASK_STATE_VALIDATION.REJECTED) {
          dispatch(taskValidationRejectNotification({ card: taskCard, change, projectName }));
          return;
        }

        if (stateChange[0]?.state === TASK_STATE_VALIDATION.ACCEPTED) {
          //dispatch(taskValidationAcceptNotification({ card: taskCard, change, projectName }));
          dispatch(taskValidationDoneNotification({ card: taskCard, projectName }));
          return;
        }
        if (stateChange[0]?.state === TASK_STATE_VALIDATION.INPROGRESS) {
          dispatch(taskBeginningNotification({ card: taskCard, change, projectName }));
          return;
        }
      }
      if (dueChange?.length) {
        dispatch(taskDueChangeNotification({ card: taskCard, projectName }));
      }
      if (attchmentChange?.length) {
        //console.log('attchmentChange', attchmentChange?.length);
        if (theOld) {
          let rest = attchmentChange[0]?.attachments;
          theOld?.attachments.forEach((_one) => (rest = rest?.filter((_as) => _as?.id !== _one?.id)));

          if (rest.length) {
            dispatch(taskattachmentsChangeNotification({ card: taskCard, projectName , change: attchmentChange, attachCount: rest?.length}));
          }
        }
      }
    }
  }
);

export const taskMoveNotification = createAsyncThunk(
  'notification/task/move',
  async ({ card, project, oldOperationName, newOperationName }, { dispatch }) => {
    const { canAccessId, mamangers: managers, createdBy } = card;
    const thisUser = auth.currentUser;

    let canAccessIdManagers = managers?.map((_one) => _one.id) || [];

    let listIds = {};
    const canAccessIds = uniq([...canAccessIdManagers, ...canAccessId, createdBy?.id]).filter(
      (_id) => _id && !isEmpty(_id) && _id !== thisUser.uid
    );

    const projectName = project?.name;

    canAccessIds.forEach((id) => (listIds[id] = true));

    const desc = `La tâche << ${card.name} >> a été déplacée par << ${auth.currentUser.displayName} >> depuis l'operation << ${oldOperationName} >> vers l'operation << ${newOperationName} >> du projet << ${project?.name} >>`;

    dispatch(
      createNotification({
        data: {
          title: `Tâche déplacée`,
          description: desc,
          createdAt: new Date(),
          type: NOTIFICATION_TYPES.TASK,
          isUnRead: listIds,
          canAccess: canAccessIds,
          returnId: card?.id,
          cardTitle: card?.name,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName: project?.name,
            projectKey: card?.projectKey || card?.idProject,
            id: card?.id
          }
        }
      })
    );
    dispatch(
      createMail({
        users: canAccessIds,
        subject: subject,
        description: desc,
        link: `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`,
        header: `La tâche "${card?.name}" a été déplacée`
      })
    );
  }
);

export const taskCreationNotification = createAsyncThunk('notification/task/new', async ({ card }, { dispatch }) => {
  const { canAccessId, managers } = card;

  //console.log({card});

  let canAccessIdManagers = managers?.map((_one) => _one.id) || [];

  let listIds = {};
  const canAccessIds = uniq([...canAccessIdManagers, ...canAccessId]);

  canAccessIds.forEach((id) => (listIds[id] = true));

  const desc = `Vous avez été ajouté à la tâche ${card.name} par 
     ${auth.currentUser.displayName},
      le ${format(new Date(), 'dd MMMM yyyy HH:mm', { locale: frLocale })}
    `;

  //console.log({ canAccessIds, canAccessIdManagers, canAccessId: card?.canAccessId });

  dispatch(
    createNotification({
      data: {
        title: `Nouvelle tâche`,
        description: desc,
        createdAt: new Date(),
        type: NOTIFICATION_TYPES.TASK,
        isUnRead: listIds,
        canAccess: canAccessIds,
        by: {
          id: auth.currentUser.uid,
          displayName: auth.currentUser.displayName || '',
          photoURL: auth.currentUser.photoURL || ''
        },
        action: {
          projectKey: card?.projectKey || card?.idProject,
          id: card?.id
        }
      }
    })
  );
});

export const taskDeletionNotification = createAsyncThunk(
  'notification/task/delete',
  async ({ cardId }, { dispatch, getState }) => {
    //console.log('taskDeletionNotification', { cardId });
    const {
      kanban: {
        board: { cards: cardList }
      }
    } = getState();
    const card = cardList[cardId];

    const { canAccessId, managers } = card;
    let listIds = {};
    const canAccessIdManagers = managers?.map((_one) => _one.id) || [];
    const canAccessIds = uniq([...canAccessIdManagers, ...canAccessId]);

    canAccessIds.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: `Suppresion de la tache`,
          description: ` "${card.name}" `,
          createdAt: new Date(),
          type: NOTIFICATION_TYPES.TASK,
          isUnRead: listIds,
          canAccess: canAccessIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          }
        }
      })
    );
  }
);

export const taskCommentAddNotification = ({ canReceived, projectName, projectKey, taskId, taskName }) => {
  return async (dispatch) => {
    let notifTitle = 'Un commentaire a été publié dans une tâche que vous suivez';
    let notifType = NOTIFICATION_TYPES.TASK_COMMENT_ADD;

    let listIds = {};

    const canAccessIdManagers = canReceived?.map((_one) => _one.id) || [];
    const canAccessIds = uniq([...canAccessIdManagers]);

    canAccessIds.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: 'Vous un nouveau commentaire sur la tâche ' + taskName + ' du projet ' + projectName,
          createdAt: new Date(),
          type: notifType,
          returnId: taskId,
          cardTitle: taskName,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            projectKey: projectKey
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: canReceived,
        subject: subject,
        description: `Un commentaire a été publié dans la tâche ${taskName} du projet ${projectName} par ${thisUser?.displayName}`,
        link: `https://${domaine}/dashboard/task/${projectKey}/${taskId}`,
        header: `Un commentaire a été publié dans une tâche que vous suivez`
      })
    );
  };
};

export const taskMentionNotification = ({
  canReceived,
  projectName,
  projectKey,
  taskId,
  taskName,
  target = 'dans le commentaire'
}) => {
  return async (dispatch) => {
    let notifTitle = 'Vous avez été mentionné';
    let notifType = NOTIFICATION_TYPES.TASK_MENTION;

    let listIds = {};
    //console.log('mentions -- ', canReceived);
    const canAccessIdManagers = canReceived?.map((_one) => _one.id) || [];
    const canAccessIds = uniq([...canAccessIdManagers]);

    canAccessIds.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: `Vous avez été mentionné ${target} sur la tâche "` + taskName + '" du projet ' + projectName,
          createdAt: new Date(),
          type: notifType,
          returnId: taskId,
          cardTitle: taskName,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            target,
            projectName,
            projectKey: projectKey,
            taskId,
            id: taskId,
            cardId: taskId,
            detailId: taskId,
            returnId: taskId,
            taskName,
            cardName: taskName
          }
        },
        returnId: taskId
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: canReceived,
        subject: subject,
        description: `Vous avez été mentionné ${target} dans la tâche "${taskName}" du projet "${projectName}" par ${thisUser?.displayName}`,
        link: `https://${domaine}/dashboard/task/${projectKey}/${taskId}`,
        header: 'Vous avez été mentionné'
      })
    );
  };
};

//#endregion

//#region SUB TASK
export const subTaskAssignNotification = ({ taskName, taskId, projectId, projectName, subTask, oldSubTask = null }) => {
  return async (dispatch) => {
    let notifTitle = 'Une sous tâche vous a été assignée';
    let notifType = NOTIFICATION_TYPES.SUB_TASK_VALIDATION_ASSIGN_USER;

    const { assigne } = subTask;

    let listIds = {};
    const oldManager = oldSubTask?.assigne || [];

    const canAccessIdManager = assigne?.map((_one) => _one.id) || [];
    const resAccessId = canAccessIdManager.filter((_id) => !oldManager.find((id) => id === _id)) || [];
    const canAccessIds = uniq([...resAccessId]);

    canAccessIds.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: `Vous avez été ajouté à la sous tâche ${subTask?.title}, de la tâche ${taskName} du project ${projectName}`,
          createdAt: serverTime(),
          type: notifType,
          returnId: taskId,
          cardTitle: subTask?.title,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          other: {
            taskName,
            projectName
          },
          action: {
            projectKey: projectId
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    dispatch(
      createMail({
        users: assigne,
        subject: subject,
        description: `Une sous tâche vous a été assignée sur la tâche ${taskName} du projet ${projectName} par ${thisUser?.displayName}`,
        link: `https://${domaine}/dashboard/task/${projectId}/${taskId}`,
        header: `Une sous tâche vous a été assignée`
      })
    );
  };
};
//#endregion

//#region blog

export const blogCreationNotification = createAsyncThunk('notification/blog/new', async ({ post }, { dispatch }) => {
  dispatch(
    createNotification({
      data: {
        title: post.title,
        description: post.body.substring(0, 30),
        createdAt: new Date(),
        type: NOTIFICATION_TYPES.BLOG,
        isBroadcast: true,
        isUnRead: null,
        canAccess: null,
        action: {
          postId: post.id
        }
      }
    })
  );
});

//#endregion

//#region audience

export const audienceNotification = ({
  audience,
  type = NOTIFICATION_TYPES.AUDIENCE,
  users = [],
  title,
  description
}) => {
  return (dispatch) => {
    const canAccessId = (audience?.personToMeet || []).map((el) => el?.id);
    let listIds = {};
    canAccessId.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title,
          description,
          createdAt: new Date(),
          type,
          isUnRead: listIds,
          canAccess: canAccessId,
          action: {
            url: PATH_DASHBOARD.general.accueil.root,
            audienceId: audience?.id
          }
        }
      })
    );
  };
};
export const audienceCreationNotification = createAsyncThunk(
  'notification/audience/new',
  async ({ data }, { dispatch }) => {
    const { canAccessId } = data;

    let listIds = {};
    canAccessId.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: "Nouvelle demande d'audience",
          description: `pour ${data.lastName} ${data.firstName}`,
          createdAt: new Date(),
          type: NOTIFICATION_TYPES.AUDIENCE,
          isUnRead: listIds,
          canAccess: canAccessId,
          action: {
            url: PATH_DASHBOARD.general.accueil.root
          }
        }
      })
    );
  }
);

export const audienceUpdateNotification = createAsyncThunk(
  'notification/audience/update',
  async ({ data }, { dispatch }) => {
    const { canAccessId } = data;

    let listIds = {};
    canAccessId.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: "Maj de la demande d'audience",
          description: `pour ${data.lastName} ${data.firstName}`,
          createdAt: new Date(),
          type: NOTIFICATION_TYPES.AUDIENCE,
          isUnRead: listIds,
          canAccess: canAccessId,
          action: {
            url: PATH_DASHBOARD.general.accueil.root
          }
        }
      })
    );
  }
);

//#endregion

//#region stage

export const stageCreationNotification = createAsyncThunk('notification/stage/new', async ({ data }, { dispatch }) => {
  try {
    const { docs } = await firestore.collection('users').where('roles.stage.read', '==', true).get();

    const canAccessId = [...docs.map((one) => one.id)];

    let shouldRead = {};
    canAccessId.forEach((id) => (shouldRead[id] = true));

    dispatch(
      createNotification({
        data: {
          title: 'Nouvelle demande de stage',
          description: `pour ${data.lastName} ${data.firstName}`,
          createdAt: new Date(),
          type: NOTIFICATION_TYPES.STAGE,
          isUnRead: shouldRead,
          canAccess: canAccessId,
          action: {
            url: PATH_DASHBOARD.general.accueil.root
          }
        }
      })
    );
  } catch (e) {
    console.error(e);
  }
});

//#endregion

//#region chat

export const chatCreationNotification = createAsyncThunk(
  'notification/chat/new',
  async ({ data }, { dispatch, getState }) => {
    try {
      const { conversationId } = data;
      const conversations = getState().firestore.ordered['conversations'];
      const { participants, participantsId, lastMessage = '' } = conversations?.find((el) => el?.id === conversationId);
      const { displayName, uid: currentUserId } = auth.currentUser;
      const isGroup = participants.length > 2;

      const receiverIds = participantsId.filter((one) => one !== currentUserId);

      let shouldRead = {};
      receiverIds.forEach((id) => (shouldRead[id] = true));

      dispatch(
        createNotification({
          data: {
            title: isGroup ? 'Nouveau message' : displayName,
            description: lastMessage,
            createdAt: new Date(),
            type: NOTIFICATION_TYPES.CHAT,
            isUnRead: shouldRead,
            canAccess: receiverIds,
            by: {
              id: auth.currentUser.uid,
              displayName: auth.currentUser.displayName || '',
              photoURL: auth.currentUser.photoURL || ''
            },
            action: {
              url: null,
              lastMessage
            }
          }
        })
      );
    } catch (e) {
      console.error(e);
    }
  }
);

export const projectConversationNotification = createAsyncThunk(
  'notification/project/chat/new',
  async ({ data }, { dispatch }) => {
    try {
      const { participantIds, lastMessage, projectId, title, description } = data;

      let shouldRead = {};

      participantIds.filter((r) => r !== lastMessage.senderId).forEach((id) => (shouldRead[id] = true));
      // const description = data.message.length > 0 ? data.message : data.attachments.length > 0 ? 'Ajout de piéce joint' : 'Un nouveau message';

      dispatch(
        createNotification({
          data: {
            title: 'Vous avez un nouveau message dans la conversation du projet: <<' + title + ' >>',
            description,
            createdAt: new Date(),
            type: NOTIFICATION_TYPES.PROJECT_CHAT,
            isUnRead: shouldRead,
            canAccess: participantIds,
            by: {
              id: auth.currentUser.uid,
              displayName: auth.currentUser.displayName || '',
              photoURL: auth.currentUser.photoURL || ''
            },
            action: {
              projectName: title,
              projectKey: projectId,
              url: projectId
            },
            url: projectId
          }
        })
      );
    } catch (e) {
      console.error(e);
    }
  }
);

export const chatForsakeNotification = (notifications) => {
  return async (dispatch) => {
    try {
      const batch = firestore.batch();

      notifications.forEach((one) => {
        const { id, canAccess, isUnRead, ...rest } = one;
        const ref = firestore.collection('notifications').doc(id);
        const currentUserId = auth.currentUser.uid;
        const newUnRead = omit(isUnRead, [currentUserId]);
        const newAccess = reject(canAccess, (id) => id === currentUserId);

        batch.set(ref, { ...rest, isUnRead: newUnRead, canAccess: newAccess });
      });

      await batch.commit();
    } catch (e) {
      console.error(e);
    }
  };
};

//#endregion

//#region RELANCE
export const sendTaskRelanceNotification = ({ sendTo, taskId, projectId, projectName, taskName, callback = null }) => {
  return async (dispatch) => {
    try {
      let notifTitle = 'Vous avez été relancé sur une tâche';
      let notifType = NOTIFICATION_TYPES.RELANCE_TASK;

      let listIds = {};

      const resAccessId = sendTo.map((_one) => _one?.id);
      const canAccessIds = uniq([...resAccessId]);

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/task/${projectId}/${taskId}`;
      const description = `Vous avez été relancé sur la tâche ${taskName} du projet ${projectName} par ${thisUser?.displayName}`;

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: taskId,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: auth.currentUser.uid,
              displayName: auth.currentUser.displayName || '',
              photoURL: auth.currentUser.photoURL || ''
            },
            other: {
              taskName,
              projectName
            },
            action: {
              projectKey: projectId
            }
          }
        })
      );
      dispatch(
        createMail({
          users: sendTo,
          subject: subject,
          description: description,
          link: `https://${domaine}/dashboard/task/${projectId}/${taskId}`,
          header: `Vous avez été relancé sur une tâche`
        })
      );

      callback && callback();
    } catch (error) {}
  };
};
//#endregion

/**
 *
 * @param data {{createdAt: Date, isUnRead: string[], canAccessEmail: string[], canAccess: string[], description: string, action: {url: string}, by: {id: string,displayName: string,photoURL: string,}, avatar: string, title: string, type: string}}
 * @param callback {function()}
 * @returns {(function(*): Promise<void>)|*}
 */
export const archiveNotification = (data, callback = null) => {
  return async (dispatch) => {
    try {
      dispatch(
        createNotification({
          data: { ...data, type: NOTIFICATION_TYPES.ARCHIVE, createdAt: new Date() },
          callback
        })
      );
    } catch (e) {}
  };
};

export const chatMentionNotification = ({ canReceived, conversationId }) => {
  return async (dispatch) => {
    try {
      let notifTitle = 'Vous avez été mentionné';
      let notifType = NOTIFICATION_TYPES.CHAT_MENTION;

      let listIds = {};
      const canAccessIdManagers = canReceived?.map((_one) => _one.id) || [];
      const canAccessIds = uniq([...canAccessIdManagers]);

      canAccessIds.forEach((id) => (listIds[id] = true));

      const person = getCurrentUserAccess();

      const data = {
        title: notifTitle,
        description: `Vous avez été mentionné dans une coversation par ${person.name}`,
        createdAt: new Date(),
        type: notifType,
        canAccess: canAccessIds,
        isUnRead: listIds,
        by: {
          id: auth.currentUser.uid,
          displayName: auth.currentUser.displayName || '',
          photoURL: auth.currentUser.photoURL || ''
        },
        action: {
          conversationId,
          userName: person.name
        }
      };
      dispatch(
        createNotification({
          data
        })
      );
    } catch (e) {
      //console.log(e);
      console.trace();
    }
  };
};

    
export const courielCreationNotification = ({ couriel, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'Une nouvelle courriel a été créée';
      let notifType = NOTIFICATION_TYPES.COURIEL_CREATION;
       const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);
      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/task/${couriel?.id}`;
      const description = `Une nouvelle courriel a été créée ${couriel?.courriel_object} par ${thisUser?.displayName}`;
      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: couriel?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: auth.currentUser.uid,
              displayName: auth.currentUser.displayName || '',
              photoURL: auth.currentUser.photoURL || ''
            },
            other: {
              taskName: couriel?.courriel_object,
              projectName: couriel?.courriel_reference
            },
            action: {
              projectKey: couriel?.id
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description: description,
          link: `https://${domaine}/dashboard/task/${couriel?.id}`,
          header: `Une nouvelle courriel a été créée`
        })
      );

      callback && callback();
    } catch (error) { }
  };
};

export const affectationAssignNotification = ({ affectation, taskId, projectId, projectName, taskName, callback = null }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'Vous avez été assigné à une affectation';
      let notifType = NOTIFICATION_TYPES.AFFECTATION_ASSIGN; 
       const { users } = getState().user;

      let listIds = {};

      const resAccessId = affectation?.defaultReceiver?.map((_one) => _one?.id);
      const canAccessIds = uniq([...resAccessId]);
      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/task/${projectId}/${taskId}`;
      const description = `Vous avez été assigné à une affectation ${taskName} du projet ${projectName} par ${thisUser?.displayName}`;

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: taskId,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: auth.currentUser.uid,
              displayName: auth.currentUser.displayName || '',
              photoURL: auth.currentUser.photoURL || ''
            },
            other: {
              taskName,
              projectName
            },
            action: {
              projectKey: projectId
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description: description,
          link: `https://${domaine}/dashboard/task/${projectId}/${taskId}`,
          header: `Vous avez été assigné à une affectation`
        })
      );

      callback && callback();
    } catch (error) {}
  };
}

export const courielToDoNotification = ({ couriel, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'Vous avez de nouvelles courriels à traiter';
      let notifType = NOTIFICATION_TYPES.COURIEL_TO_DO; 
       const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);
      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/task/${couriel?.id}`;
      const description = `Vous avez de nouvelles courriels à traiter ${couriel?.courriel_object} du projet ${couriel?.courriel_reference} par ${thisUser?.displayName}`;

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: couriel?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: auth.currentUser.uid,
              displayName: auth.currentUser.displayName || '',
              photoURL: auth.currentUser.photoURL || ''
            },
            other: {
              taskName: couriel?.courriel_object,
              projectName: couriel?.courriel_reference
            },
            action: {
              projectKey: couriel?.id
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description: description,
          link: `https://${domaine}/dashboard/task/${couriel?.id}`,
          header: `Vous avez de nouvelles courriels à traiter`
        })
      );

      callback && callback();
    } catch (error) {}
  };
}

export const courielToReviewNotification = ({ couriel, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      //console.log({couriel,targetIds})
      let notifTitle = 'Vous avez de nouvelles courriels à valider';
      let notifType = NOTIFICATION_TYPES.COURIEL_TO_REVIEW; 
       const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);
      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/task/${couriel?.id}`;
      const description = `Vous avez de nouvelles courriels à valider ${couriel?.courriel_object} de l'affectation ${couriel?.courriel_reference} par ${thisUser?.displayName}`;

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: couriel?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: auth.currentUser.uid,
              displayName: auth.currentUser.displayName || '',
              photoURL: auth.currentUser.photoURL || ''
            },
            other: {
              taskName: couriel?.courriel_object,
              projectName: couriel?.courriel_reference
            },
            action: {
              projectKey: couriel?.id
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description: description,
          link: `https://${domaine}/dashboard/task/${couriel?.id}`,
          header: `Vous avez de nouvelles courriels à valider`
        })
      );

      callback && callback();
    } catch (error) {
      console.error(error)
    }
  };
}

export const courielCoReceiverNotification = ({ couriel, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'Vous avez de nouvelles courriels en copie';
      let notifType = NOTIFICATION_TYPES.COURIEL_CO_RECEIVER; 
       const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);
      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/task/${couriel?.id}`;
      const description = `Vous avez de nouvelles courriels en copie ${couriel?.courriel_object} de l'affectation ${couriel?.courriel_reference} par ${thisUser?.displayName}`;

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: couriel?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: auth.currentUser.uid,
              displayName: auth.currentUser.displayName || '',
              photoURL: auth.currentUser.photoURL || ''
            },
            other: {
              taskName: couriel?.courriel_object,
              projectName: couriel?.courriel_reference
            },
            action: {
              projectKey: couriel?.id
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description: description,
          link: `https://${domaine}/dashboard/task/${couriel?.id}`,
          header: `Vous avez de nouvelles courriels en copie`
        })
      );

      callback && callback();
    } catch (error) {}
  };
}

export const courielDefaultReceiverNotification = ({ couriel, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'Les Utilisateurs par défaut ont ete modifié';
      let notifType = NOTIFICATION_TYPES.COURIEL_DEFAULT_RECEIVER; 
      const { users } = getState().user;
      
      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);
      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/task/${couriel?.id}`;
      const description = `Les Utilisateurs par défaut ont ete modifié ${couriel?.courriel_object} du projet ${couriel?.courriel_reference} par ${thisUser?.displayName}`;

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: couriel?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: auth.currentUser.uid,
              displayName: auth.currentUser.displayName || '',
              photoURL: auth.currentUser.photoURL || ''
            },
            other: {
              taskName: couriel?.courriel_object,
              projectName: couriel?.courriel_reference
            },
            action: {
              projectKey: couriel?.id
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description: description,
          link: `https://${domaine}/dashboard/task/${couriel?.id}`,
          header: `Les Utilisateurs par défaut ont ete modifié`
        })
      );

      callback && callback();
    }
    catch (error) { 
      console.error(error)
    }
  }
}

export const courielHistoryNotification = ({ couriel, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'Une affectation a été validée';
      let notifType = NOTIFICATION_TYPES.COURIEL_HISTORY;
      const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);
      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/affection/${couriel?.id}`;
      const description = `L'affectation  N° ${couriel?.save_reference}  a été validée par ${thisUser?.displayName}`;

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: couriel?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: auth.currentUser.uid,
              displayName: auth.currentUser.displayName || '',
              photoURL: auth.currentUser.photoURL || ''
            },
            other: {
              taskName: couriel?.courriel_object,
              projectName: couriel?.courriel_reference
            },
            action: {
              projectKey: couriel?.id
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description: description,
          link:accessLink,
          header: notifTitle
        })
      );

      callback && callback();
    }
    catch (error) { 
      console.error(error)
    }
  }
}

export const courielArchiveNotification = ({ couriel, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'Une affectation a été archivée';
      let notifType = NOTIFICATION_TYPES.COURIEL_ARCHIVE;
      const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);
      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/affection/${couriel?.id}`;
      const description = `L'affectation  N° ${couriel?.save_reference}  a été archivée par ${thisUser?.displayName}`;
      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: couriel?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: auth.currentUser.uid,
              displayName: auth.currentUser.displayName || '',
              photoURL: auth.currentUser.photoURL || ''
            },
            other: {
              save_reference: couriel?.save_reference,
            },
            action: {
              projectKey: couriel?.id
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description: description,
          link:accessLink,
          header: notifTitle
        })
      );

      callback && callback();
    }
    catch (error) { 
      console.error(error)
    }
  }
}

export const courielUnArchiveNotification = ({ couriel, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'Une affectation a été désarchivée';
      let notifType = NOTIFICATION_TYPES.COURIEL_UN_ARCHIVE;
      const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);
      const canAccess = users.filter((one) => canAccessIds.includes(one.id));
      //console.log({canAccessIds,canAccess});

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/affection/${couriel?.id}`;
      const description = `L'affectation  N° ${couriel?.save_reference}  a été désarchivée par ${thisUser?.displayName}`;

      //console.log({canAccessIds,canAccess,listIds});

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: couriel?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds, 
            by: {
              id: thisUser?.uid,
              displayName: thisUser?.displayName || '',
              photoURL: thisUser?.photoURL || ''
          },
          other: {
            save_reference: couriel?.save_reference,
          },
          action: {
            projectKey: couriel?.id
          }
        }
      })
    );
    dispatch(
      createMail({
        users: canAccess,
        subject: subject,
        description: description,
        link: accessLink,
        header: notifTitle
      })
    );
    callback && callback();
    }
    catch (error) {
      console.error(error)
    }
  }
}

export const courielTrashNotification = ({ couriel, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'Une affectation a été mis dans la corbeille';
      let notifType = NOTIFICATION_TYPES.COURIEL_TRASH;
      const { users } = getState().user;


      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);
      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/affection/${couriel?.id}`;
      const description = `L'affectation  N° ${couriel?.save_reference}  a été mis dans la corbeille par ${thisUser?.displayName}`;

      //console.log({canAccessIds,canAccess,listIds});

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: couriel?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: auth.currentUser.uid,
              displayName: auth.currentUser.displayName || '',
              photoURL: auth.currentUser.photoURL || ''
            },
            other: {
              courielName: couriel?.courriel_object,
              projectName: couriel?.courriel_reference
            },
            action: {
              projectKey: couriel?.id
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description: description,
          link:accessLink,
          header: notifTitle
        })
      );

      callback && callback();
    }
    catch (error) { 
      console.error(error)
    }
  }
}

export const courielUnTrashNotification = ({ couriel, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      //console.log({couriel,targetIds})
      let notifTitle = 'Des affectations ont été restaurées';
      let notifType = NOTIFICATION_TYPES.COURIEL_UN_TRASH;
      const { users } = getState().user;
      
      let listIds = {};
  
      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);
      const canAccess = users.filter((one) => canAccessIds.includes(one.id));
  
      canAccessIds.forEach((id) => (listIds[id] = true));
  
      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/affection/${couriel?.id}`;
      const description = `L'affectation  N° ${couriel?.save_reference}  a été restaurée par ${thisUser?.displayName}`;
  
      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: couriel?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds, 
            by: {
              id: thisUser?.uid,
              displayName: thisUser?.displayName || '',
              photoURL: thisUser?.photoURL || ''
          },
          other: {
            save_reference: couriel?.save_reference,
          },
          action: {
            projectKey: couriel?.id
          }
        }
      })
    );
    dispatch(
      createMail({
        users: canAccess,
        subject: subject,
        description: description,
        link: accessLink,
        header: notifTitle
      })
    );
    callback && callback();
    }
    catch (error) { 
      console.error(error)
    }
  }
}


export const courielDeleteNotification = ({ couriel, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'Une affectation a été supprimée définitivement';
      let notifType = NOTIFICATION_TYPES.COURIEL_DELETE;
      const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);
      const canAccess = users.filter((one) => canAccessIds.includes(one.id));
      //console.log({canAccessIds,canAccess});

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/affection/${couriel?.id}`;
      const description = `L'affectation  N° ${couriel?.save_reference}  a été supprimée définitivement par ${thisUser?.displayName}`;

      //console.log({canAccessIds,canAccess,listIds});

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: couriel?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds, 
            by: {
              id: thisUser?.uid,
              displayName: thisUser?.displayName || '',
              photoURL: thisUser?.photoURL || ''
            },
            other: {
              save_reference: couriel?.save_reference,
              projectName: couriel?.courriel_reference
            },
            action: {
              projectKey: couriel?.id
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description: description,
          link:accessLink,
          header: notifTitle
        })
      );

      callback && callback();
    }
    catch (error) { 
      console.error(error)
    }
  }
}

export const courielAssignedNotification = ({ couriel, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'Vous avez reçu une assignation';
      let notifType = NOTIFICATION_TYPES.COURIEL_ASSIGNED;
      const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);
      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/affection/${couriel?.id}`;
      const description = `Vous avez reçu une assignation de ${thisUser?.displayName || thisUser?.email} pour l'affectation N° ${couriel?.save_reference}`;

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: couriel?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: thisUser?.uid,
              displayName: thisUser?.displayName || '',
              photoURL: thisUser?.photoURL || ''
            },
            other: {
              taskName: couriel?.courriel_object,
              projectName: couriel?.courriel_reference
            },
            action: {
              projectKey: couriel?.id
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description: description,
          link:accessLink,
          header: notifTitle
        })
      );

      callback && callback();
    }
    catch (error) { 
      console.error(error)
    }
  }
}

export const courielAddAttachmentNotification = ({ couriel, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'De nouvelles pièces jointes ont été ajoutées';
      let notifType = NOTIFICATION_TYPES.COURIEL_ADD_ATTACHMENT;
      const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);
      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      //console.log({canAccessIds,canAccess});

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/affection/${couriel?.id}`;
      const description = `De nouvelles pièces jointes ont été ajoutées à l'affectation N° ${couriel?.save_reference} par ${thisUser?.displayName}`;

      //console.log({canAccessIds,canAccess,listIds});

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: couriel?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: thisUser?.uid,
              displayName: thisUser?.displayName || '',
              photoURL: thisUser?.photoURL || ''
            },
            other: {
              save_reference: couriel?.save_reference,
            },
            action: {
              projectKey: couriel?.id
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description: description,
          link: accessLink,
          header: notifTitle
        })
      );

      callback && callback();
    }
    catch (error) { 
      console.error(error)
    }
  }
}


export const documentSharedNotification = ({ item, targetIds = [], callback }) => {
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'Vous avez été ajouté à un document partagé';
      let notifType = NOTIFICATION_TYPES.DOCUMENT_SHARED;
      const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);

      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      //console.log({canAccessIds,canAccess});

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const description = `Vous avez été ajouté à un document partagé par ${thisUser?.displayName}`;

      //console.log({canAccessIds,canAccess,listIds});

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description,
            createdAt: serverTime(),
            type: notifType,
            returnId: item?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: thisUser?.uid,
              displayName: thisUser?.displayName || '',
              photoURL: thisUser?.photoURL || ''
            },
            other: {
              documentName: item?.name,
              documentReference: item?.parentFolderId
            },
            action: {
              projectKey: item?.id
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description,
          link: `https://${domaine}/doc/share-with-me/${item?.id}`,
          header: notifTitle
        })
      );

      callback && callback();
    }
    catch (error) {
      console.error(error)
    }
  }
}

export const documentSharedUserLeaveNotification = ({ item, targetIds = [], callback }) => { 
  return async (dispatch, getState) => {
    try {
      let notifTitle = 'Un utilisateur a quitté votre document partagé';
      let notifType = NOTIFICATION_TYPES.DOCUMENT_SHARED_USER_LEAVE;
      const { users } = getState().user;

      let listIds = {};

      const resAccessId = targetIds;
      const canAccessIds = uniq([...resAccessId]);

      const canAccess = users.filter((one) => canAccessIds.includes(one.id));

      //console.log({canAccessIds,canAccess});

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const description = `L'utilisateur ${thisUser?.displayName} a quitté votre document partagé ${item?.name}`;

      //console.log({canAccessIds,canAccess,listIds});

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description,
            createdAt: serverTime(),
            type: notifType,
            returnId: item?.id,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: thisUser?.uid,
              displayName: thisUser?.displayName || '',
              photoURL: thisUser?.photoURL || ''
            },
            other: {
              documentName: item?.name,
              documentReference: item?.parentFolderId
            },
            action: {
              projectKey: item?.id
            }
          }
        })
      );
      dispatch(
        createMail({
          users: canAccess,
          subject: subject,
          description,
          link: `https://${domaine}/doc/folders/${item?.id}`,
          header: notifTitle
        })
      );

      callback && callback();
    }
    catch (error) {
      console.error(error)
    }
  }
}