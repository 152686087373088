import { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import useAuth from 'src/hooks/useAuth';
import useRappel from 'src/hooks/useRappel';
import useUserList from 'src/hooks/useUserList';

import {
  Stack,
  Button,
  Typography,
  IconButton,
  Divider,
  Box,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { AffectLineDate } from '../affectation_v4/liste/AffectationLine';
import { Avatar } from 'antd';
import { LoadingButton } from '@mui/lab';
import MenuPopover from 'src/components/MenuPopover';
import ContactsDialog from 'src/components/ContactsDialog';
import InputLine from 'src/components/CustomInputLine';

import { Add } from '@mui/icons-material';

import { DERAULT_RAPPEL_DATA } from 'src/models/rappel';
import { RAPPEL_FREQUENCY_RYTHM, RAPPEL_KIND } from 'src/constants/rappel';

import * as Yup from 'yup';
import createAvatar from 'src/utils/createAvatar';
import { nanoid } from '@reduxjs/toolkit';
import { findEarliestDate, gDate, getRappelLikeDate } from 'src/utils/formatTime';
import { isString } from 'lodash';

export default function RappelForm({ anchor, open, onClose, rappel, initalData, restrictUserListTo }) {
  //console.log('RappelForm', { rappel, initalData });
  const { user } = useAuth();
  const { users } = useUserList();
  const { create, edit } = useRappel({ docId: initalData?.docId ?? null });

  const [type, setType] = useState(rappel?.kind || RAPPEL_KIND.UNIQUE);
  const [loading, setLoading] = useState(false);
  const isEdit = useMemo(() => rappel && rappel?.id, [rappel]);
  const isMine = useMemo(() => user.id === rappel?.createdById, [rappel?.createdById, user.id]);
  const disableField = useMemo(() => isEdit && !isMine, [isEdit, isMine]);

  const initialValues = {
    ...DERAULT_RAPPEL_DATA,
    kind: RAPPEL_KIND.UNIQUE,
    users: [],
    endDate: null,
    frequencyCount: 1,
    frequencyRythm: RAPPEL_FREQUENCY_RYTHM.DAY,
    additionalReminders: [],
    sendEmailNotification: false,
    ...initalData,
    ...rappel
  };

  const validationSchema = useMemo(() => {
    let store = {
      title: Yup.string().required('Le title est requis'),
      description: Yup.string().required('La description est requise'),
      initialDate: Yup.date().nullable().required('Ce champs est requis')
    };

    if (type == RAPPEL_KIND.LOOP) {
      store = {
        ...store,
        frequencyCount: Yup.number().required('Ce champs est requis')
      };
    }
    return Yup.object().shape(store);
  }, [type]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values, { setErrors, setFieldError, setSubmitting, resetForm }) => {
      setLoading(true);

      const {
        endDate,
        users: usersMetas,
        additionalReminders = [],
        frequencyCount,
        frequencyRythm,
        kind,
        users,
        ...rest
      } = values;

      const getRunAt = () => {
        if (values.kind == RAPPEL_KIND.MULTIPLE) {
          return findEarliestDate([values.initialDate, ...additionalReminders?.map((el) => el?.date)], true);
        }

        return values.initialDate;
      };

      const getTargeId = () => {
        const ids = [...users?.map((el) => el?.id), user?.id]?.filter((el) => isString(el));
        const target = users?.filter((el) => ids.includes(el?.id));
        const userEmails = target?.map((el) => el?.email)?.filter((el) => isString(el));
        const mobile = target?.map((el) => el?.mobileToken)?.filter((el) => isString(el));
        const web = target?.map((el) => el?.token)?.filter((el) => isString(el));
        return {
          userIds: ids,
          userEmails,
          fcm: {
            mobile,
            web
          }
        };
      };

      const isRecursive = kind == RAPPEL_KIND.LOOP;
      const data = {
        ...rest,
        kind,
        runAt: getRappelLikeDate(getRunAt()),
        isRecursive,
        recurrence: isRecursive
          ? {
              frequency: frequencyRythm,
              interval: frequencyCount,
              startDate: values.initialDate,
              endDate
            }
          : null,
        status: 'nonLu',
        additionalReminders: additionalReminders
          ?.map((el) => ({ ...el, date: gDate(el?.date) }))
          ?.filter((el) => el?.date),
        ...getTargeId()
      };

      const callback = () => {
        setLoading(false);
        onClose();
      };

      if (!isEdit) {
        create(data, callback);
      } else {
        edit(data, callback);
      }
    }
  });

  const { values, errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  useEffect(() => {
    setFieldValue(
      'users',
      users?.filter((el) => values?.userIds?.includes(el?.id))
    );
    setFieldValue('initialDate', gDate(values.initialDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getErrorProps = (field) => {
    return {
      error: Boolean(touched[field] && errors[field]),
      helperText: touched[field] && errors[field],
      ...getFieldProps(field),
      onChange: (_, val) => {
        setFieldValue(field, val);

        if (field == 'kind') {
          //TODO: take out the field for addtional dates and recursivity
          setType(val);
          setFieldValue('frequencyCount', 1);
          setFieldValue('frequencyRythm', RAPPEL_FREQUENCY_RYTHM.DAY);
          setFieldValue('additionalReminders', []);
        }
      }
    };
  };

  const handleAddDate = () => {
    setFieldValue('additionalReminders', [
      ...values.additionalReminders.filter((el) => el?.date),
      { id: nanoid(), status: 'actif', date: null }
    ]);
  };

  const handleEditAdditionalDate = (id, date) => {
    setFieldValue(
      'additionalReminders',
      [...values.additionalReminders]
        .map((el) => {
          if (el?.id === id) return { ...el, date: getRappelLikeDate(date) };
          return el;
        })
        .filter((el) => el?.date)
    );
  };

  return (
    <>
      <MenuPopover disabledArrow width={'fit-content'} open={open} onClose={onClose} anchorEl={anchor?.current}>
        <Stack width={300} p={2} spacing={2}>
          <Typography>{isEdit ? 'Editer un Rappel' : 'Créer un Rappel'}</Typography>
          <Stack width={1} spacing={2}>
            <InputLine disabled={disableField} type="text" label="Titre" {...getErrorProps('title')} />
            <InputLine disabled={disableField} type="text" label="Description" {...getErrorProps('description')} />

            <Divider />

            <InputLine
              disabled={disableField}
              type="select"
              options={[RAPPEL_KIND.UNIQUE, RAPPEL_KIND.MULTIPLE, RAPPEL_KIND.LOOP]}
              label="Type de rappel"
              {...getErrorProps('kind')}
            />

            <Stack width={1} spacing={0.5}>
              <Typography fontSize={11}>
                {values.kind == RAPPEL_KIND.UNIQUE && 'Date de rappel'}
                {values.kind == RAPPEL_KIND.MULTIPLE && 'Dates de rappel'}
                {values.kind == RAPPEL_KIND.LOOP && 'Date de début'}
              </Typography>
              <AffectLineDate
                disabled={disableField}
                vue="input"
                {...getErrorProps('initialDate')}
                date={values.initialDate}
                onChange={(value) => {
                  setFieldValue('initialDate', value);
                }}
              />
            </Stack>

            {values.kind == RAPPEL_KIND.MULTIPLE && (
              <Stack spacing={2}>
                {values?.additionalReminders?.map((el) => (
                  <Stack width={1} spacing={0.5} key={el?.id}>
                    <AffectLineDate
                      disabled={disableField}
                      vue="input"
                      date={el?.date}
                      onChange={(value) => {
                        handleEditAdditionalDate(el?.id, value);
                      }}
                    />
                  </Stack>
                ))}

                {!disableField && (
                  <Stack direction={'row'}>
                    <Button
                      size="small"
                      variant={'text'}
                      color={'inherit'}
                      onClick={handleAddDate}
                      startIcon={<Add />}
                      disabled={disableField}
                    >
                      Ajouter une date
                    </Button>
                  </Stack>
                )}
              </Stack>
            )}

            {values.kind == RAPPEL_KIND.LOOP && (
              <Stack width={1} spacing={0.5}>
                <Typography fontSize={11}>Date de Fin</Typography>
                <AffectLineDate
                  disabled={disableField}
                  vue="input"
                  {...getErrorProps('endDate')}
                  date={values.endDate}
                  onChange={(value) => {
                    setFieldValue('endDate', value);
                  }}
                />
              </Stack>
            )}

            {values.kind == RAPPEL_KIND.LOOP && (
              <Stack width={1} spacing={0.5}>
                <Typography fontSize={11}>Fréquence de répetition</Typography>

                <Stack direction={'row'} width={1} spacing={2}>
                  <Stack direction={'row'} width={'50%'}>
                    <InputLine disabled={disableField} type="number" label="" {...getErrorProps('frequencyCount')} />
                  </Stack>

                  <InputLine
                    disabled={disableField}
                    type="select"
                    options={[RAPPEL_FREQUENCY_RYTHM.DAY, RAPPEL_FREQUENCY_RYTHM.WEEK, RAPPEL_FREQUENCY_RYTHM.MONTH]}
                    label=""
                    {...getErrorProps('frequencyRythm')}
                  />
                </Stack>
              </Stack>
            )}
            <Stack spacing={0.5}>
              <Divider />
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disableField}
                      checked={values.sendEmailNotification}
                      size="small"
                      onChange={(_, val) => {
                        setFieldValue('sendEmailNotification', val);
                      }}
                    />
                  }
                  label={<Typography fontSize={11}>Recevoir des emails pour ce rappel</Typography>}
                />
              </FormGroup>

              <Divider />
            </Stack>

            <Stack width={1} spacing={0.5}>
              <Typography fontSize={12}>Collaborateurs</Typography>
              <Share
                disabled={disableField}
                restrictUserListTo={restrictUserListTo}
                value={values.users}
                onChange={(val) => setFieldValue('users', val)}
              />
            </Stack>
          </Stack>

          {!isEdit || isMine ? (
            <Stack direction={'row'} spacing={2} justifyContent={'end'}>
              <Button variant={'outlined'} size="small" onClick={onClose}>
                Annuler
              </Button>
              <LoadingButton loading={loading} variant={'contained'} size="small" onClick={handleSubmit}>
                Enregistrer
              </LoadingButton>
            </Stack>
          ) : (
            <Stack direction={'row'} spacing={2} justifyContent={'end'}>
              <Button variant={'outlined'} size="small" onClick={onClose}>
                Fermer
              </Button>
            </Stack>
          )}
        </Stack>
      </MenuPopover>
    </>
  );
}

const Share = ({ restrictUserListTo, value = [], onChange, disabled = false }) => {
  return (
    <>
      <Stack direction={'row'} spacing={0.5}>
        <Avatar.Group maxCount={4} size="small">
          {value?.length > 0 &&
            value.map((_one, idx) => {
              return (
                <Tooltip key={_one?.id + idx} title={_one?.displayName || _one?.name} arrow>
                  <Avatar
                    src={_one?.avatar || _one?.photoURL || _one?.photoUrl || null}
                    alt={_one?.displayName || _one?.name}
                    size={35}
                    style={{
                      backgroundColor: createAvatar(_one?.displayName || _one?.name).color2,
                      borderRadius: 10,
                      marginRight: 8
                    }}
                  >
                    {createAvatar(_one?.displayName || _one?.name).name}
                  </Avatar>
                </Tooltip>
              );
            })}
        </Avatar.Group>

        {!disabled && (
          <ContactsDialog
            assigne={value}
            onAssigne={onChange}
            CustomList={restrictUserListTo}
            action={(popoverRef, onClick) => (
              <Box ref={popoverRef} onClick={onClick} disabled={disabled}>
                <IconButton
                  variant={'outlined'}
                  size="small"
                  sx={{
                    borderRadius: 1,
                    bgcolor: 'white',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: (t) => t.palette.divider
                  }}
                >
                  <Add />
                </IconButton>
              </Box>
            )}
          />
        )}
      </Stack>
    </>
  );
};
