import { createSlice, nanoid } from '@reduxjs/toolkit';
import { firestore, auth } from 'src/contexts/FirebaseContext';
import { hasAccountExpired } from 'src/contexts/MicrosoftMailContext';
import axios from 'axios';
import { MAIL_FLAG, MAIL_FOLDER } from 'src/section/mail/MailContext';
import { keys, uniq, isFunction, isEmpty } from 'lodash';
import { CLOUD_MAIL_API_KEY } from 'src/config';
import Timer from 'src/helpers/timer';
import Mail from 'src/section/mail_v2/models/Mail';
import { refreshLinkedAccount } from 'src/redux/slices/microsoftMail';
import axiosRequest from 'src/utils/axiosRequest';
import { MAIL_TIMER } from 'src/helpers/mailTimer';
import { MAIL_PLATFORM } from 'src/constants/mail';
import { formatSignatureContent } from 'src/helpers/mailSignatureImageExtrator';

const initialState = {
  accounts: {},
  folders: {},
  mails: {},
  loading: [],

  pagination: {
    total: 0,
    per_page: 50
  },
  mailByUID: {},

  refresher: {
    callback: null,
    lastUpdated: null,
    count: 0
  },
  notify: (mail) => {}
};

const slice = createSlice({
  name: 'custom-mail',
  initialState,
  reducers: {
    loadingByUID: (state, action) => {
      const { folder, messageUID, loading, mail } = action.payload;

      if (state.mailByUID[folder]) {
        state.mailByUID[folder][messageUID] = { loading, mail };
      } else {
        state.mailByUID[folder] = { [messageUID]: { loading, mail } };
      }
    },

    updatePagination: (state, action) => {
      const { folder, totals, mailLoaded } = action.payload;
      state.pagination[folder] = mailLoaded;
      state.pagination.total = totals;
    },

    setNotifyCallback: (state, action) => {
      state.notify = action.payload;
    },
    setAccounts: (state, action) => {
      state.accounts = {
        ...state.accounts,
        ...action.payload
      };
    },
    setFolders: (state, action) => {
      state.folders = {
        ...state.folders,
        ...action.payload
      };
    },
    setMailsInFolders: (state, action) => {
      const email = action.payload.email;
      const folder = action.payload.folder;
      const mails = action.payload.mails;
      state.mails = {
        ...(state.mails || {}),
        [email]: {
          ...(state.mails[email] || {}),
          [folder]: {
            ...((state.mails[email] || {})[folder] || {}),
            ...mails
          }
        }
      };
    },
    removeMailsInFolder: (state, action) => {
      const copy = { ...state.mails };
      const email = action.payload.email;
      const folder = action.payload.folder;
      const mailId = action.payload.mailId;

      delete ((copy[email] || {})[folder] || {})[mailId];

      state.mails = copy;
    },

    updateMailFolder: (state, action) => {
      const copy = { ...state.mails };
      const email = action.payload.email;
      const folder = action.payload.folder;
      const mailId = action.payload.mailId;
      const nextFolder = action.payload.nextFolder;

      const mail = ((copy[email] || {})[folder] || {})[mailId];

      console.log(
        'updateMailFolder',
        mail,
        'copy',
        copy,
        'email',
        email,
        'folder',
        folder,
        'mailId',
        mailId,
        'nextFolder',
        nextFolder
      );

      if (!mail) return;

      delete ((copy[email] || {})[folder] || {})[mailId];

      // push to next folder and update folder property
      //uodate folder property
      mail.folder = nextFolder;

      copy[email][nextFolder] = {
        ...copy[email][nextFolder],
        [mailId]: mail
      };

      state.mails = copy;
    },

    setDrafts: (state, action) => {
      const email = action.payload.email;
      const drafts = action.payload.drafts;
      state.mails = {
        ...(state.mails || {}),
        [email]: {
          ...(state.mails[email] || {}),
          [MAIL_FOLDER.DRAFTS]: {
            ...((state.mails[email] || {})[MAIL_FOLDER.DRAFTS] || {}),
            ...drafts
          }
        }
      };
    },

    deleteDraft: (state, action) => {
      const email = action.payload.email;
      const draftId = action.payload.draftId;
      const copy = { ...state.mails };

      delete ((copy[email] || {})[MAIL_FOLDER.DRAFTS] || {})[draftId];

      state.mails = copy;
    },

    updateDraft: (state, action) => {
      const email = action.payload.email;
      const draftId = action.payload.draftId;
      const values = action.payload.values;

      const copy = { ...state.mails };

      const draft = ((copy[email] || {})[MAIL_FOLDER.DRAFTS] || {})[draftId];

      if (!draft) return;

      Object.assign(draft, { ...values });

      state.mails = copy;
    },

    updateMailFlag: (state, action) => {
      const copy = { ...state.mails };
      const email = action.payload.email;
      const oldFlag = action.payload.oldFlag;
      const folder = action.payload.folder;
      const mailId = action.payload.mailId;
      const flag = action.payload.flag;

      //email.flags= ['unseen', 'flagged', 'answered', 'draft', 'recent']

      const mail = ((copy[email] || {})[folder] || {})[mailId];

      if (!mail) return;

      // add flag to mail.flags but if it already exist remove it
      if (mail.flags.includes(flag)) {
        mail.flags = mail.flags.filter((el) => el !== flag);
      } else if (flag.length === 0 && oldFlag.length !== 0) {
        mail.flags = mail.flags.filter((el) => el !== oldFlag);
      } else if (flag.length !== 0 && oldFlag.length === 0) {
        mail.flags = [...mail.flags, flag];
      } else {
        mail.flags = [...mail.flags, flag];
      }

      state.mails = copy;
    },

    updateMailsInFolder: (state, action) => {
      const copy = { ...state.mails };
      const email = action.payload.email;
      const folder = action.payload.folder;
      const mailId = action.payload.mailId;
      const values = action.payload.values;

      const mail = ((copy[email] || {})[folder] || {})[mailId];

      if (!mail) return;

      Object.assign(mail, { ...values });

      state.mails = copy;
    },
    setLoading: (state, action) => {
      state.loading = uniq([...state.loading, ...action.payload]);
    },
    revokeLoading: (state, action) => {
      state.loading = state.loading?.filter((key) => !action.payload.includes(key));
    },
    removeAccount: (state, action) => {
      delete state.accounts[action.payload];
      delete state.folders[action.payload];
      delete state.mails[action.payload];
    },
    reduceUnreadMailCount: (state, action) => {
      const email = action.payload;
      const cursor = (state.folders[email] || {})[MAIL_FOLDER.INBOX];
      const prev = cursor?.unreadItemCount || 0;
      const unreadItemCount = prev - 1 < 0 ? 0 : prev - 1;
      const result = {
        ...state.folders,
        [email]: {
          ...state.folders[email],
          [MAIL_FOLDER.INBOX]: {
            ...cursor,
            unreadItemCount
          }
        }
      };

      state.folders = result;
    },
    updateMailRefresher: (state, action) => {
      if (isFunction(state.refresher.callback)) {
        state.refresher.callback();
      }

      state.refresher = {
        callback: action.payload,
        lastUpdated: new Date(),
        count: state.refresher.count + 1
      };
    }
  }
});

export const {
  setAccounts,
  setFolders,
  setMailsInFolders,
  setLoading,
  revokeLoading,
  removeMailsInFolder,
  updateMailsInFolder,
  removeAccount,
  reduceUnreadMailCount,
  updateMailRefresher,
  setNotifyCallback,
  updateMailFolder,
  setDrafts,
  deleteDraft,
  updateDraft,
  updateMailFlag
} = slice.actions;

const customMailReducer = slice.reducer;

export default customMailReducer;

export const authCustomMail = (data, onResolve, onReject) => {
  return async (dispacth) => {
    try {
      const snap = await axios.post(`${CLOUD_MAIL_API_KEY}/webmail/signin?platform=custom`, data);

      const result = {
        [data?.user?.email]: {
          ...snap.data,
          email: data?.user?.email
        }
      };
      console.log({ result });
      dispacth(igniteCustomAccount({ accountSnap: result }));

      onResolve && onResolve(snap.data);
    } catch (e) {
      onReject && onReject(e?.error);
    }
  };
};
export const persistToken = ({ email, result, userId }) => {
  return async (dispatch, getStore) => {
    try {
      const accounts = getStore()?.microsoftMail?.accounts || {};
      const isExpired = hasAccountExpired(accounts[email]);
      // console.log("accounts", accounts)
      // console.log("isExpired", isExpired)

      if (isExpired) {
        await firestore.runTransaction(async (transaction) => {
          const docRef = firestore.collection('users').doc(userId);
          const snap = await transaction.get(docRef);
          const linkedAccount = {
            ...(snap?.data()?.linkedAccount || {}),
            ...result
          };
          // console.log('result save', linkedAccount)
          transaction.update(docRef, { linkedAccount });
          dispatch(setAccounts(result));
          dispatch(
            getAccountFolders({
              account: result[email],
              onResolve: () => {
                const account = result[email];
                console.log({ account });
                dispatch(getMails({ account, folderType: MAIL_FOLDER.INBOX }));
              }
            })
          );
        });
      }
    } catch (error) {}
  };
};

export const igniteCustomAccount = ({ accountSnap }) => {
  return (dispatch, getStore) => {
    try {
      const snap = getStore()?.customMail?.accounts || {};

      dispatch(setAccounts(accountSnap));

      keys(accountSnap).forEach((email) => {
        if (snap[email]) return;

        const account = accountSnap[email];
        dispatch(
          refreshToken({
            account,
            callback: (account) => {
              dispatch(
                getAccountFolders({
                  account,
                  onResolve: () => {
                    setTimeout(() => {
                      dispatch(getMails({ account, folderType: MAIL_FOLDER.INBOX }));
                    }, [2000]);
                  }
                })
              );
            }
          })
        );
      });
    } catch (error) {}
  };
};

export const updateAccountData = ({ email, data, userId }) => {
  return async (dispatch) => {
    try {
      await axios.patch(
        `${CLOUD_MAIL_API_KEY}/account`,
        {
          ...data
        },
        {
          headers: {
            userid: auth.currentUser.uid
          }
        }
      );

      const account = { [email]: data };

      dispatch(setAccounts(account));
      dispatch(refreshLinkedAccount({}));
    } catch (e) {}
  };
};

export const updateCustomMailsOnFolder =
  ({ account = {}, messageUID, mailUID, currentFolder, nextFolder, callback, onError }) =>
  async (dispatch) => {
    try {
      const { token, user } = account;

      if (!token) return account;

      await axiosRequest.put('mail/webmail/update', {
        messageUID: mailUID,
        currentFolder,
        nextFolder,
        token,
        platform: 'custom'
      });
      if (callback) callback();
    } catch (error) {
      onError && onError();
    }
  };

export const updateCustomMailFolder = ({
  account = {},
  messageUID,
  mailUID,
  currentFolder,
  nextFolder,
  callback,
  onError
}) => {
  return async (dispatch) => {
    try {
      const { token, user } = account;

      if (!token) return account;

      dispatch(
        updateMailFolder({
          email: user?.email,
          folder: currentFolder,
          mailId: messageUID,
          nextFolder
        })
      );

      await axiosRequest.put('mail/webmail/update', {
        messageUID: mailUID,
        currentFolder,
        nextFolder,
        token,
        platform: 'custom'
      });

      if (callback) callback();
    } catch (error) {
      onError && onError();
      const { token, user } = account;
      dispatch(
        updateMailFolder({
          email: user?.email,
          folder: nextFolder,
          mailId: messageUID,
          nextFolder: currentFolder
        })
      );
    }
  };
};

//TODO: updateCustomMailsOnFolder on local

const generatId = () => {
  //minimum 30 with nanoid
  const id = nanoid(30);
  return id;
};

const buildMessage = async ({ account, email }) => {
  const { token, user } = account;
  // change the to from ['email1', 'email2'] to [{email: 'email1', name: 'name1'}, {email: 'email2', name: 'name2'}
  const to =
    email?.to?.map((el) => {
      return { email: el, name: el };
    }) || [];
  const cc =
    email?.cc?.map((el) => {
      return { email: el, name: el };
    }) || [];

  const cci =
    email?.cci?.map((el) => {
      return { email: el, name: el };
    }) || [];
  const convertFileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const transformFileToBase64 = async (file) => {
    const base64 = await convertFileToBase64(file);
    const contentBytes = base64.split(',')[1];
    return contentBytes;
  };

  const formatFiles = async (files) => {
    const promises = files.map(async (file) => {
      //si le fichier n'est pas un blob on retourne un vide
      if (!(file instanceof Blob)) {
        return;
      }
      return {
        filename: file.name,
        content: await transformFileToBase64(file),
        contentType: file.type
      };
    });
    return Promise.all(promises);
  };

  const formatedFiles = await formatFiles(email?.files || []);

  //TODO : adapter le model de mail
  const message = {
    //date: gDate(),
    //from: { email: user?.email, name: user?.displayName },
    from: { email: user?.email, name: user?.displayName },
    //to: { email: email?.to, name: email?.to },
    to: to,
    id: generatId(),
    //cc: { email: email?.cc, name: email?.cc },
    cc: cc,
    cci: cci,
    subject: email?.subject,
    customFlag: 'MonDrapeauPersonnalise',
    text: email?.body || "Corps de l'e-mail au format texte.\nCela peut être un message multiligne.",
    textAsHtml: `<p>${email?.body || "Corps de l'e-mail au format texte.\nCela peut être un message multiligne"}</p>`,
    attachments: formatedFiles
  };

  const formatedMail = new Mail({});

  return Promise.resolve(message);
};

export const saveToDraft = ({ account = {}, email, onResolve, onReject }) => {
  return async (dispatch) => {
    try {
      const { token, user } = account;

      const message = await buildMessage({ account, email });

      if (!token) return account;

      // dispatch(
      //   setDrafts({
      //     email: user?.email,
      //     drafts: {
      //       [message?.id]: {
      //         ...message
      //       }
      //     }
      //   })
      // );

      await axiosRequest.post('mail/webmail/draft', { message, token, platform: 'custom', account: account });
      if (onResolve) onResolve();
    } catch (error) {
      if (onReject) onReject();
    }
  };
};

const fomatComposerDataToMail = (data) => {
  const { to, cc, cci, subject, body, files, createdAt, id } = data;

  const mail = {
    to:
      to?.map((el) => {
        return { name: el, email: el };
      }) || [],
    cc:
      cc?.map((el) => {
        return { name: el, email: el };
      }) || [],
    cci:
      cci?.map((el) => {
        return { name: el, email: el };
      }) || [],
    subject,
    body,
    files,
    createdAt,
    id
  };

  return mail;
};

{
  /* </k58R4sih29C9YTS-HrCZXHeU8hIBWA>
  << k58R4sih29C9YTS - HrCZXHeU8hIBWA >> */
}

const formatIdToSent = (id) => {
  // chnage <k58R4sih29C9YTS-HrCZXHeU8hIBWA> to k58R4sih29C9YTS-HrCZXHeU8hIBWA
  const result = id.replace('<', '').replace('>', '');
  return result;
};

export const updateDraftMail = ({ account = {}, email, onResolve, onReject }) => {
  return async (dispatch) => {
    try {
      const { token, user } = account;

      const _message = await buildMessage({ account, email });

      const message = { ..._message, id: formatIdToSent(email?.id) };

      if (!token) return account;

      dispatch(
        updateDraft({
          email: user?.email,
          draftId: email?.id,
          values: {
            ...fomatComposerDataToMail(email)
          }
        })
      );

      await axiosRequest.post('mail/webmail/draft', { message, token, platform: 'custom', account: account });
      if (onResolve) onResolve();
    } catch (error) {
      if (onReject) onReject();
    }
  };
};

export const deleteDraftMail = ({ account = {}, email, onResolve, onReject }) => {
  return async (dispatch) => {
    try {
      const { token, user } = account;

      const message = buildMessage({ account, email });

      if (!token) return account;

      dispatch(
        deleteDraft({
          email: user?.email,
          draftId: email?.id
        })
      );

      //await axiosRequest.post('mail/webmail/draft', { message, token, platform: 'custom' });
      if (onResolve) onResolve();
    } catch (error) {
      if (onReject) onReject();
    }
  };
};

const formatFlagForSent = (flag) => {
  // remove \ from flag
  const result = flag.replace('\\', '');
  return result;
};

export const updateFlag = ({
  account = {},
  mailId,
  mailUID,
  currentFlag,
  currentFolder,
  nextFlag,
  onResolve,
  onReject
}) => {
  return async (dispatch) => {
    try {
      const { token, user } = account;

      if (!token) return account;
      dispatch(
        updateMailFlag({
          email: account?.user?.email,
          folder: currentFolder,
          mailId: mailId,
          flag: nextFlag,
          oldFlag: currentFlag
        })
      );

      //
      const params = {
        messageUID: mailUID,
        token,
        currentFlag: formatFlagForSent(currentFlag),
        nextFlag: formatFlagForSent(nextFlag),
        platform: MAIL_PLATFORM.CUSTOM
      };

      // if (currentFlag.length !== 0) {
      //   params.currentFlag = currentFlag;

      // }
      // if (nextFlag.length === 0 && currentFlag.length !== 0) {
      //   params.nextFlag = currentFlag;
      // }
      // else if (nextFlag.length !== 0 && params.currentFlag !== nextFlag) {
      //   params.nextFlag = nextFlag;
      // }

      // if (params.currentFlag === params.nextFlag) delete params.currentFlag;

      axiosRequest.put('/mail/webmail/tag/update', params);

      if (onResolve) onResolve();
    } catch (error) {
      onReject && onReject();

      dispatch(
        updateMailFlag({
          email: account?.user?.email,
          folder: currentFolder,
          mailId: mailId,
          flag: nextFlag,
          oldFlag: currentFlag
        })
      );
    }
  };
};

export const sendMsMail = ({ account = {}, email, onResolve, onReject }) => {
  return async (dispatch, getStore) => {
    try {
      const files = [...(email?.files || [])];
      const formated = [];
      //inReplyTo: string (ID- du mail auquel on répond)

      //console.log({ email, account });

      const inReplyTo = account?.userId;

      for (const file of files) {
        const contentBytes = await transformFileToBase64(file);

        formated.push({
          filename: file?.name,
          content: contentBytes,
          encoding: 'base64'
        });
      }

      let result = { ...email, files: formated, platform: 'custom', token: account?.token };

      // if is not reply remove replyTo but if is reply add replyTo and  inReplyTo
      if (email?.replyTo?.length > 0) {
        result = {
          ...result,
          replyTo: email?.replyTo,
          inReplyTo
        };
      } else {
        delete result.replyTo;
      }

      const formatMailToSent = (result) => {
        const message = {
          from: { email: account?.user?.email, name: account?.user?.displayName },
          to: [{ email: email?.to, name: email?.to }],
          id: generatId(),
          cc: result?.cci,
          cci: result?.cc,
          subject: result?.subject,
          customFlag: 'MonDrapeauPersonnalise',
          text: result?.body,
          textAsHtml: `<p>${result?.body || ''}</p>`,
          attachments: [],
          body: result?.body,
          sentDate: new Date(),
          account: account
        };

        return message;
      };

      await axios.post(`${CLOUD_MAIL_API_KEY}/webmail/message/send`, result);
      //const mail = await buildMessage({ account, email });
      dispatch(
        setMailsInFolders({
          account: account,
          mails: [formatMailToSent(result)],
          folder: MAIL_FOLDER.SENT
        })
      );

      console.log({ result, account, email, formatMailToSent: formatMailToSent(result) });
      onResolve && onResolve();
    } catch (error) {
      onReject && onReject();
      console.log(error);
    }
  };
};

export const getAccountFolders = ({ account, onResolve, onReject }) => {
  return async (disptach) => {
    const utils = new LoadingUtil(disptach, `getAccountFolders-${account?.user?.email} `);

    try {
      disptach(
        refreshToken({
          account,
          callback: async (account) => {
            const { token, user } = account;

            if (!token) return account;
            utils.start();

            const snap = await axios.post(`${CLOUD_MAIL_API_KEY}/webmail/folders`, {
              token,
              platform: 'custom'
            });

            const value = snap.data;

            let data = {};

            const check = (list = [], key = '') => {
              return list.map((el) => el.toLowerCase().trim()).includes(key.toLowerCase().trim());
            };

            keys(value).forEach((key) => {
              const folder = {
                ...value[key],
                id: key
              };

              if (check(['inbox', 'Boîte de réception'], key)) {
                data = {
                  ...data,
                  [MAIL_FOLDER.INBOX]: folder
                };
              }
              if (check(['Sent', 'Outbox', 'Boîte de réception', 'Sent Items', 'Sent Messages', 'Sent Mail'], key)) {
                data = {
                  ...data,
                  [MAIL_FOLDER.SENT]: folder
                };
              }
              if (check(['Deleted Items', 'Trash', 'Bin', 'Recycle Bin'], key)) {
                data = {
                  ...data,
                  [MAIL_FOLDER.TRASH]: folder
                };
              }
              if (check(['Junk', 'Spam', 'Bulk Mail', 'Junk E-mail'], key)) {
                data = {
                  ...data,
                  [MAIL_FOLDER.SPAM]: folder
                };
              }
            });

            // console.group("getAccountFolders")
            // console.log("orgin", value)
            // console.log("folders", data)
            // console.groupEnd()

            disptach(
              setFolders({
                [user?.email]: data
              })
            );

            // console.log("snap", snap.data)
            // console.log("data", data)
            utils.end();
            onResolve && onResolve(data);
          }
        })
      );
    } catch (error) {
      onReject && onReject();
      utils.end();
    }
  };
};

export const getMailByUId = ({ account = {}, folderType, messageUID, onReject, onResolve }) => {
  return async (dispatch, getStore) => {
    try {
      const { token, user } = account;

      if (!token) return account;

      const { customMail } = getStore();

      const folders = customMail?.folders || {};
      const folderId = folders[account?.user?.email][folderType]?.id;

      const getApiFolderType = (folderType) => {
        if (folderType === MAIL_FOLDER.ARCHIVE) return MAIL_FOLDER.INBOX;
        return folderType;
      };

      const currentFolder = getApiFolderType(folderType) || folderId;

      dispatch(slice.actions.loadingByUID({ folder: currentFolder, messageUID, loading: true, mail: null }));

      const { data } = await axios.post(`${CLOUD_MAIL_API_KEY}/webmail/message/uid`, {
        token,
        platform: 'custom',
        folder: currentFolder,
        messageUid: messageUID
      });

      dispatch(slice.actions.loadingByUID({ folder: currentFolder, messageUID, loading: false, mail: data }));
    } catch (error) {
      console.error(error);
      if (onReject) onReject(error);
    }
  };
};

export const getMails = ({ account = {}, folderType, refreshDate = null, onResolve, onReject }) => {
  return async (dispatch, getStore) => {
    const utils = new LoadingUtil(dispatch, `getMails-${account?.user?.email}-${folderType}`);

    try {
      const { token, user } = account;

      if (!token) return account;

      utils.start();

      const { customMail, microsoftMail } = getStore();

      const notify = microsoftMail?.notify;
      const mails = customMail?.mails[account?.user?.email] || {};
      const folders = customMail?.folders || {};
      const folderId = folders[account?.user?.email][folderType]?.id;

      const { pagination } = customMail;

      const getApiFolderType = (folderType) => {
        if (folderType === MAIL_FOLDER.ARCHIVE) return MAIL_FOLDER.INBOX;
        return folderType;
      };

      const currentFolder = getApiFolderType(folderType) || folderId;

      let snap = null;

      if (!refreshDate) {
        const { data } = await axios.post(`${CLOUD_MAIL_API_KEY}/webmail/message`, {
          token,
          platform: 'custom',
          folder: currentFolder,
          page: 1,
          pageSize: pagination?.per_page || 70
        });

        snap = data;
      } else {
        const { data } = await axios.post(`${CLOUD_MAIL_API_KEY}/webmail/message/new`, {
          token,
          platform: 'custom',
          date: refreshDate,
          folder: currentFolder,
          page: 1,
          pageSize: pagination?.per_page || 70
        });
        snap = data;
      }

      const messages = [...(snap?.data || [])];
      const totals = snap?.total || 0;

      //update mail pagination
      dispatch(slice.actions.updatePagination({ folder: currentFolder, totals, mailLoaded: messages.length }));

      const res = [];

      for (const message of messages) {
        const messageFiles = message?.hasAttachments ? message?.orginalMail?.attachments : [];
        const files = [];

        messageFiles?.forEach((el) => {
          const attachmentData = new Uint8Array(el?.content?.data);
          const blob = new Blob([attachmentData], { type: el?.contentType });
          const file = new File([blob], el?.filename, { type: el?.contentType });
          files.push(file);
        });
        res.push({ ...message, files });
      }

      const result = {
        folder: folderType,
        email: account?.user?.email,
        mails: listToObject(res)
      };

      dispatch(setMailsInFolders(result));
      onResolve && onResolve();
      utils.end();

      if (MAIL_FOLDER.INBOX === folderType && notify) {
        const newMail = [];

        const prev = mails[MAIL_FOLDER.INBOX];
        const current = result.mails;

        keys(current).map((id) => {
          if (prev && !prev[id]) {
            newMail.push(current[id]);
          }
        });

        newMail.forEach((mail) => {
          notify({ ...mail, account });
        });
      }
    } catch (error) {
      onReject && onReject();
      console.log(error);
      utils.end();
    }
  };
};

export const toggleMsMailImportance = ({ account, folderType, mailId, isImportant, onResolve, onReject }) => {
  return async (dispatch) => {
    try {
      dispatch(
        refreshToken({
          account,
          callback: async (account) => {
            const { token } = account;

            if (!token) return account;

            const snap = await axios.patch(
              `https://graph.microsoft.com/v1.0/me/messages/${mailId}`,
              {
                importance: isImportant ? 'high' : 'normal'
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-type': 'application/json'
                }
              }
            );

            dispatch(
              updateMailFlag({
                email: account?.user?.email,
                folder: folderType,
                mailId,
                flag: MAIL_FLAG.IMPORTANT,
                oldFlag: MAIL_FLAG.IMPORTANT
              })
            );

            //dispatch(getMails({ account, folderType }));

            onResolve && onResolve();
          }
        })
      );
    } catch (error) {
      onReject && onReject();
    }
  };
};

export const getMsMailAttachment = ({ account, folderType, mailId, onResolve, onReject }) => {
  return async (dispatch) => {
    try {
      dispatch(
        refreshToken({
          account,
          callback: async (account) => {
            const { token } = account;

            if (!token) return account;

            const snap = await axios.get(`https://graph.microsoft.com/v1.0/me/messages/${mailId}/attachments`, {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-type': 'application/json'
              }
            });

            const result = (snap.data.value || [])?.map((file) => {
              const raw = window.atob(file.contentBytes);
              const rawLength = raw.length;
              let array = new Uint8Array(new ArrayBuffer(rawLength)); // pass your byte response to this constructor

              for (let i = 0; i < rawLength; i++) {
                array[i] = raw.charCodeAt(i);
              }

              const blob = new Blob([array], { type: file.contentType });

              const url = window.URL.createObjectURL(blob);
              return {
                ...file,
                type: file?.contentType,
                url
              };
            });

            onResolve && onResolve(result);
          }
        })
      );
    } catch (error) {
      onReject && onReject();
    }
  };
};
export const markMailAsRead = ({ account = {}, folderType, mailId, onResolve, onReject }) => {
  return async (dispatch, getStore) => {
    const { customMail } = getStore();
    try {
      dispatch(
        refreshToken({
          account,
          callback: async (account) => {
            const { token, user } = account;

            if (!token) return account;

            const folders = customMail?.folders || {};
            const folderId = folders[account?.user?.email][folderType]?.id;

            dispatch(
              updateMailsInFolder({
                email: account?.user?.email,
                folder: folderType,
                mailId,
                values: {
                  isRead: true
                }
              })
            );

            // await axios.patch(
            //   `https://graph.microsoft.com/v1.0/me/messages/${mailId}`,
            //   {
            //     isRead: true
            //   },
            //   {
            //     headers: {
            //       Authorization: `Bearer ${token}`,
            //       'Content-type': 'application/json'
            //     }
            //   }
            // );

            dispatch(reduceUnreadMailCount(account?.user?.email));
          }
        })
      );
    } catch (error) {}
  };
};

export const markMailAsUnread = ({ account = {}, folderType, mailId, onResolve, onReject }) => {
  return async (dispatch, getStore) => {
    try {
      dispatch(
        refreshToken({
          account,
          callback: async (account) => {
            const { token, user } = account;

            if (!token) return account;

            const folders = getStore()?.customMail?.folders || {};
            const folderId = folders[account?.user?.email][folderType]?.id;

            dispatch(
              updateMailsInFolder({
                email: account?.user?.email,
                folder: folderType,
                mailId,
                values: {
                  isRead: false
                }
              })
            );
            // await axios.patch(
            //   `https://graph.microsoft.com/v1.0/me/messages/${mailId}`,
            //   {
            //     isRead: false
            //   },
            //   {
            //     headers: {
            //       Authorization: `Bearer ${token}`,
            //       'Content-type': 'application/json'
            //     }
            //   }
            // );
            dispatch(reduceUnreadMailCount(account?.user?.email));
          }
        })
      );
    } catch (error) {
    } finally {
      onResolve && onResolve();
    }
  };
};

export const deleteMsMail = ({ account = {}, folderType, mailUID, mailId, onResolve, onReject }) => {
  return async (dispatch, getStore) => {
    try {
      dispatch(
        refreshToken({
          account,
          callback: async (account) => {
            const { token, user } = account;

            if (!token) return account;

            const folders = getStore()?.customMail?.folders || {};
            const folderId = folders[account?.user?.email][folderType]?.id;

            dispatch(
              removeMailsInFolder({
                email: account?.user?.email,
                folder: folderType,
                mailId
              })
            );

            dispatch(
              updateFlag({
                account,
                mailId,
                mailUID,
                currentFlag: '',
                currentFolder: folderType,
                nextFlag: MAIL_FLAG.DELETED,
                onResolve,
                onReject
              })
            );

            //dispatch(getMails({ account, folderType: MAIL_FOLDER.TRASH }));

            const result = { mailId: mailUID, folderId, platform: 'custom', token: account?.token };

            await axios.post(`${CLOUD_MAIL_API_KEY}/delete_mail`, result);

            // Implement update of the mail

            onResolve && onResolve();
          }
        })
      );
    } catch (error) {
      onReject && onReject();
    }
  };
};

export const disconnectAccount = (account) => {
  return async (dispatch) => {
    const utils = new LoadingUtil(dispatch, `refreshToken`);
    try {
      utils.start();

      dispatch(removeAccount(account?.user?.email));

      await axios.delete(
        `${CLOUD_MAIL_API_KEY}/account?accountId=${account?.id}&token=${account?.token}&plateform=custom`,
        {
          headers: {
            userid: auth.currentUser.uid,
            accountId: account?.id
          }
        }
      );

      utils.end();
    } catch (error) {
      utils.end();
    }
  };
};

const refreshToken = ({ account, callback }) => {
  return async (dispatch) => {
    const utils = new LoadingUtil(dispatch, `refreshToken`);

    try {
      return callback(account);
    } catch (error) {
      utils.end();
    }
  };
};

export const refreshrrrLinkedAccount = ({ emmitNotification }) => {
  return async (dispatch) => {
    const utils = new LoadingUtil(dispatch, `refreshLinkedAccount`);

    try {
      utils.start();
      const snap = await axios.get(`${CLOUD_MAIL_API_KEY}/account`, {
        headers: {
          userid: auth.currentUser.uid
        }
      });

      let accountSnap = {};

      keys(snap.data)?.forEach((id) => {
        const email = snap.data[id]?.user?.email;

        accountSnap[email] = { ...snap.data[id], email };
      });

      dispatch(igniteCustomAccount({ accountSnap }));
      utils.end();

      if (emmitNotification) {
        dispatch(setNotifyCallback(emmitNotification));
      }
      // console.log(accountSnap)
    } catch (error) {
      utils.end();
    }
  };
};

const createRefrecheDate = () => {
  const dateActuelle = new Date();

  const nouvelleDate = new Date(dateActuelle.getTime() - MAIL_TIMER);

  var month = nouvelleDate.toLocaleString('en-us', { month: 'short' });
  var day = nouvelleDate.getDate();
  var year = nouvelleDate.getFullYear();
  var hour = nouvelleDate.getHours();
  var minute = nouvelleDate.getMinutes();

  var formattedDate =
    month + ' ' + day + ', ' + year + ' ' + (hour < 10 ? '0' : '') + hour + ':' + (minute < 10 ? '0' : '') + minute;

  return formattedDate;
};

export const refreshCustomMails = () => {
  return async (dispatch, getStore) => {
    try {
      let count = 0;

      const canceler = Timer.repeat(MAIL_TIMER, () => {
        const accountSnap = getStore()?.customMail?.accounts || {};

        Object.entries(accountSnap).forEach(([key, account]) => {
          if (!isEmpty(key)) {
            dispatch(
              refreshToken({
                account,
                callback: (account) => {
                  dispatch(getMails({ account, folderType: MAIL_FOLDER.INBOX, refreshDate: createRefrecheDate() }));
                  count++;
                }
              })
            );
          }
        });
      });

      dispatch(updateMailRefresher(canceler));
    } catch (error) {}
  };
};

const transformMsMailToCustom = (msMail) => {
  return Mail.fromOutlook(msMail);
};

export const transformStringRecipientsToArray = (data = '') => {
  return data
    .split(',')
    .map((mail) => mail.trim().replaceAll(' ', ''))
    .filter((el) => el.length !== 0)
    .map((address) => ({
      emailAddress: {
        address
      }
    }));
};

export const transformArrayRecipientsToString = (data = []) => {
  let result = '';

  data.forEach((el) => {
    result += `${el?.emailAddress?.address},`;
  });

  return result;
};

const transformUsersArrayToString = (data = []) => {
  let result = '';

  data.forEach((el, index) => {
    const name = el?.emailAddress?.name;
    const email = el?.emailAddress?.address;
    const isLast = data.length - 1 === index;

    if (name !== email) result += `${name} <${email}>`;
    else {
      result += `<${email}>`;
    }

    if (isLast) return;

    result += ` , `;
  });

  return result;
};

export const listToObject = (list = []) => {
  let result = {};

  list.forEach((el) => {
    result[el?.id] = el;
  });

  return result;
};

export const transformToMsEmail = (data) => {
  return {};
};

class LoadingUtil {
  key = null;
  dispatch = null;

  constructor(dispatch, key) {
    this.dispatch = dispatch;
    this.key = key;
  }

  start() {
    this.dispatch(setLoading([this.key]));
  }

  end() {
    this.dispatch(revokeLoading([this.key]));
  }
}

export const transformFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

// dispatch(refreshToken({
//     account, callback: async (account) => {
//
//     }
// }))
