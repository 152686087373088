import PropTypes from 'prop-types';
import { useState, useRef, useEffect, useMemo } from 'react';
// @mui
import {
  Stack,
  Avatar,
  TextField,
  Typography,
  ListItemText,
  ListItemAvatar,
  InputAdornment,
  Button,
  ListItem,
  IconButton
} from '@mui/material';
// _mock_

// components
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import createAvatar, { getAvatarUrl } from 'src/utils/createAvatar';
import { toSimpleAccessUser } from 'src/helpers/user';
import MenuPopover from 'src/components/MenuPopover';
import { themeColor } from 'src/constants/color';
import { Icon } from '@iconify/react';
import { useToggleV2 } from 'src/hooks/useToggle';
import { useSelector } from 'src/redux/store';

const ITEM_HEIGHT = 64;

// ----------------------------------------------------------------------

ContactsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

/**
 *
 * @param {{
 * assigne: Array,
 * onAssigne: function(Array),
 * action: (popoverRef:any, onOpen:function)? {},
 * title: string?,
 * toBlock: Array?,
 * isSimpleUserID: boolean,
 * CustomList: Array | null ,
 * onlyAssignee: boolean,
 * disabled: boolean
 * }} props
 * @example <ContactsDialog
 *            assigne={[]}
 *            onAssigne={(users)=> }
 *            action ={(popoverRef, onOpen) => }
 *            titile=''
 *            toBlock={[]}
 *          />
 * @returns
 */
export default function ContactsDialog({
  assigne,
  onAssigne,
  action = null,
  task,
  isSimpleUserID = false,
  title = 'Assigné(s)',
  toBlock = [],
  CustomList = null,
  onlyAssignee = false,
  disabled = false
}) {
  const popoverRef = useRef();
  const [filterName, setFilterName] = useState('');
  const [open, onOpen, onClose] = useToggleV2();
  const { users: _contacts } = useSelector((state) => state.user);

  const converAssigne = useMemo(() => {
    if (isSimpleUserID) {
      return assigne.map((_id) => _contacts.find((one) => one?.id === _id));
    }
    return assigne;
  }, [assigne, _contacts, isSimpleUserID]);

  const [assignee, setAssigne] = useState(converAssigne);

  const handleSearchQuery = (event) => {
    setFilterName(event.target.value);
  };

  const handleAssigne = (user) => {
    const existIndex = assignee.findIndex((_one) => _one.id === user.id || _one === user.id);

    if (existIndex !== -1) {
      const updatedAssignee = [...assignee];
      updatedAssignee.splice(existIndex, 1);
      setAssigne(updatedAssignee);
    } else {
      setAssigne((prev) => [...prev, toSimpleAccessUser(user)]);
    }
  };

  const dataFiltered = useMemo(() => {
    if (onlyAssignee) {
      return converAssigne;
    }
    return applySortFilter({
      listData: CustomList?.length ? [...CustomList] : [..._contacts],
      filterName
    });
  }, [CustomList, _contacts, filterName, converAssigne, onlyAssignee]);

  const handleClose = () => {
    onClose();
    onAssigne(assignee);
  };

  useEffect(() => setAssigne(converAssigne), [converAssigne]);

  return (
    <>
      {!action && (
        <IconButton
          ref={popoverRef}
          size="small"
          onClick={onOpen}
          sx={{ p: 0.8, border: `1px solid ${themeColor.CARROT_ORANGE}`, color: themeColor.CARROT_ORANGE }}
          disabled={disabled}
        >
          <Icon icon="fluent:person-add-20-regular" />
        </IconButton>
      )}

      {action && action(popoverRef, onOpen)}

      <MenuPopover
        arrow="left-center"
        horizontal="right"
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        width={400}
        anchorEl={popoverRef.current}
        open={open && Boolean(popoverRef.current)}
        onClose={handleClose}
      >
        <Stack spacing={2} sx={{ p: 2.5, pb: 1 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">
              {title} <Typography component="span">({assignee?.length || 0})</Typography>
            </Typography>
            <Button size="small" onClick={handleClose} color="inherit" variant="outlined" disabled={disabled}>
              Terminer
            </Button>
          </Stack>

          <TextField
            fullWidth
            value={filterName}
            onChange={handleSearchQuery}
            label="Rechercher"
            placeholder="Rechercher ..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              )
            }}
          />
        </Stack>

        <Scrollbar
          sx={{
            height: ITEM_HEIGHT * 6,
            p: 1,
            '& .MuiMenuItem-root': {
              px: 1.5,
              height: ITEM_HEIGHT,
              borderRadius: 0.75
            }
          }}
        >
          {dataFiltered.map((contact, idx) => {
            const isAssigne = [...assignee].find((_user) => _user?.id === contact?.id);
            const canBlock = toBlock.find((_one) => _one === contact?.id);
            return (
              <ListItem
                key={contact.id + idx}
                dense
                secondaryAction={
                  !canBlock ? (
                    <Button
                      startIcon={<Icon height={15} width={15} icon={!isAssigne ? 'eva:plus-fill' : 'eva:minus-fill'} />}
                      onClick={() => handleAssigne(contact)}
                      color={isAssigne ? 'error' : 'inherit'}
                      sx={{ fontSize: 12 }}
                      disabled={disabled}
                      variant="text"
                    >
                      {isAssigne ? 'Retirer' : 'Assigner'}
                    </Button>
                  ) : (
                    <Button color="error" sx={{ fontSize: 12 }}>
                      Bloqué
                    </Button>
                  )
                }
              >
                <ListItemAvatar sx={{ position: 'relative' }}>
                  <Avatar
                    src={getAvatarUrl(contact)}
                    sx={{
                      color: 'white',
                      bgcolor: contact?.avatar ? 'default' : createAvatar(contact?.displayName || contact?.name).color2
                    }}
                  >
                    {createAvatar(contact?.displayName || contact?.name).name}
                  </Avatar>
                </ListItemAvatar>

                <ListItemText
                  primaryTypographyProps={{ typography: 'subtitle2', mb: 0.25 }}
                  secondaryTypographyProps={{ typography: 'caption' }}
                  primary={contact?.displayName || contact?.name}
                  secondary={contact.email}
                />
              </ListItem>
            );
          })}
        </Scrollbar>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ listData, filterName }) {
  if (filterName) {
    listData = listData.filter(
      (item) =>
        item.displayName?.toLowerCase()?.indexOf(filterName.toLowerCase()) !== -1 ||
        item?.email?.toLowerCase()?.indexOf(filterName.toLowerCase()) !== -1
    );
  }

  return listData;
}
