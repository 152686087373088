import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { COL_WIDTH } from 'src/constants/task';

export default function V4ProjectContentTaskComponent({
  name,
  intervenant,
  priority,
  due,
  header = false,
  visibility
}) {
  return (
    <Stack
      width={1}
      direction="row"
      alignItems="center"
      px={0.5}
      sx={{ borderBottom: '1px solid', borderColor: (t) => `${t.palette.divider}` }}
      divider={<Box height={40} width={1.01} bgcolor={(t) => t.palette.divider} mr={1} />}
    >
      <Stack width={1}>
        {header ? (
          <Typography variant="overline" fontSize={10}>
            {name}
          </Typography>
        ) : (
          name
        )}
      </Stack>
      <Stack minWidth={COL_WIDTH} alignItems="left">
        {header ? (
          <Typography variant="overline" fontSize={10}>
            {intervenant}
          </Typography>
        ) : (
          intervenant
        )}
      </Stack>
      <Stack minWidth={COL_WIDTH} alignItems="lfet">
        {header ? (
          <Typography variant="overline" fontSize={10}>
            Visibilité
          </Typography>
        ) : (
          visibility
        )}
      </Stack>
      <Stack minWidth={COL_WIDTH} alignItems="lfet">
        {header ? (
          <Typography variant="overline" fontSize={10}>
            {priority}
          </Typography>
        ) : (
          priority
        )}
      </Stack>
      <Stack minWidth={COL_WIDTH} alignItems="lfet">
        {header ? (
          <Typography variant="overline" fontSize={10}>
            {due}
          </Typography>
        ) : (
          due
        )}
      </Stack>
    </Stack>
  );
}
