import { Icon } from '@iconify/react';
import React from 'react';
import { IconButtonAnimate } from 'src/components/animate';
import { themeColor } from 'src/constants/color';
import { dateConverter } from 'src/helpers/dueDateConverter';
import useDateRangePicker from 'src/hooks/useDateRangePicker';
import { TaskType } from 'src/models/task_m';
import { dispatch } from 'src/redux/store';
import DatePickerDialog from 'src/components/DatePickerDialog';
import DisplayTime from 'src/components/DisplayTime';

/**
 *
 * @param {{task : TaskType, onUpdate: function, disabled: boolean, enabledDataInterval: boolean}} props
 * @returns
 */
export default function TaskDueDate({ task, onUpdate, disabled = false, enabledDataInterval = null }) {
  //TODO : reoudre le probleme qui empeche de recuprer deux date e mme temps 
  const {
    startTime,
    endTime,
    isSameDays,
    isSameMonths,
    openPicker,
    onOpenPicker,
    onClosePicker,
    onChangeEndTime,
    onChangeStartTime
  } = useDateRangePicker(dateConverter(task?.due));

  const onOpenPickerDisabled = () => {
    if (disabled) {
      return;
    }
    onOpenPicker();
  };

  const onSave = () => {
    const _startTime = new Date(startTime).toISOString();
    const _endTime = new Date(endTime).toISOString();

    const values = [new Date(_startTime), new Date(_endTime)];
    dispatch(onUpdate(task, { due: values }));
  };

  return (
    <>
      {startTime && endTime ? (
        <DisplayTime
          startTime={startTime}
          endTime={endTime}
          isSameDays={isSameDays}
          isSameMonths={isSameMonths}
          onOpenPicker={onOpenPickerDisabled}
          sx={{ typography: 'body2' }}
        />
      ) : (
        <IconButtonAnimate onClick={onOpenPickerDisabled} sx={{ p: 0.5 }}>
          <Icon icon="clarity:calendar-line" color={themeColor.CARROT_ORANGE} />
        </IconButtonAnimate>
      )}

      {!disabled && openPicker && (
        <DatePickerDialog
          open={openPicker}
          startTime={startTime}
          endTime={endTime}
          minDate={enabledDataInterval ? enabledDataInterval?.start?.toDate() : null}
          maxDate={enabledDataInterval ? enabledDataInterval?.end?.toDate() : null}
          onChangeEndTime={onChangeEndTime}
          onChangeStartTime={onChangeStartTime}
          onClose={onClosePicker}
          onSave={onSave}
        />
      )}
    </>
  );
}